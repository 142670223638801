import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/react-hooks";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  Decimal: { input: any; output: any };
  GenericScalar: { input: any; output: any };
};

export type Catalog = {
  __typename?: "Catalog";
  createDocumentationRecord: Scalars["Int"]["output"];
  /** Leave empty to disable the model download feature. */
  downloadThreeDModel: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  inviteManager: Scalars["Int"]["output"];
  requestModelPrices: Maybe<ModelPrices>;
  requestThreeDModelMaps: Scalars["Int"]["output"];
  requestThreeDModelNoScale: Scalars["Int"]["output"];
  requestThreeDModelQrCodes: Scalars["Int"]["output"];
  requestThreeDModelScale: Scalars["Int"]["output"];
  requestThreeDModelSpray: Scalars["Int"]["output"];
  storageClassPrices: Maybe<Array<Maybe<StorageClass>>>;
  /** Storage pricing classes. Every value describes an upper limit of storage in GiB. Number of limits has to be same as number of storage prices. */
  storageLimits: Array<Scalars["Decimal"]["output"]>;
  /** Storage pricing classes. Every value describes a price for respective limit. Number of limits has to be same as number of storage prices. */
  storagePrices: Array<Scalars["Int"]["output"]>;
};

export type Config = {
  __typename?: "Config";
  allowedGeodeticSurveyFileTypes: Maybe<Array<Scalars["String"]["output"]>>;
  allowedImageFileTypes: Maybe<Array<Scalars["String"]["output"]>>;
  allowedOtherFileTypes: Maybe<Array<Scalars["String"]["output"]>>;
  allowedVideoFileTypes: Maybe<Array<Scalars["String"]["output"]>>;
  contactUrl: Maybe<Scalars["String"]["output"]>;
  /** List of predefined options that manager can choose from when picking a name of a construction phase in czech */
  defaultConstructionPhasesCs: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of predefined options that manager can choose from when picking a name of a construction phase in german */
  defaultConstructionPhasesDe: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of predefined options that manager can choose from when picking a name of a construction phase in english */
  defaultConstructionPhasesEn: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of predefined options that manager can choose from when picking a name of a construction phase in ukrainian */
  defaultConstructionPhasesUk: Maybe<Array<Scalars["String"]["output"]>>;
  documentationRecordDeleteAfterDays: Scalars["Int"]["output"];
  /** List of options that 3d operator can choose from when rejecting documentation in czech */
  documentationRecordRejectReasonsCs: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of options that 3d operator can choose from when rejecting documentation in german */
  documentationRecordRejectReasonsDe: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of options that 3d operator can choose from when rejecting documentation in english */
  documentationRecordRejectReasonsEn: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of options that 3d operator can choose from when rejecting documentation in ukrainian */
  documentationRecordRejectReasonsUk: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of options that 3d operator can choose from when returning documentation in czech */
  documentationRecordReturnReasonsCs: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of options that 3d operator can choose from when returning documentation in german */
  documentationRecordReturnReasonsDe: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of options that 3d operator can choose from when returning documentation in english */
  documentationRecordReturnReasonsEn: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of options that 3d operator can choose from when returning documentation in ukrainian */
  documentationRecordReturnReasonsUk: Maybe<Array<Scalars["String"]["output"]>>;
  emailContactCs: Maybe<Scalars["String"]["output"]>;
  emailContactDe: Maybe<Scalars["String"]["output"]>;
  emailContactEn: Maybe<Scalars["String"]["output"]>;
  emailContactUk: Maybe<Scalars["String"]["output"]>;
  externalAccountUrl: Maybe<Scalars["String"]["output"]>;
  externalBaseName: Maybe<Scalars["String"]["output"]>;
  externalBaseUrl: Maybe<Scalars["String"]["output"]>;
  fileUploadUrl: Maybe<Scalars["String"]["output"]>;
  helpdeskUrl: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  logoutRedirectUrl: Maybe<Scalars["String"]["output"]>;
  minimalGroundControlPoints: Scalars["Int"]["output"];
  /** URL of oauth authorize endpoint, which returns user's identity based on provided username and password. */
  oauthTokenAuthorizeUrl: Maybe<Scalars["String"]["output"]>;
  /** URL of oauth userinfo endpoint, which returns user's identity of given access token. */
  oauthUserinfoUrl: Maybe<Scalars["String"]["output"]>;
  organizationInviteSentValidDays: Scalars["Int"]["output"];
  phoneContactCs: Maybe<Scalars["String"]["output"]>;
  phoneContactDe: Maybe<Scalars["String"]["output"]>;
  phoneContactEn: Maybe<Scalars["String"]["output"]>;
  phoneContactUk: Maybe<Scalars["String"]["output"]>;
  providerCrn: Maybe<Scalars["String"]["output"]>;
  providerName: Maybe<Scalars["String"]["output"]>;
  providerUrl: Maybe<Scalars["String"]["output"]>;
  purchaseExpirationMonths: Scalars["Int"]["output"];
  shopUrlCs: Maybe<Scalars["String"]["output"]>;
  shopUrlDe: Maybe<Scalars["String"]["output"]>;
  shopUrlEn: Maybe<Scalars["String"]["output"]>;
  shopUrlUk: Maybe<Scalars["String"]["output"]>;
  storagePaymentIntervalMonths: Scalars["Int"]["output"];
  threeDModelDeleteAfterDays: Scalars["Int"]["output"];
  threeDViewerUrl: Maybe<Scalars["String"]["output"]>;
  transactionEmailsEnabled: Scalars["Boolean"]["output"];
  transactionMessagesBccEmail: Maybe<Scalars["String"]["output"]>;
};

export type ConstructionElement = Node & {
  __typename?: "ConstructionElement";
  archivedAt: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  documentationRecord: DocumentationRecord;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  photo: Media;
  /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
  versions: VersionConnection;
};

export type ConstructionElementVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConstructionElementConnection = {
  __typename?: "ConstructionElementConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<ConstructionElementEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

export type ConstructionElementCreateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentationRecord: Scalars["ID"]["input"];
  photo: Scalars["ID"]["input"];
};

export type ConstructionElementCreatePayload = {
  __typename?: "ConstructionElementCreatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionElement: ConstructionElement;
};

export type ConstructionElementDeleteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionElement: Scalars["ID"]["input"];
};

export type ConstructionElementDeletePayload = {
  __typename?: "ConstructionElementDeletePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Archived construction element */
  constructionElement: ConstructionElement;
};

/** A Relay edge containing a `ConstructionElement` and its cursor. */
export type ConstructionElementEdge = {
  __typename?: "ConstructionElementEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: ConstructionElement;
};

export type ConstructionElementOps = {
  __typename?: "ConstructionElementOps";
  /**
   * Creates a construction element.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  create: Maybe<ConstructionElementCreatePayload>;
  /**
   * Archives a construction element.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `already_archived`: Object is already archived
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  delete: Maybe<ConstructionElementDeletePayload>;
  /**
   * Updates a construction element.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `parameter_required`: Required parameter is missing
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  update: Maybe<ConstructionElementUpdatePayload>;
};

export type ConstructionElementOpsCreateArgs = {
  input: ConstructionElementCreateInput;
};

export type ConstructionElementOpsDeleteArgs = {
  input: ConstructionElementDeleteInput;
};

export type ConstructionElementOpsUpdateArgs = {
  input: ConstructionElementUpdateInput;
};

export type ConstructionElementUpdateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionElement: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ConstructionElementUpdatePayload = {
  __typename?: "ConstructionElementUpdatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionElement: ConstructionElement;
};

export type ConstructionObject = Node &
  SearchResult & {
    __typename?: "ConstructionObject";
    accountableManager: Maybe<User>;
    accountableManagerId: Maybe<Scalars["ID"]["output"]>;
    accountableOperator: Maybe<User>;
    accountableOperatorId: Maybe<Scalars["ID"]["output"]>;
    accountableSurveyor: Maybe<User>;
    accountableSurveyorId: Maybe<Scalars["ID"]["output"]>;
    archivedAt: Maybe<Scalars["DateTime"]["output"]>;
    completedRecords: Scalars["Int"]["output"];
    constructionSite: ConstructionSite;
    createdAt: Scalars["DateTime"]["output"];
    createdRecords: Scalars["Int"]["output"];
    description: Maybe<Scalars["String"]["output"]>;
    documentationRecords: DocumentationRecordConnection;
    /** The ID of the object. */
    id: Scalars["ID"]["output"];
    /** List of tuples: [lat, lng] */
    location: Maybe<Array<Maybe<Array<Maybe<Scalars["Float"]["output"]>>>>>;
    name: Scalars["String"]["output"];
    state: ConstructionObjectState;
    suggestedDocumentationName: Maybe<Scalars["String"]["output"]>;
    /** Number of all (including archived) records belonging to this object */
    totalRecords: Scalars["Int"]["output"];
    /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
    versions: VersionConnection;
    workInProgressRecords: Scalars["Int"]["output"];
  };

export type ConstructionObjectDocumentationRecordsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  constructionObjectName?: InputMaybe<Scalars["String"]["input"]>;
  constructionPhaseOrder?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteId?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteName?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>>>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<Array<InputMaybe<DocumentationRecordStateFilterEnum>>>;
  surveyUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDModelUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDOperatorAssigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDOperatorName?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Array<InputMaybe<DocumentationRecordTypeFilterEnum>>>;
  videoType?: InputMaybe<Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>>>;
};

export type ConstructionObjectVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConstructionObjectConnection = {
  __typename?: "ConstructionObjectConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<ConstructionObjectEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

export type ConstructionObjectCreateInput = {
  accountableManager?: InputMaybe<Scalars["ID"]["input"]>;
  accountableOperator?: InputMaybe<Scalars["ID"]["input"]>;
  accountableSurveyor?: InputMaybe<Scalars["ID"]["input"]>;
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionSite: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** List of 2-sized tuples - latitude, longitude */
  location?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>>>;
  name: Scalars["String"]["input"];
  state?: InputMaybe<ConstructionObjectState>;
};

export type ConstructionObjectCreatePayload = {
  __typename?: "ConstructionObjectCreatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionObject: ConstructionObject;
  constructionSite: ConstructionSite;
};

export type ConstructionObjectDeleteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionObject: Scalars["ID"]["input"];
};

export type ConstructionObjectDeletePayload = {
  __typename?: "ConstructionObjectDeletePayload";
  /** Whether the object was really archived */
  archived: Scalars["Boolean"]["output"];
  /** List of descendant entities that were archived */
  archivedRecords: Array<Maybe<DocumentationRecord>>;
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** (Possibly) archived construction object */
  constructionObject: ConstructionObject;
  constructionSite: ConstructionSite;
  /** List of descendant entities that couldn't be deleted */
  failedRecords: Array<Maybe<DocumentationRecord>>;
};

/** A Relay edge containing a `ConstructionObject` and its cursor. */
export type ConstructionObjectEdge = {
  __typename?: "ConstructionObjectEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: ConstructionObject;
};

export type ConstructionObjectOps = {
  __typename?: "ConstructionObjectOps";
  /**
   * Creates a construction site with one default phase.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `not_member`: User is not a member of organization
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  create: Maybe<ConstructionObjectCreatePayload>;
  /**
   * Archives the construction object and it's descendants.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `already_archived`: Object is already archived
   */
  delete: Maybe<ConstructionObjectDeletePayload>;
  update: Maybe<ConstructionObjectUpdatePayload>;
};

export type ConstructionObjectOpsCreateArgs = {
  input: ConstructionObjectCreateInput;
};

export type ConstructionObjectOpsDeleteArgs = {
  input: ConstructionObjectDeleteInput;
};

export type ConstructionObjectOpsUpdateArgs = {
  input: ConstructionObjectUpdateInput;
};

export enum ConstructionObjectOrderByEnum {
  /** Accountable manager  first name */
  AccountableManagerFirstName = "ACCOUNTABLE_MANAGER__FIRST_NAME",
  /** Accountable manager  first name (descending) */
  AccountableManagerFirstNameDesc = "ACCOUNTABLE_MANAGER__FIRST_NAME_DESC",
  /** Accountable manager  last name */
  AccountableManagerLastName = "ACCOUNTABLE_MANAGER__LAST_NAME",
  /** Accountable manager  last name (descending) */
  AccountableManagerLastNameDesc = "ACCOUNTABLE_MANAGER__LAST_NAME_DESC",
  /** Created at */
  CreatedAt = "CREATED_AT",
  /** Created at (descending) */
  CreatedAtDesc = "CREATED_AT_DESC",
  /** Name */
  Name = "NAME",
  /** Name (descending) */
  NameDesc = "NAME_DESC"
}

export enum ConstructionObjectState {
  Active = "active",
  Inactive = "inactive"
}

export enum ConstructionObjectStateFilterEnum {
  /** active */
  Active = "ACTIVE",
  /** inactive */
  Inactive = "INACTIVE"
}

export type ConstructionObjectUpdateInput = {
  accountableManager?: InputMaybe<Scalars["ID"]["input"]>;
  accountableOperator?: InputMaybe<Scalars["ID"]["input"]>;
  accountableSurveyor?: InputMaybe<Scalars["ID"]["input"]>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionObject: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** List of 2-sized tuples - latitude, longitude */
  location?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<ConstructionObjectState>;
  /** If true, accountable users are also updated on descendant documentation records */
  updateDescendantDocumentationRecords?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ConstructionObjectUpdatePayload = {
  __typename?: "ConstructionObjectUpdatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionObject: ConstructionObject;
  /** List of descendant entities that couldn't be updated */
  failedRecords: Array<Maybe<DocumentationRecord>>;
  /** List of descendant entities that were updated */
  updatedRecords: Array<Maybe<DocumentationRecord>>;
};

export type ConstructionPhase = Node & {
  __typename?: "ConstructionPhase";
  archivedAt: Maybe<Scalars["DateTime"]["output"]>;
  completedRecords: Scalars["Int"]["output"];
  constructionSite: ConstructionSite;
  createdAt: Scalars["DateTime"]["output"];
  createdRecords: Scalars["Int"]["output"];
  documentationRecords: DocumentationRecordConnection;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  /** Logical order of the phase within the Construction Site */
  order: Scalars["Int"]["output"];
  /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
  versions: VersionConnection;
  workInProgressRecords: Scalars["Int"]["output"];
};

export type ConstructionPhaseDocumentationRecordsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  constructionObjectName?: InputMaybe<Scalars["String"]["input"]>;
  constructionPhaseOrder?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteId?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteName?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>>>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<Array<InputMaybe<DocumentationRecordStateFilterEnum>>>;
  surveyUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDModelUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDOperatorAssigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDOperatorName?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Array<InputMaybe<DocumentationRecordTypeFilterEnum>>>;
  videoType?: InputMaybe<Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>>>;
};

export type ConstructionPhaseVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConstructionPhaseConnection = {
  __typename?: "ConstructionPhaseConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<ConstructionPhaseEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

export type ConstructionPhaseCreateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionSite: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type ConstructionPhaseCreatePayload = {
  __typename?: "ConstructionPhaseCreatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionPhase: ConstructionPhase;
  constructionSite: ConstructionSite;
};

export type ConstructionPhaseDeleteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionPhase: Scalars["ID"]["input"];
};

export type ConstructionPhaseDeletePayload = {
  __typename?: "ConstructionPhaseDeletePayload";
  /** Whether the object was really archived */
  archived: Scalars["Boolean"]["output"];
  /** List of descendant entities that were archived */
  archivedRecords: Array<Maybe<DocumentationRecord>>;
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** (Possibly) archived construction phase */
  constructionPhase: ConstructionPhase;
  constructionSite: ConstructionSite;
  /** List of descendant entities that couldn't be deleted */
  failedRecords: Array<Maybe<DocumentationRecord>>;
};

/** A Relay edge containing a `ConstructionPhase` and its cursor. */
export type ConstructionPhaseEdge = {
  __typename?: "ConstructionPhaseEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: ConstructionPhase;
};

export type ConstructionPhaseMoveInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionPhase: Scalars["ID"]["input"];
  direction: MoveDirection;
};

export type ConstructionPhaseMovePayload = {
  __typename?: "ConstructionPhaseMovePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionPhase: ConstructionPhase;
};

export type ConstructionPhaseOps = {
  __typename?: "ConstructionPhaseOps";
  /**
   * Creates a construction site phase.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  create: Maybe<ConstructionPhaseCreatePayload>;
  /**
   * Archive existing construction phase and associated documentation records.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `already_archived`: Object is already archived
   */
  delete: Maybe<ConstructionPhaseDeletePayload>;
  /**
   * Update order of single construction phase in relation to other phases of the same site.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   */
  move: Maybe<ConstructionPhaseMovePayload>;
  /**
   * Update order of ConstructionSite's phases. Phases must be from same construction site. Won't validate duplicate orders!
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `is_empty`: List can not be empty
   *  `not_same_construction_site`: All items must be for same construction site
   */
  reorder: Maybe<ConstructionPhaseReorderPayload>;
  /**
   * Update existing construction phase.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   */
  update: Maybe<ConstructionPhaseUpdatePayload>;
};

export type ConstructionPhaseOpsCreateArgs = {
  input: ConstructionPhaseCreateInput;
};

export type ConstructionPhaseOpsDeleteArgs = {
  input: ConstructionPhaseDeleteInput;
};

export type ConstructionPhaseOpsMoveArgs = {
  input: ConstructionPhaseMoveInput;
};

export type ConstructionPhaseOpsReorderArgs = {
  input: ConstructionPhaseReorderInput;
};

export type ConstructionPhaseOpsUpdateArgs = {
  input: ConstructionPhaseUpdateInput;
};

export enum ConstructionPhaseOrderByEnum {
  /** Name */
  Name = "NAME",
  /** Name (descending) */
  NameDesc = "NAME_DESC",
  /** Order */
  Order = "ORDER",
  /** Order (descending) */
  OrderDesc = "ORDER_DESC"
}

export type ConstructionPhaseReorderInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  order: Array<InputMaybe<OrderItem>>;
};

export type ConstructionPhaseReorderPayload = {
  __typename?: "ConstructionPhaseReorderPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionSite: ConstructionSite;
};

export type ConstructionPhaseUpdateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionPhase: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConstructionPhaseUpdatePayload = {
  __typename?: "ConstructionPhaseUpdatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionPhase: ConstructionPhase;
};

export type ConstructionSite = Node &
  SearchResult & {
    __typename?: "ConstructionSite";
    accountableManager: Maybe<User>;
    accountableManagerId: Maybe<Scalars["ID"]["output"]>;
    accountableOperator: Maybe<User>;
    accountableOperatorId: Maybe<Scalars["ID"]["output"]>;
    accountableSurveyor: Maybe<User>;
    accountableSurveyorId: Maybe<Scalars["ID"]["output"]>;
    archivedAt: Maybe<Scalars["DateTime"]["output"]>;
    /** Controls whether Documentation Records should be approved by accountable manager before submitting for 3D processing. */
    autoRequestThreeD: Scalars["Boolean"]["output"];
    completedRecords: Scalars["Int"]["output"];
    constructionObjects: ConstructionObjectConnection;
    constructionPhases: ConstructionPhaseConnection;
    createdAt: Scalars["DateTime"]["output"];
    createdRecords: Scalars["Int"]["output"];
    description: Maybe<Scalars["String"]["output"]>;
    /** The ID of the object. */
    id: Scalars["ID"]["output"];
    /** List of tuples: [lat, lng] */
    location: Maybe<Array<Maybe<Array<Maybe<Scalars["Float"]["output"]>>>>>;
    name: Scalars["String"]["output"];
    organization: Organization;
    state: ConstructionSiteState;
    /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
    versions: VersionConnection;
    workInProgressRecords: Scalars["Int"]["output"];
  };

export type ConstructionSiteConstructionObjectsArgs = {
  accountableManagerId?: InputMaybe<Scalars["String"]["input"]>;
  accountableManagerName?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionObjectOrderByEnum>>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<Array<InputMaybe<ConstructionObjectStateFilterEnum>>>;
};

export type ConstructionSiteConstructionPhasesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionPhaseOrderByEnum>>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConstructionSiteVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConstructionSiteConnection = {
  __typename?: "ConstructionSiteConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<ConstructionSiteEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

export type ConstructionSiteCreateInput = {
  accountableManager?: InputMaybe<Scalars["ID"]["input"]>;
  accountableOperator?: InputMaybe<Scalars["ID"]["input"]>;
  accountableSurveyor?: InputMaybe<Scalars["ID"]["input"]>;
  autoRequestThreeD?: InputMaybe<Scalars["Boolean"]["input"]>;
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** If provided, also creates a phase with this name */
  defaultPhaseName?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** List of 2-sized tuples - latitude, longitude */
  location?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>>>;
  name: Scalars["String"]["input"];
  organization: Scalars["ID"]["input"];
  state?: InputMaybe<ConstructionSiteState>;
};

export type ConstructionSiteCreatePayload = {
  __typename?: "ConstructionSiteCreatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionSite: ConstructionSite;
  organization: Organization;
};

export type ConstructionSiteDeleteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionSite: Scalars["ID"]["input"];
};

export type ConstructionSiteDeletePayload = {
  __typename?: "ConstructionSiteDeletePayload";
  /** Whether the object was really archived */
  archived: Scalars["Boolean"]["output"];
  /** List of descendant entities that were archived */
  archivedObjects: Array<Maybe<ConstructionObject>>;
  /** List of descendant entities that were archived */
  archivedPhases: Array<Maybe<ConstructionPhase>>;
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** (Possibly) archived construction site */
  constructionSite: ConstructionSite;
  /** List of descendant entities that couldn't be deleted */
  failedObjects: Array<Maybe<ConstructionObject>>;
  /** List of descendant entities that couldn't be deleted */
  failedPhases: Array<Maybe<ConstructionPhase>>;
  organization: Organization;
};

/** A Relay edge containing a `ConstructionSite` and its cursor. */
export type ConstructionSiteEdge = {
  __typename?: "ConstructionSiteEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: ConstructionSite;
};

export type ConstructionSiteOps = {
  __typename?: "ConstructionSiteOps";
  /**
   * Creates a construction site, optionally with one default phase.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `not_member`: User is not a member of organization
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  create: Maybe<ConstructionSiteCreatePayload>;
  /**
   * Archives the construction site and it's descendants.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `already_archived`: Object is already archived
   */
  delete: Maybe<ConstructionSiteDeletePayload>;
  update: Maybe<ConstructionSiteUpdatePayload>;
};

export type ConstructionSiteOpsCreateArgs = {
  input: ConstructionSiteCreateInput;
};

export type ConstructionSiteOpsDeleteArgs = {
  input: ConstructionSiteDeleteInput;
};

export type ConstructionSiteOpsUpdateArgs = {
  input: ConstructionSiteUpdateInput;
};

export enum ConstructionSiteOrderByEnum {
  /** Accountable manager  first name */
  AccountableManagerFirstName = "ACCOUNTABLE_MANAGER__FIRST_NAME",
  /** Accountable manager  first name (descending) */
  AccountableManagerFirstNameDesc = "ACCOUNTABLE_MANAGER__FIRST_NAME_DESC",
  /** Accountable manager  last name */
  AccountableManagerLastName = "ACCOUNTABLE_MANAGER__LAST_NAME",
  /** Accountable manager  last name (descending) */
  AccountableManagerLastNameDesc = "ACCOUNTABLE_MANAGER__LAST_NAME_DESC",
  /** Created at */
  CreatedAt = "CREATED_AT",
  /** Created at (descending) */
  CreatedAtDesc = "CREATED_AT_DESC",
  /** Name */
  Name = "NAME",
  /** Name (descending) */
  NameDesc = "NAME_DESC"
}

export enum ConstructionSiteState {
  Active = "active",
  Inactive = "inactive"
}

export enum ConstructionSiteStateFilterEnum {
  /** active */
  Active = "ACTIVE",
  /** inactive */
  Inactive = "INACTIVE"
}

export type ConstructionSiteUpdateInput = {
  accountableManager?: InputMaybe<Scalars["ID"]["input"]>;
  accountableOperator?: InputMaybe<Scalars["ID"]["input"]>;
  accountableSurveyor?: InputMaybe<Scalars["ID"]["input"]>;
  autoRequestThreeD?: InputMaybe<Scalars["Boolean"]["input"]>;
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionSite: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** List of 2-sized tuples - latitude, longitude */
  location?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<ConstructionSiteState>;
  /** If true, state is updated on descendant documentation objects (active/inactive)  */
  updateDescendantConstructionObjectState?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** If true, accountable users are also updated on descendant construction objects */
  updateDescendantConstructionObjects?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** If true, accountable users are also updated on descendant documentation records */
  updateDescendantDocumentationRecords?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ConstructionSiteUpdatePayload = {
  __typename?: "ConstructionSiteUpdatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  constructionSite: ConstructionSite;
  /** List of descendant entities that couldn't be updated */
  failedObjects: Array<Maybe<ConstructionObject>>;
  /** List of descendant entities that couldn't be updated */
  failedRecords: Array<Maybe<DocumentationRecord>>;
  /** List of descendant entities that were updated */
  updatedObjects: Array<Maybe<ConstructionObject>>;
  /** List of descendant entities that were updated */
  updatedRecords: Array<Maybe<DocumentationRecord>>;
};

export enum CoordinateSystem {
  Ch1903PlusLv95 = "ch1903_plus_lv95",
  Dhdn = "dhdn",
  Dhdn_3dGkZ2 = "dhdn_3d_gk_z2",
  Dhdn_3dGkZ4 = "dhdn_3d_gk_z4",
  Dhdn_3dGkZ5 = "dhdn_3d_gk_z5",
  Dhdn_31466 = "dhdn_31466",
  Dhdn_31467 = "dhdn_31467",
  Dhdn_31468 = "dhdn_31468",
  Etrs89PolandCs2000_5 = "etrs89_poland_cs2000_5",
  Etrs89PolandCs2000_6 = "etrs89_poland_cs2000_6",
  Etrs89PolandCs2000_7 = "etrs89_poland_cs2000_7",
  Etrs89PolandCs2000_8 = "etrs89_poland_cs2000_8",
  Etrs89Utm_32n = "etrs89_utm_32n",
  Etrs89Utm_33n = "etrs89_utm_33n",
  Hd72 = "hd72",
  SJtsk = "s_jtsk",
  Unknown = "unknown",
  Wgs84 = "wgs84",
  Wgs84Utm_32n = "wgs84_utm_32n",
  Wgs84Utm_34n = "wgs84_utm_34n",
  Wgs84Utm_35n = "wgs84_utm_35n",
  Wgs84Utm_36n = "wgs84_utm_36n",
  Wgs84Utm_37n = "wgs84_utm_37n"
}

export type DiskUsageKindStats = {
  __typename?: "DiskUsageKindStats";
  kind: MediaKind;
  total: Scalars["Float"]["output"];
};

export type DiskUsageStats = {
  __typename?: "DiskUsageStats";
  byKind: Array<Maybe<DiskUsageKindStats>>;
  total: Scalars["Float"]["output"];
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: "DjangoDebug";
  /** Executed SQL queries for this API query. */
  sql: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: "DjangoDebugSQL";
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars["String"]["output"];
  /** Duration of this database query in seconds. */
  duration: Scalars["Float"]["output"];
  /** Postgres connection encoding if available. */
  encoding: Maybe<Scalars["String"]["output"]>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars["Boolean"]["output"];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars["Boolean"]["output"];
  /** Postgres isolation level if available. */
  isoLevel: Maybe<Scalars["String"]["output"]>;
  /** JSON encoded database query parameters. */
  params: Scalars["String"]["output"];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars["String"]["output"];
  /** The actual SQL sent to this database. */
  sql: Maybe<Scalars["String"]["output"]>;
  /** Start time of this database query. */
  startTime: Scalars["Float"]["output"];
  /** Stop time of this database query. */
  stopTime: Scalars["Float"]["output"];
  /** Postgres transaction ID if available. */
  transId: Maybe<Scalars["String"]["output"]>;
  /** Postgres transaction status if available. */
  transStatus: Maybe<Scalars["String"]["output"]>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars["String"]["output"];
};

export type DocumentationRecord = Node &
  SearchResult & {
    __typename?: "DocumentationRecord";
    accountableManager: Maybe<User>;
    accountableManagerId: Scalars["ID"]["output"];
    accountableOperator: Maybe<User>;
    accountableOperatorId: Maybe<Scalars["ID"]["output"]>;
    accountableSurveyor: Maybe<User>;
    accountableSurveyorId: Maybe<Scalars["ID"]["output"]>;
    accountableThreeDOperator: Maybe<User>;
    archivedAt: Maybe<Scalars["DateTime"]["output"]>;
    /**
     * List of reasons (if any) why this record cannot be archived.
     *
     * Defined errors:
     *
     * `permission_denied`: You do not have permission to perform this action
     *  `already_archived`: Object is already archived
     *  `state_constraint_violation`: You can not perform this action on object in its current state
     */
    canArchive: Array<Maybe<Errors>>;
    constructionElements: ConstructionElementConnection;
    constructionObject: ConstructionObject;
    constructionObjectId: Scalars["ID"]["output"];
    constructionPhase: ConstructionPhase;
    constructionPhaseId: Scalars["ID"]["output"];
    createdAt: Scalars["DateTime"]["output"];
    description: Maybe<Scalars["String"]["output"]>;
    documentationRecordVideo: Maybe<DocumentationRecordVideo>;
    geodeticSurvey: Maybe<GeodeticSurvey>;
    groundControlPointDocumentations: GroundControlPointDocumentationConnection;
    /** The ID of the object. */
    id: Scalars["ID"]["output"];
    /** Shortcut to last state change */
    lastStateChange: Maybe<DocumentationRecordStateChange>;
    /** Last payment for 3D model */
    lastThreeDModelPayment: Maybe<Payment>;
    /** Disk space used by documentation of this record. 3D models excluded */
    modelSize: Scalars["Float"]["output"];
    name: Scalars["String"]["output"];
    outputExtensions: OutputExtensionConnection;
    /** Used when ground control points are captured using simplified flow */
    simpleGroundControlPoint: Maybe<GroundControlPointDocumentation>;
    /** Used when ground control points are captured using simplified flow */
    simpleGroundControlPointCount: Maybe<Scalars["Int"]["output"]>;
    state: DocumentationRecordState;
    stateChanges: DocumentationRecordStateChangeConnection;
    threeDModel: Maybe<ThreeDModel>;
    /** Disk space used by documentation of this record. 3D models excluded */
    totalSize: Scalars["Float"]["output"];
    /** List of events state change triggers available for current state. */
    triggers: Array<Maybe<DocumentationRecordTrigger>>;
    type: DocumentationRecordType;
    /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
    versions: VersionConnection;
  };

export type DocumentationRecordConstructionElementsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentationRecordGroundControlPointDocumentationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentationRecordOutputExtensionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentationRecordStateChangesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentationRecordVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentationRecordAssignThreeDOperatorInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  documentationRecord: Scalars["ID"]["input"];
};

export type DocumentationRecordAssignThreeDOperatorPayload = {
  __typename?: "DocumentationRecordAssignThreeDOperatorPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  documentationRecord: DocumentationRecord;
};

export type DocumentationRecordCloneInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  documentationRecord: Scalars["ID"]["input"];
  newName: Scalars["String"]["input"];
};

export type DocumentationRecordClonePayload = {
  __typename?: "DocumentationRecordClonePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  documentationRecord: Maybe<DocumentationRecord>;
};

export type DocumentationRecordConnection = {
  __typename?: "DocumentationRecordConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<DocumentationRecordEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

export type DocumentationRecordCreateInput = {
  accountableManager?: InputMaybe<Scalars["ID"]["input"]>;
  accountableOperator?: InputMaybe<Scalars["ID"]["input"]>;
  accountableSurveyor?: InputMaybe<Scalars["ID"]["input"]>;
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionObject: Scalars["ID"]["input"];
  constructionPhase: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  type: DocumentationRecordType;
  videoType?: InputMaybe<DocumentationRecordVideoType>;
};

export type DocumentationRecordCreatePayload = {
  __typename?: "DocumentationRecordCreatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  documentationRecord: Maybe<DocumentationRecord>;
};

export type DocumentationRecordDeleteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  documentationRecord: Scalars["ID"]["input"];
};

export type DocumentationRecordDeletePayload = {
  __typename?: "DocumentationRecordDeletePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Archived documentation record */
  documentationRecord: DocumentationRecord;
};

/** A Relay edge containing a `DocumentationRecord` and its cursor. */
export type DocumentationRecordEdge = {
  __typename?: "DocumentationRecordEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: DocumentationRecord;
};

export type DocumentationRecordOps = {
  __typename?: "DocumentationRecordOps";
  /**
   * Assign self as Three D operator of given documentation record
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   *  `three_d_operator_already_assigned`: Three D operator is already assigned
   */
  assignThreeDOperator: Maybe<DocumentationRecordAssignThreeDOperatorPayload>;
  /**
   * Makes a copy of documentation record.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  clone: Maybe<DocumentationRecordClonePayload>;
  /**
   * Creates a documentation record.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `not_enough_credits`: Organization does not have enough credits
   */
  create: Maybe<DocumentationRecordCreatePayload>;
  /**
   * Archives the documentation record and it's descendants.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `already_archived`: Object is already archived
   */
  delete: Maybe<DocumentationRecordDeletePayload>;
  /**
   * Moves a documentation record to other construction phase and/or object within organization's construction sites.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  relocate: Maybe<DocumentationRecordRelocatePayload>;
  /**
   * Trigger change state of documentation record.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `condition_validation`: Condition validation failed
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   *  `fsm_state_not_changed`: State hasn't changed
   *  `fsm_unknown_event`: Unknown event name
   *  `value_invalid`: Given value is invalid
   *  `reason_invalid`: Reason is invalid or missing
   *  `not_same_organization`: Payment organization and documentation record organization must be the same
   */
  trigger: Maybe<DocumentationRecordTriggerPayload>;
  /**
   * Updates a documentation record.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `not_member`: User is not a member of organization
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  update: Maybe<DocumentationRecordUpdatePayload>;
};

export type DocumentationRecordOpsAssignThreeDOperatorArgs = {
  input: DocumentationRecordAssignThreeDOperatorInput;
};

export type DocumentationRecordOpsCloneArgs = {
  input: DocumentationRecordCloneInput;
};

export type DocumentationRecordOpsCreateArgs = {
  input: DocumentationRecordCreateInput;
};

export type DocumentationRecordOpsDeleteArgs = {
  input: DocumentationRecordDeleteInput;
};

export type DocumentationRecordOpsRelocateArgs = {
  input: DocumentationRecordRelocateInput;
};

export type DocumentationRecordOpsTriggerArgs = {
  input: DocumentationRecordTriggerInput;
};

export type DocumentationRecordOpsUpdateArgs = {
  input: DocumentationRecordUpdateInput;
};

export enum DocumentationRecordOrderByEnum {
  /** Construction object  construction site  name */
  ConstructionObjectConstructionSiteName = "CONSTRUCTION_OBJECT__CONSTRUCTION_SITE__NAME",
  /** Construction object  construction site  name (descending) */
  ConstructionObjectConstructionSiteNameDesc = "CONSTRUCTION_OBJECT__CONSTRUCTION_SITE__NAME_DESC",
  /** Construction object  construction site  organization  name */
  ConstructionObjectConstructionSiteOrganizationName = "CONSTRUCTION_OBJECT__CONSTRUCTION_SITE__ORGANIZATION__NAME",
  /** Construction object  construction site  organization  name (descending) */
  ConstructionObjectConstructionSiteOrganizationNameDesc = "CONSTRUCTION_OBJECT__CONSTRUCTION_SITE__ORGANIZATION__NAME_DESC",
  /** Construction object  name */
  ConstructionObjectName = "CONSTRUCTION_OBJECT__NAME",
  /** Construction object  name (descending) */
  ConstructionObjectNameDesc = "CONSTRUCTION_OBJECT__NAME_DESC",
  /** Construction phase  name */
  ConstructionPhaseName = "CONSTRUCTION_PHASE__NAME",
  /** Construction phase  name (descending) */
  ConstructionPhaseNameDesc = "CONSTRUCTION_PHASE__NAME_DESC",
  /** Construction phase  order */
  ConstructionPhaseOrder = "CONSTRUCTION_PHASE__ORDER",
  /** Construction phase  order (descending) */
  ConstructionPhaseOrderDesc = "CONSTRUCTION_PHASE__ORDER_DESC",
  /** Created at */
  CreatedAt = "CREATED_AT",
  /** Created at (descending) */
  CreatedAtDesc = "CREATED_AT_DESC",
  /** Last modified */
  LastModified = "LAST_MODIFIED",
  /** Last modified (descending) */
  LastModifiedDesc = "LAST_MODIFIED_DESC",
  /** Last state change  created at */
  LastStateChangeCreatedAt = "LAST_STATE_CHANGE__CREATED_AT",
  /** Last state change  created at (descending) */
  LastStateChangeCreatedAtDesc = "LAST_STATE_CHANGE__CREATED_AT_DESC",
  /** Model size */
  ModelSize = "MODEL_SIZE",
  /** Model size (descending) */
  ModelSizeDesc = "MODEL_SIZE_DESC",
  /** Name */
  Name = "NAME",
  /** Name (descending) */
  NameDesc = "NAME_DESC",
  /** Total size */
  TotalSize = "TOTAL_SIZE",
  /** Total size (descending) */
  TotalSizeDesc = "TOTAL_SIZE_DESC"
}

export type DocumentationRecordRelocateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  constructionObject: Scalars["ID"]["input"];
  constructionPhase: Scalars["ID"]["input"];
  documentationRecord: Scalars["ID"]["input"];
};

export type DocumentationRecordRelocatePayload = {
  __typename?: "DocumentationRecordRelocatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  documentationRecord: Maybe<DocumentationRecord>;
};

export enum DocumentationRecordState {
  Archived = "archived",
  Created = "created",
  Finished = "finished",
  Rejected = "rejected",
  RequiresManagerConfirmation = "requires_manager_confirmation",
  RequiresOperator = "requires_operator",
  RequiresSurveyor = "requires_surveyor",
  RequiresThreeDOperator = "requires_three_d_operator"
}

export type DocumentationRecordStateChange = Node & {
  __typename?: "DocumentationRecordStateChange";
  /** Submit/rejection comment */
  comment: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  documentationRecord: DocumentationRecord;
  fromState: DocumentationRecordStateChangeFromState;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  /** Rejection reason */
  rejectionReason: Maybe<Scalars["String"]["output"]>;
  /** Return reason */
  returnReason: Maybe<Scalars["String"]["output"]>;
  toState: DocumentationRecordStateChangeToState;
  /** Trigger name */
  trigger: Triggers;
  /** State change originator */
  user: Maybe<User>;
};

export type DocumentationRecordStateChangeConnection = {
  __typename?: "DocumentationRecordStateChangeConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<DocumentationRecordStateChangeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `DocumentationRecordStateChange` and its cursor. */
export type DocumentationRecordStateChangeEdge = {
  __typename?: "DocumentationRecordStateChangeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: DocumentationRecordStateChange;
};

export enum DocumentationRecordStateChangeFromState {
  /** archived */
  Archived = "ARCHIVED",
  /** created */
  Created = "CREATED",
  /** finished */
  Finished = "FINISHED",
  /** rejected */
  Rejected = "REJECTED",
  /** requires_manager_confirmation */
  RequiresManagerConfirmation = "REQUIRES_MANAGER_CONFIRMATION",
  /** requires_operator */
  RequiresOperator = "REQUIRES_OPERATOR",
  /** requires_surveyor */
  RequiresSurveyor = "REQUIRES_SURVEYOR",
  /** requires_three_d_operator */
  RequiresThreeDOperator = "REQUIRES_THREE_D_OPERATOR"
}

export enum DocumentationRecordStateChangeToState {
  /** archived */
  Archived = "ARCHIVED",
  /** created */
  Created = "CREATED",
  /** finished */
  Finished = "FINISHED",
  /** rejected */
  Rejected = "REJECTED",
  /** requires_manager_confirmation */
  RequiresManagerConfirmation = "REQUIRES_MANAGER_CONFIRMATION",
  /** requires_operator */
  RequiresOperator = "REQUIRES_OPERATOR",
  /** requires_surveyor */
  RequiresSurveyor = "REQUIRES_SURVEYOR",
  /** requires_three_d_operator */
  RequiresThreeDOperator = "REQUIRES_THREE_D_OPERATOR"
}

export enum DocumentationRecordStateFilterEnum {
  /** archived */
  Archived = "ARCHIVED",
  /** created */
  Created = "CREATED",
  /** finished */
  Finished = "FINISHED",
  /** rejected */
  Rejected = "REJECTED",
  /** requires_manager_confirmation */
  RequiresManagerConfirmation = "REQUIRES_MANAGER_CONFIRMATION",
  /** requires_operator */
  RequiresOperator = "REQUIRES_OPERATOR",
  /** requires_surveyor */
  RequiresSurveyor = "REQUIRES_SURVEYOR",
  /** requires_three_d_operator */
  RequiresThreeDOperator = "REQUIRES_THREE_D_OPERATOR"
}

export type DocumentationRecordTrigger = {
  __typename?: "DocumentationRecordTrigger";
  /** Signals if event is available for current user/or object state. This is hard check, user can't change Record properties to change this flag. */
  available: Scalars["Boolean"]["output"];
  /** List of failed validations. This will be empty list if `available` is `False` */
  conditionErrors: Array<Maybe<DocumentationRecordValidation>>;
  /** Signals if event can be triggered on object with current properties. User can update Record props to pass conditions check. */
  conditions: Scalars["Boolean"]["output"];
  /** Trigger name */
  trigger: Triggers;
};

export type DocumentationRecordTriggerInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  documentationRecord: Scalars["ID"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
  /** Refund percentage */
  refund?: InputMaybe<Scalars["Int"]["input"]>;
  trigger: Triggers;
};

export type DocumentationRecordTriggerPayload = {
  __typename?: "DocumentationRecordTriggerPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  documentationRecord: DocumentationRecord;
};

export enum DocumentationRecordType {
  NoThreeDNoVideo = "no_three_d_no_video",
  NoThreeDVideo = "no_three_d_video",
  ThreeDNoScale = "three_d_no_scale",
  ThreeDScale = "three_d_scale"
}

export enum DocumentationRecordTypeFilterEnum {
  /** no_three_d_no_video */
  NoThreeDNoVideo = "NO_THREE_D_NO_VIDEO",
  /** no_three_d_video */
  NoThreeDVideo = "NO_THREE_D_VIDEO",
  /** three_d_no_scale */
  ThreeDNoScale = "THREE_D_NO_SCALE",
  /** three_d_scale */
  ThreeDScale = "THREE_D_SCALE"
}

export type DocumentationRecordUpdateInput = {
  accountableManager?: InputMaybe<Scalars["ID"]["input"]>;
  accountableOperator?: InputMaybe<Scalars["ID"]["input"]>;
  accountableSurveyor?: InputMaybe<Scalars["ID"]["input"]>;
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentationRecord: Scalars["ID"]["input"];
  documentationRecordVideo?: InputMaybe<DocumentationRecordVideoInput>;
  geodeticSurvey?: InputMaybe<GeodeticSurveyInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  threeDModel?: InputMaybe<ThreeDModelInput>;
  type?: InputMaybe<DocumentationRecordType>;
};

export type DocumentationRecordUpdatePayload = {
  __typename?: "DocumentationRecordUpdatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  documentationRecord: Maybe<DocumentationRecord>;
};

export type DocumentationRecordValidation = {
  __typename?: "DocumentationRecordValidation";
  /** Optional, name of attribute where validation failed */
  attribute: Maybe<Scalars["String"]["output"]>;
  /** Validation name */
  validation: Validations;
};

export type DocumentationRecordVideo = Node & {
  __typename?: "DocumentationRecordVideo";
  /** Tuple: [lat, lng]. Only available when video was recorded using mawisphoto mobile app. */
  captureLocation: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
  createdAt: Scalars["DateTime"]["output"];
  documentationRecord: DocumentationRecord;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  type: Maybe<DocumentationRecordVideoType>;
  /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
  versions: VersionConnection;
  video: Maybe<Media>;
};

export type DocumentationRecordVideoVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentationRecordVideoInput = {
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  type?: InputMaybe<DocumentationRecordVideoType>;
  video?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum DocumentationRecordVideoType {
  Maps = "maps",
  NoScale = "no_scale",
  QrCodes = "qr_codes",
  Scale = "scale",
  Spray = "spray"
}

export enum DocumentationRecordVideoTypeFilterEnum {
  /** maps */
  Maps = "MAPS",
  /** none */
  None = "NONE",
  /** no_scale */
  NoScale = "NO_SCALE",
  /** qr_codes */
  QrCodes = "QR_CODES",
  /** scale */
  Scale = "SCALE",
  /** spray */
  Spray = "SPRAY"
}

export type DownloadableFile = {
  __typename?: "DownloadableFile";
  fileName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  size: Maybe<Scalars["Float"]["output"]>;
};

export enum Errors {
  AlreadyArchived = "already_archived",
  AlreadyInvited = "already_invited",
  AlreadyMember = "already_member",
  AlreadyRefunded = "already_refunded",
  AuthenticationError = "authentication_error",
  CannotChargeForStorage = "cannot_charge_for_storage",
  ConditionValidation = "condition_validation",
  CredentialsInvalid = "credentials_invalid",
  EmailInvalid = "email_invalid",
  FileTypeNotAllowed = "file_type_not_allowed",
  FsmStateNotChanged = "fsm_state_not_changed",
  FsmUnknownEvent = "fsm_unknown_event",
  ImpersonateSameUser = "impersonate_same_user",
  InquiryNotResolvable = "inquiry_not_resolvable",
  InquiryResolutionInvalid = "inquiry_resolution_invalid",
  InquiryTopicsRequired = "inquiry_topics_required",
  InvalidAmount = "invalid_amount",
  InviteInvalid = "invite_invalid",
  IsEmpty = "is_empty",
  NameRequired = "name_required",
  NoAccess = "no_access",
  NoVideo = "no_video",
  NotClaimable = "not_claimable",
  NotEnoughCredits = "not_enough_credits",
  NotFound = "not_found",
  NotManager = "not_manager",
  NotMember = "not_member",
  NotReady = "not_ready",
  NotSameConstructionSite = "not_same_construction_site",
  NotSameDocumentationRecord = "not_same_documentation_record",
  NotSameOrganization = "not_same_organization",
  OrganizationNotSpecified = "organization_not_specified",
  ParameterRequired = "parameter_required",
  PermissionDenied = "permission_denied",
  RateLimited = "rate_limited",
  ReasonInvalid = "reason_invalid",
  RefundInvalidAmount = "refund_invalid_amount",
  RoleAccountableMismatch = "role_accountable_mismatch",
  RoleMismatch = "role_mismatch",
  RoleNotAllowed = "role_not_allowed",
  StateConstraintViolation = "state_constraint_violation",
  ThreeDOperatorAlreadyAssigned = "three_d_operator_already_assigned",
  UndefinedStorageClass = "undefined_storage_class",
  ValueInvalid = "value_invalid"
}

export enum ExternalState {
  Active = "active",
  Blocked_1 = "blocked_1",
  Blocked_2 = "blocked_2",
  Created = "created",
  Deleted = "deleted"
}

export type GeodeticSurvey = Node & {
  __typename?: "GeodeticSurvey";
  createdAt: Scalars["DateTime"]["output"];
  documentationRecord: DocumentationRecord;
  files: MediaConnection;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  txtSurvey: Maybe<Media>;
  /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
  versions: VersionConnection;
};

export type GeodeticSurveyFilesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GeodeticSurveyVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GeodeticSurveyInput = {
  files?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  txtSurvey?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GroundControlPointDocumentation = Node & {
  __typename?: "GroundControlPointDocumentation";
  archivedAt: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  documentationRecord: DocumentationRecord;
  files: MediaConnection;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  /** Tuple: [lat, lng]. Can only be null when accessing Simplified Ground Control Point */
  location: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
  order: Scalars["Int"]["output"];
  /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
  versions: VersionConnection;
};

export type GroundControlPointDocumentationFilesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GroundControlPointDocumentationVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GroundControlPointDocumentationConnection = {
  __typename?: "GroundControlPointDocumentationConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<GroundControlPointDocumentationEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

export type GroundControlPointDocumentationCreateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentationRecord: Scalars["ID"]["input"];
  files: Array<InputMaybe<Scalars["ID"]["input"]>>;
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
  order: Scalars["Int"]["input"];
};

export type GroundControlPointDocumentationCreatePayload = {
  __typename?: "GroundControlPointDocumentationCreatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  groundControlPointDocumentation: GroundControlPointDocumentation;
};

export type GroundControlPointDocumentationDeleteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  groundControlPointDocumentation: Scalars["ID"]["input"];
};

export type GroundControlPointDocumentationDeletePayload = {
  __typename?: "GroundControlPointDocumentationDeletePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Archived ground control point documentation */
  groundControlPointDocumentation: GroundControlPointDocumentation;
};

/** A Relay edge containing a `GroundControlPointDocumentation` and its cursor. */
export type GroundControlPointDocumentationEdge = {
  __typename?: "GroundControlPointDocumentationEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: GroundControlPointDocumentation;
};

export type GroundControlPointDocumentationOps = {
  __typename?: "GroundControlPointDocumentationOps";
  /**
   * Creates a ground control point documentation.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   *  `parameter_required`: Required parameter is missing
   */
  create: Maybe<GroundControlPointDocumentationCreatePayload>;
  /**
   * Deleted a ground control point documentation.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `already_archived`: Object is already archived
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  delete: Maybe<GroundControlPointDocumentationDeletePayload>;
  /**
   * Updates order of ground control point documentations within one documentation record.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   *  `not_same_documentation_record`: All items must be for same documentation record
   *  `is_empty`: List can not be empty
   */
  reorder: Maybe<GroundControlPointDocumentationReorderPayload>;
  /**
   * Records a simplified ground control point documentation. Deletes existing ground control points if any.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   *  `parameter_required`: Required parameter is missing
   */
  setSimplified: Maybe<GroundControlPointDocumentationSetSimplifiedPayload>;
  /**
   * Updates a ground control point documentation.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   *  `parameter_required`: Required parameter is missing
   */
  update: Maybe<GroundControlPointDocumentationUpdatePayload>;
};

export type GroundControlPointDocumentationOpsCreateArgs = {
  input: GroundControlPointDocumentationCreateInput;
};

export type GroundControlPointDocumentationOpsDeleteArgs = {
  input: GroundControlPointDocumentationDeleteInput;
};

export type GroundControlPointDocumentationOpsReorderArgs = {
  input: GroundControlPointDocumentationReorderInput;
};

export type GroundControlPointDocumentationOpsSetSimplifiedArgs = {
  input: GroundControlPointDocumentationSetSimplifiedInput;
};

export type GroundControlPointDocumentationOpsUpdateArgs = {
  input: GroundControlPointDocumentationUpdateInput;
};

export type GroundControlPointDocumentationReorderInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  order: Array<InputMaybe<OrderItem>>;
};

export type GroundControlPointDocumentationReorderPayload = {
  __typename?: "GroundControlPointDocumentationReorderPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  documentationRecord: DocumentationRecord;
};

export type GroundControlPointDocumentationSetSimplifiedInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documentationRecord: Scalars["ID"]["input"];
  files?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  groundControlPointCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GroundControlPointDocumentationSetSimplifiedPayload = {
  __typename?: "GroundControlPointDocumentationSetSimplifiedPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  documentationRecord: DocumentationRecord;
};

export type GroundControlPointDocumentationUpdateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  files: Array<InputMaybe<Scalars["ID"]["input"]>>;
  groundControlPointDocumentation: Scalars["ID"]["input"];
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export type GroundControlPointDocumentationUpdatePayload = {
  __typename?: "GroundControlPointDocumentationUpdatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  groundControlPointDocumentation: GroundControlPointDocumentation;
};

export type Inquiry = Node & {
  __typename?: "Inquiry";
  createdAt: Scalars["DateTime"]["output"];
  files: MediaConnection;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  organization: Organization;
  requests: InquiryRequestedTopicConnection;
  resolvedAt: Maybe<Scalars["DateTime"]["output"]>;
  resolvedBy: Maybe<User>;
  resolverComment: Maybe<Scalars["String"]["output"]>;
  status: InquiryStatus;
  user: User;
  userComment: Maybe<Scalars["String"]["output"]>;
};

export type InquiryFilesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InquiryRequestsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InquiryConnection = {
  __typename?: "InquiryConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<InquiryEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `Inquiry` and its cursor. */
export type InquiryEdge = {
  __typename?: "InquiryEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: Inquiry;
};

export type InquiryOps = {
  __typename?: "InquiryOps";
  /**
   * Resolves an Inquiry and notifies the author of the request about it's resolution.
   *
   * Defined errors:
   *
   * `permission_denied`: You do not have permission to perform this action
   *  `not_found`: Node not found
   *  `inquiry_resolution_invalid`: Only completed and rejected are valid resolution statuses
   *  `inquiry_not_resolvable`: Inquiry cannot be resolved
   */
  resolve: Maybe<InquiryResolvePayload>;
  /**
   * Inform admins about features a customer would like to use.
   *
   * Defined errors:
   *
   * `permission_denied`: You do not have permission to perform this action
   *  `inquiry_topics_required`: Inquiry must have at least one topic
   *  `not_found`: Node not found
   */
  send: Maybe<InquirySendPayload>;
};

export type InquiryOpsResolveArgs = {
  input: InquiryResolveInput;
};

export type InquiryOpsSendArgs = {
  input: InquirySendInput;
};

export enum InquiryOrderByEnum {
  /** Created at */
  CreatedAt = "CREATED_AT",
  /** Created at (descending) */
  CreatedAtDesc = "CREATED_AT_DESC",
  /** Resolved at */
  ResolvedAt = "RESOLVED_AT",
  /** Resolved at (descending) */
  ResolvedAtDesc = "RESOLVED_AT_DESC"
}

export type InquiryRequestedTopic = Node & {
  __typename?: "InquiryRequestedTopic";
  constructionObjects: ConstructionObjectConnection;
  constructionSites: ConstructionSiteConnection;
  documentationRecords: DocumentationRecordConnection;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  inquiryTopic: InquiryTopic;
};

export type InquiryRequestedTopicConstructionObjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InquiryRequestedTopicConstructionSitesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InquiryRequestedTopicDocumentationRecordsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InquiryRequestedTopicConnection = {
  __typename?: "InquiryRequestedTopicConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<InquiryRequestedTopicEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `InquiryRequestedTopic` and its cursor. */
export type InquiryRequestedTopicEdge = {
  __typename?: "InquiryRequestedTopicEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: InquiryRequestedTopic;
};

export type InquiryResolveInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  /** IDs of files to attach to the Inquiry */
  files?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  inquiry: Scalars["ID"]["input"];
  /** Complete or reject, other status is not allowed */
  status: InquiryStatus;
};

export type InquiryResolvePayload = {
  __typename?: "InquiryResolvePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  inquiry: Inquiry;
};

export type InquirySendInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  nodes?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  organization: Scalars["ID"]["input"];
  topic: Scalars["ID"]["input"];
};

export type InquirySendPayload = {
  __typename?: "InquirySendPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  inquiry: Inquiry;
};

/**
 * Inquiries can no-longer be archived, but some of them have
 *     this status from before the archival was implemented
 */
export enum InquiryStatus {
  Archived = "archived",
  Completed = "completed",
  Created = "created",
  Rejected = "rejected"
}

export enum InquiryStatusFilterEnum {
  /** archived */
  Archived = "ARCHIVED",
  /** completed */
  Completed = "COMPLETED",
  /** created */
  Created = "CREATED",
  /** rejected */
  Rejected = "REJECTED"
}

export type InquiryTopic = Node & {
  __typename?: "InquiryTopic";
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  /** Whether this topic is archived or not. Archived topics are not visible to users. */
  isArchived: Scalars["Boolean"]["output"];
  /** Whether user inquiring this topic can select a construction site, object or a record which their inquiry relates to. */
  isStructural: Scalars["Boolean"]["output"];
  /** Topic of the inquiry in czech */
  topicCs: Scalars["String"]["output"];
  /** Topic of the inquiry in german */
  topicDe: Scalars["String"]["output"];
  /** Topic of the inquiry in english */
  topicEn: Scalars["String"]["output"];
  /** Topic of the inquiry in ukrainian */
  topicUk: Scalars["String"]["output"];
};

export enum InviteResolution {
  Accept = "accept",
  Reject = "reject"
}

export enum InviteState {
  Accepted = "accepted",
  Cancelled = "cancelled",
  Created = "created",
  Expired = "expired",
  Rejected = "rejected",
  Sent = "sent"
}

export enum Language {
  Cs = "cs",
  De = "de",
  En = "en",
  Uk = "uk"
}

export type LoginAuthTokenInput = {
  authToken: Scalars["String"]["input"];
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type LoginAuthTokenPayload = {
  __typename?: "LoginAuthTokenPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  refreshToken: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
  user: User;
};

export type LoginCredentialsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  password: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type LoginCredentialsPayload = {
  __typename?: "LoginCredentialsPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  refreshToken: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
  user: User;
};

export type LoginOps = {
  __typename?: "LoginOps";
  /**
   * Returns JWT that's authenticated with the provided token. Authentication process is documented [here](https://hrdlicka.atlassian.net/wiki/spaces/HRD/pages/46366724/P+ihla+ov+n)
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `authentication_error`: Authentication failed
   *  `no_access`: User does not have access - no payment found
   *  `rate_limited`: Request was rate limited
   */
  authToken: Maybe<LoginAuthTokenPayload>;
  /**
   * Returns JWT that's authenticated with the provided credentials. Authentication process is documented [here](https://hrdlicka.atlassian.net/wiki/spaces/HRD/pages/46366724/P+ihla+ov+n)
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `authentication_error`: Authentication failed
   *  `no_access`: User does not have access - no payment found
   *  `rate_limited`: Request was rate limited
   *  `credentials_invalid`: Invalid credentials
   */
  credentials: Maybe<LoginCredentialsPayload>;
};

export type LoginOpsAuthTokenArgs = {
  input: LoginAuthTokenInput;
};

export type LoginOpsCredentialsArgs = {
  input: LoginCredentialsInput;
};

export type Media = Node & {
  __typename?: "Media";
  createdAt: Scalars["DateTime"]["output"];
  fileName: Maybe<Scalars["String"]["output"]>;
  fileType: Maybe<Scalars["String"]["output"]>;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  kind: MediaKind;
  organization: Organization;
  path: Scalars["String"]["output"];
  size: Maybe<Scalars["Float"]["output"]>;
  state: MediaState;
  /**
   *
   *         Returns url where the file would be available when it's ready.
   *         !!!Keep in mind: This doesn't mean that the file is ready there now!!!
   *
   */
  url: Maybe<Scalars["String"]["output"]>;
  user: User;
};

export type MediaConnection = {
  __typename?: "MediaConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<MediaEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

export type MediaCreateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  organization: Scalars["ID"]["input"];
};

export type MediaCreatePayload = {
  __typename?: "MediaCreatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  media: Media;
};

/** A Relay edge containing a `Media` and its cursor. */
export type MediaEdge = {
  __typename?: "MediaEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: Media;
};

export enum MediaKind {
  Documentation = "documentation",
  Inquiry = "inquiry",
  OutputExtension = "output_extension",
  ThreeDModel = "three_d_model",
  Unassigned = "unassigned"
}

export type MediaOps = {
  __typename?: "MediaOps";
  create: Maybe<MediaCreatePayload>;
};

export type MediaOpsCreateArgs = {
  input: MediaCreateInput;
};

export enum MediaState {
  Archived = "archived",
  Archiving = "archiving",
  Created = "created",
  ForArchival = "for_archival",
  Restoring = "restoring",
  Uploaded = "uploaded",
  Uploading = "uploading"
}

export type ModelPrices = {
  __typename?: "ModelPrices";
  maps: Scalars["Float"]["output"];
  noScale: Scalars["Float"]["output"];
  qrCodes: Scalars["Float"]["output"];
  scale: Scalars["Float"]["output"];
  spray: Scalars["Float"]["output"];
};

export enum MoveDirection {
  Bottom = "bottom",
  Down = "down",
  Top = "top",
  Up = "up"
}

export type Mutation = {
  __typename?: "Mutation";
  constructionElement: Maybe<ConstructionElementOps>;
  constructionObject: Maybe<ConstructionObjectOps>;
  constructionPhase: Maybe<ConstructionPhaseOps>;
  constructionSite: Maybe<ConstructionSiteOps>;
  documentationRecord: Maybe<DocumentationRecordOps>;
  groundControlPointDocumentation: Maybe<GroundControlPointDocumentationOps>;
  inquiry: Maybe<InquiryOps>;
  login: Maybe<LoginOps>;
  media: Maybe<MediaOps>;
  organization: Maybe<OrganizationOps>;
  outputExtension: Maybe<OutputExtensionOps>;
  purchase: Maybe<PurchaseOps>;
  refreshToken: Maybe<RefreshPayload>;
  threeDModel: Maybe<ThreeDModelOps>;
  verifyToken: Maybe<VerifyPayload>;
};

export type MutationRefreshTokenArgs = {
  input: RefreshInput;
};

export type MutationVerifyTokenArgs = {
  input: VerifyInput;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
};

export type NotifyOutputExtensionsCompletedInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  documentationRecord: Scalars["ID"]["input"];
};

export type NotifyOutputExtensionsCompletedPayload = {
  __typename?: "NotifyOutputExtensionsCompletedPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  success: Maybe<Scalars["Boolean"]["output"]>;
};

export type OrderItem = {
  id: Scalars["ID"]["input"];
  order: Scalars["Int"]["input"];
};

export type Organization = Node & {
  __typename?: "Organization";
  /** When the organization confirmed first Purchase and gained access to the system. */
  activatedAt: Maybe<Scalars["DateTime"]["output"]>;
  archivedAt: Maybe<Scalars["DateTime"]["output"]>;
  constructionSites: ConstructionSiteConnection;
  createdAt: Scalars["DateTime"]["output"];
  /** Virtual currency used to purchase services within the system. Acquired by confirming Purchase. */
  credits: Scalars["Int"]["output"];
  /** Credits check 15 days before payment day */
  creditsCheckFollowupAt: Maybe<Scalars["DateTime"]["output"]>;
  /** Credits check 1 month before payment day */
  creditsCheckedAt: Maybe<Scalars["DateTime"]["output"]>;
  /** Company Registration Number (CRN) */
  crn: Maybe<Scalars["String"]["output"]>;
  diskUsageStats: DiskUsageStats;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  inquiries: InquiryConnection;
  media: MediaConnection;
  name: Scalars["String"]["output"];
  nextStoragePaymentDate: Maybe<Scalars["DateTime"]["output"]>;
  /** Enables/disables sending of transaction messages (including invites) for the whole Organization. */
  notificationsEnabled: Scalars["Boolean"]["output"];
  organizationInvites: OrganizationInviteConnection;
  organizationMemberships: OrganizationMembershipConnection;
  payments: PaymentConnection;
  purchases: PurchaseConnection;
  /** Returns a list of all documentation records that are created within the organization. This is a shortcut for cycling through all sites and objects. To get tasks assigned to the user, use user.tasks. */
  tasks: DocumentationRecordConnection;
};

export type OrganizationConstructionSitesArgs = {
  accountableManagerId?: InputMaybe<Scalars["String"]["input"]>;
  accountableManagerName?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionSiteOrderByEnum>>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<Array<InputMaybe<ConstructionSiteStateFilterEnum>>>;
};

export type OrganizationInquiriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByEnum>>>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  requestedByName?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusFilterEnum>>>;
  toProcess?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OrganizationMediaArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationOrganizationInvitesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationOrganizationMembershipsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationPaymentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationPurchasesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OrganizationTasksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  constructionObjectName?: InputMaybe<Scalars["String"]["input"]>;
  constructionPhaseOrder?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteId?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteName?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>>>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<Array<InputMaybe<DocumentationRecordStateFilterEnum>>>;
  surveyUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDModelUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDOperatorAssigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDOperatorName?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Array<InputMaybe<DocumentationRecordTypeFilterEnum>>>;
  videoType?: InputMaybe<Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>>>;
};

export type OrganizationCancelInviteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invite: Scalars["ID"]["input"];
};

export type OrganizationCancelInvitePayload = {
  __typename?: "OrganizationCancelInvitePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  invite: OrganizationInvite;
  organization: Organization;
  user: User;
};

export type OrganizationInvite = Node & {
  __typename?: "OrganizationInvite";
  acceptedAt: Maybe<Scalars["DateTime"]["output"]>;
  cancelledAt: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  expired: Scalars["Boolean"]["output"];
  expiresAt: Scalars["DateTime"]["output"];
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  invitedBy: User;
  organization: Organization;
  rejectedAt: Maybe<Scalars["DateTime"]["output"]>;
  role: Role;
  sentAt: Maybe<Scalars["DateTime"]["output"]>;
  state: InviteState;
  user: User;
};

export type OrganizationInviteConnection = {
  __typename?: "OrganizationInviteConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<OrganizationInviteEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `OrganizationInvite` and its cursor. */
export type OrganizationInviteEdge = {
  __typename?: "OrganizationInviteEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: OrganizationInvite;
};

export type OrganizationInviteUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  organization: Scalars["ID"]["input"];
  role: Role;
};

export type OrganizationInviteUserPayload = {
  __typename?: "OrganizationInviteUserPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  invite: OrganizationInvite;
  organization: Organization;
  user: User;
};

export type OrganizationLeaveInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** A user to take over one's job in the organization */
  newManager?: InputMaybe<Scalars["ID"]["input"]>;
  /** Organization to leave */
  organization: Scalars["ID"]["input"];
};

export type OrganizationLeavePayload = {
  __typename?: "OrganizationLeavePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  user: User;
};

export type OrganizationLogEventInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  event: UseCaseEvent;
};

export type OrganizationLogEventPayload = {
  __typename?: "OrganizationLogEventPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type OrganizationMembership = Node & {
  __typename?: "OrganizationMembership";
  createdAt: Scalars["DateTime"]["output"];
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  organization: Organization;
  user: User;
};

export type OrganizationMembershipConnection = {
  __typename?: "OrganizationMembershipConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<OrganizationMembershipEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `OrganizationMembership` and its cursor. */
export type OrganizationMembershipEdge = {
  __typename?: "OrganizationMembershipEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: OrganizationMembership;
};

export type OrganizationOps = {
  __typename?: "OrganizationOps";
  /**
   * Cancel invite
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `invite_invalid`: Invite is expired or has already been accepted, rejected or cancelled
   */
  cancelInvite: Maybe<OrganizationCancelInvitePayload>;
  /**
   * Invite new user to the organization.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `role_not_allowed`: User can not be invited to this role
   *  `email_invalid`: Email is not valid
   *  `already_member`: User is already member of organization
   *  `role_mismatch`: User already has different role
   *  `already_invited`: User is already invited to this organization
   *  `not_enough_credits`: Organization does not have enough credits
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  inviteUser: Maybe<OrganizationInviteUserPayload>;
  /**
   * Leave an organization you are a member of.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `not_member`: User is not a member of organization
   *  `not_manager`: User is not a manager
   */
  leave: Maybe<OrganizationLeavePayload>;
  /**
   * Log user activity.
   *
   * Only following are required to be handled by frontend:
   *
   * user_logout
   *
   * file_download
   *
   * three_d_model_view
   *
   * Defined errors:
   *
   * `permission_denied`: You do not have permission to perform this action
   */
  logEvent: Maybe<OrganizationLogEventPayload>;
  /**
   * Leave an organization you are a member of.
   *
   * Defined errors:
   *
   * `permission_denied`: You do not have permission to perform this action
   *  `not_found`: Node not found
   *  `not_member`: User is not a member of organization
   *  `not_manager`: User is not a manager
   */
  removeUser: Maybe<OrganizationRemoveUserPayload>;
  /**
   * Accept or reject invite
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `invite_invalid`: Invite is expired or has already been accepted, rejected or cancelled
   *  `role_mismatch`: User already has different role
   *  `already_member`: User is already member of organization
   */
  resolveInvite: Maybe<OrganizationResolveInvitePayload>;
  /** Set preferred language of current user. */
  setLanguage: Maybe<SetLanguagePayload>;
};

export type OrganizationOpsCancelInviteArgs = {
  input: OrganizationCancelInviteInput;
};

export type OrganizationOpsInviteUserArgs = {
  input: OrganizationInviteUserInput;
};

export type OrganizationOpsLeaveArgs = {
  input: OrganizationLeaveInput;
};

export type OrganizationOpsLogEventArgs = {
  input: OrganizationLogEventInput;
};

export type OrganizationOpsRemoveUserArgs = {
  input: OrganizationRemoveUserInput;
};

export type OrganizationOpsResolveInviteArgs = {
  input: OrganizationResolveInviteInput;
};

export type OrganizationOpsSetLanguageArgs = {
  input: SetLanguageInput;
};

export type OrganizationRemoveUserInput = {
  accountableUser?: InputMaybe<Scalars["ID"]["input"]>;
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  membership: Scalars["ID"]["input"];
};

export type OrganizationRemoveUserPayload = {
  __typename?: "OrganizationRemoveUserPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  organization: Organization;
};

export type OrganizationResolveInviteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  resolution: InviteResolution;
};

export type OrganizationResolveInvitePayload = {
  __typename?: "OrganizationResolveInvitePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  invite: OrganizationInvite;
  organization: Organization;
  user: User;
};

export type OutputExtension = Node & {
  __typename?: "OutputExtension";
  comment: Maybe<Scalars["String"]["output"]>;
  completedAt: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  files: MediaConnection;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  inquiry: Inquiry;
  inquiryTopic: InquiryTopic;
};

export type OutputExtensionFilesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OutputExtensionConnection = {
  __typename?: "OutputExtensionConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<OutputExtensionEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

export type OutputExtensionDeleteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  outputExtension: Scalars["ID"]["input"];
};

export type OutputExtensionDeletePayload = {
  __typename?: "OutputExtensionDeletePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  success: Maybe<Scalars["Boolean"]["output"]>;
};

/** A Relay edge containing a `OutputExtension` and its cursor. */
export type OutputExtensionEdge = {
  __typename?: "OutputExtensionEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: OutputExtension;
};

export type OutputExtensionOps = {
  __typename?: "OutputExtensionOps";
  /**
   * Permanently delete the output extension along with all its files.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  delete: Maybe<OutputExtensionDeletePayload>;
  /**
   * Sends a notification to the Manager of the Documentation that the Output Extension has been completed.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  notifyOutputExtensionsCompleted: Maybe<NotifyOutputExtensionsCompletedPayload>;
  /**
   * Updates an output extension.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  update: Maybe<OutputExtensionUpdatePayload>;
};

export type OutputExtensionOpsDeleteArgs = {
  input: OutputExtensionDeleteInput;
};

export type OutputExtensionOpsNotifyOutputExtensionsCompletedArgs = {
  input: NotifyOutputExtensionsCompletedInput;
};

export type OutputExtensionOpsUpdateArgs = {
  input: OutputExtensionUpdateInput;
};

export type OutputExtensionUpdateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  outputExtension: Scalars["ID"]["input"];
};

export type OutputExtensionUpdatePayload = {
  __typename?: "OutputExtensionUpdatePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  outputExtension: OutputExtension;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars["String"]["output"]>;
};

export type Payment = Node & {
  __typename?: "Payment";
  amount: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  organization: Organization;
  refunds: RefundConnection;
  type: PaymentType;
  user: Maybe<User>;
};

export type PaymentRefundsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaymentConnection = {
  __typename?: "PaymentConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<PaymentEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `Payment` and its cursor. */
export type PaymentEdge = {
  __typename?: "PaymentEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: Payment;
};

export enum PaymentType {
  /** documentation_record */
  DocumentationRecord = "DOCUMENTATION_RECORD",
  /** file_download */
  FileDownload = "FILE_DOWNLOAD",
  /** invite_manager */
  InviteManager = "INVITE_MANAGER",
  /** storage */
  Storage = "STORAGE",
  /** three_d_model */
  ThreeDModel = "THREE_D_MODEL"
}

export type Purchase = Node & {
  __typename?: "Purchase";
  claimedAt: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  /** Amount of credits the Organization will (did) get when the Purchase is claimed. */
  credits: Scalars["Int"]["output"];
  expiresAt: Maybe<Scalars["DateTime"]["output"]>;
  externalId: Maybe<Scalars["String"]["output"]>;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  organization: Maybe<Organization>;
  type: PurchaseType;
  user: Maybe<User>;
};

export type PurchaseClaimInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  organization: Scalars["ID"]["input"];
  purchase: Scalars["ID"]["input"];
};

export type PurchaseClaimPayload = {
  __typename?: "PurchaseClaimPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  purchase: Purchase;
};

export type PurchaseConnection = {
  __typename?: "PurchaseConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<PurchaseEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `Purchase` and its cursor. */
export type PurchaseEdge = {
  __typename?: "PurchaseEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: Purchase;
};

export type PurchaseOps = {
  __typename?: "PurchaseOps";
  /**
   * Claim purchase from mawis.eu - adds credits and activates the organization if it wasn't yet.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `not_claimable`: Object can not be claimed
   *  `organization_not_specified`: Organization is required in this context, but was not provided
   */
  claim: Maybe<PurchaseClaimPayload>;
};

export type PurchaseOpsClaimArgs = {
  input: PurchaseClaimInput;
};

export enum PurchaseType {
  /** access */
  Access = "ACCESS"
}

export type Query = {
  __typename?: "Query";
  _debug: Maybe<DjangoDebug>;
  catalog: Maybe<Catalog>;
  config: Maybe<Config>;
  constructionObject: Maybe<ConstructionObject>;
  constructionPhase: Maybe<ConstructionPhase>;
  constructionSite: Maybe<ConstructionSite>;
  documentationRecord: Maybe<DocumentationRecord>;
  /** Lists all non-archived inquiries in the system. Should be only used by three d operator. */
  inquiries: InquiryConnection;
  inquiry: Maybe<Inquiry>;
  inquiryTopics: Array<Maybe<InquiryTopic>>;
  me: Maybe<User>;
  media: Maybe<Media>;
  organization: Maybe<Organization>;
  /** Finds entities of `types` matching `query` (ignoring case and accents). Attempts to return consistent number of results. */
  search: Maybe<Array<Maybe<SearchResult>>>;
  threeDModel: Maybe<ThreeDModel>;
};

export type QueryConstructionObjectArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryConstructionPhaseArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryConstructionSiteArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDocumentationRecordArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryInquiriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByEnum>>>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  requestedByName?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusFilterEnum>>>;
  toProcess?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryInquiryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryMediaArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrganizationArgs = {
  id: Scalars["ID"]["input"];
};

export type QuerySearchArgs = {
  query: Scalars["String"]["input"];
  types: Array<InputMaybe<SearchType>>;
};

export type QueryThreeDModelArgs = {
  id: Scalars["ID"]["input"];
};

export type RefreshInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type RefreshPayload = {
  __typename?: "RefreshPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  payload: Scalars["GenericScalar"]["output"];
  refreshExpiresIn: Scalars["Int"]["output"];
  refreshToken: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
};

export type Refund = Node & {
  __typename?: "Refund";
  /** Amount of credits that was returned to the Organization */
  amount: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  /** Which Payment is this refund related to. */
  payment: Payment;
  reason: Scalars["String"]["output"];
  user: Maybe<User>;
};

export type RefundConnection = {
  __typename?: "RefundConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<RefundEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `Refund` and its cursor. */
export type RefundEdge = {
  __typename?: "RefundEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: Refund;
};

export type RequestDownloadLinkInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  media: Scalars["ID"]["input"];
};

export type RequestDownloadLinkPayload = {
  __typename?: "RequestDownloadLinkPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

export enum Role {
  Administrator = "administrator",
  Manager = "manager",
  Observer = "observer",
  Operator = "operator",
  Reader = "reader",
  Surveyor = "surveyor",
  ThreeDOperator = "three_d_operator"
}

export type SearchResult = {
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export enum SearchType {
  ConstructionObject = "construction_object",
  ConstructionSite = "construction_site",
  DocumentationRecord = "documentation_record"
}

export type SetLanguageInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Language>;
};

export type SetLanguagePayload = {
  __typename?: "SetLanguagePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  user: User;
};

export type StorageClass = {
  __typename?: "StorageClass";
  maxGb: Scalars["Float"]["output"];
  price: Scalars["Float"]["output"];
};

export type ThreeDModel = Node & {
  __typename?: "ThreeDModel";
  archivedAt: Maybe<Scalars["DateTime"]["output"]>;
  coordinateSystem: Maybe<CoordinateSystem>;
  coordinatesFile: Maybe<Media>;
  createdAt: Scalars["DateTime"]["output"];
  documentationRecord: DocumentationRecord;
  files: Maybe<Array<Maybe<DownloadableFile>>>;
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  /** Whether there's any valid share code, that can be used to access shared data. */
  isShared: Scalars["Boolean"]["output"];
  modelFile: Maybe<Media>;
  textureFile: Maybe<Media>;
  /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
  versions: VersionConnection;
};

export type ThreeDModelVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ThreeDModelDeleteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  threeDModel: Scalars["ID"]["input"];
};

export type ThreeDModelDeletePayload = {
  __typename?: "ThreeDModelDeletePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Archived 3D model */
  threeDModel: ThreeDModel;
};

export type ThreeDModelInput = {
  coordinateSystem?: InputMaybe<CoordinateSystem>;
  coordinatesFile?: InputMaybe<Scalars["ID"]["input"]>;
  files?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  modelFile?: InputMaybe<Scalars["ID"]["input"]>;
  textureFile?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ThreeDModelOps = {
  __typename?: "ThreeDModelOps";
  /**
   * Archives existing 3D model.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `already_archived`: Object is already archived
   */
  delete: Maybe<ThreeDModelDeletePayload>;
  /**
   * Generates an URL where given media is available for download. Deducts credits.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `not_enough_credits`: Organization does not have enough credits
   *  `state_constraint_violation`: You can not perform this action on object in its current state
   */
  requestDownloadLink: Maybe<RequestDownloadLinkPayload>;
  /**
   * Revokes all existing public shares, preventing them from providing shared data.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   */
  revokeShares: Maybe<ThreeDModelRevokeSharesPayload>;
  /**
   * Sends transaction message with provided code to specified recipients.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `email_invalid`: Email is not valid
   */
  sendShare: Maybe<ThreeDModelSendSharePayload>;
  /**
   * Creates a code that can be used to access 3D model.
   *
   * Defined errors:
   *
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `not_ready`: Object is not ready
   *  `already_archived`: Object is already archived
   */
  share: Maybe<ThreeDModelSharePayload>;
};

export type ThreeDModelOpsDeleteArgs = {
  input: ThreeDModelDeleteInput;
};

export type ThreeDModelOpsRequestDownloadLinkArgs = {
  input: RequestDownloadLinkInput;
};

export type ThreeDModelOpsRevokeSharesArgs = {
  input: ThreeDModelRevokeSharesInput;
};

export type ThreeDModelOpsSendShareArgs = {
  input: ThreeDModelSendShareInput;
};

export type ThreeDModelOpsShareArgs = {
  input: ThreeDModelShareInput;
};

export type ThreeDModelRevokeSharesInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  threeDModel: Scalars["ID"]["input"];
};

export type ThreeDModelRevokeSharesPayload = {
  __typename?: "ThreeDModelRevokeSharesPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  threeDModel: ThreeDModel;
};

export type ThreeDModelSendShareInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  recipients: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type ThreeDModelSendSharePayload = {
  __typename?: "ThreeDModelSendSharePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  sent: Scalars["Boolean"]["output"];
};

export type ThreeDModelShareInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** After this timestamp the code will no longer return any data. */
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Set to true if this model is to be shared publicly using url. */
  public: Scalars["Boolean"]["input"];
  threeDModel: Scalars["ID"]["input"];
};

export type ThreeDModelSharePayload = {
  __typename?: "ThreeDModelSharePayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Code that can be used to view shared data */
  code: Scalars["String"]["output"];
  /** URL that can be used to view shared data */
  url: Scalars["String"]["output"];
};

/**
 *
 *     Possible triggers/events for DocumentationRecord
 *
 */
export enum Triggers {
  Archive = "ARCHIVE",
  Back = "BACK",
  BackCreated = "BACK_CREATED",
  BackOperator = "BACK_OPERATOR",
  BackSurveyor = "BACK_SURVEYOR",
  ChangeNoScale = "CHANGE_NO_SCALE",
  ChangeToThreeD = "CHANGE_TO_THREE_D",
  Reject = "REJECT",
  Submit = "SUBMIT",
  SubmitFinished = "SUBMIT_FINISHED",
  SubmitManagerApproval = "SUBMIT_MANAGER_APPROVAL",
  SubmitOperator = "SUBMIT_OPERATOR",
  SubmitSurveyor = "SUBMIT_SURVEYOR",
  SubmitThreeDOperator = "SUBMIT_THREE_D_OPERATOR"
}

export enum UseCaseEvent {
  AdminChangeCatalog = "admin_change_catalog",
  AdminChangeConfig = "admin_change_config",
  FileArchived = "file_archived",
  FileDownload = "file_download",
  ThreeDModelView = "three_d_model_view",
  UserLeaveOrganization = "user_leave_organization",
  UserLogin = "user_login",
  UserLogout = "user_logout"
}

export type User = Node & {
  __typename?: "User";
  dateJoined: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  /** Mawis.eu ID */
  externalId: Maybe<Scalars["Int"]["output"]>;
  externalState: Maybe<ExternalState>;
  firstName: Scalars["String"]["output"];
  /** The ID of the object. */
  id: Scalars["ID"]["output"];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars["Boolean"]["output"];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars["Boolean"]["output"];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars["Boolean"]["output"];
  language: Maybe<Language>;
  lastLogin: Maybe<Scalars["DateTime"]["output"]>;
  lastName: Scalars["String"]["output"];
  media: MediaConnection;
  /** AppCenter deployment key - use this to force install non-production version of the mobile app. */
  mobileDeploymentKey: Maybe<Scalars["String"]["output"]>;
  notificationsEnabled: Scalars["Boolean"]["output"];
  organizationMemberships: OrganizationMembershipConnection;
  payments: PaymentConnection;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  /** Claimable purchases */
  purchases: PurchaseConnection;
  /** Resolvable invitations */
  receivedInvitations: OrganizationInviteConnection;
  refunds: RefundConnection;
  role: Maybe<Role>;
  sentInvitations: OrganizationInviteConnection;
  /** Returns a list of documentation records that are assigned to the user */
  tasks: DocumentationRecordConnection;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars["String"]["output"];
  /** Versions of the entity, ordered from oldest to newest. Applying filter 'last: 1' returns the current version. */
  versions: VersionConnection;
};

export type UserMediaArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserOrganizationMembershipsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  showInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserPaymentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserPurchasesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserReceivedInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserRefundsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserSentInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserTasksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  allThreeD?: InputMaybe<Scalars["Boolean"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  constructionObjectName?: InputMaybe<Scalars["String"]["input"]>;
  constructionPhaseOrder?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteId?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteName?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>>>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<Array<InputMaybe<DocumentationRecordStateFilterEnum>>>;
  surveyUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDModelUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDOperatorAssigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDOperatorName?: InputMaybe<Scalars["String"]["input"]>;
  toProcess?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<Array<InputMaybe<DocumentationRecordTypeFilterEnum>>>;
  videoType?: InputMaybe<Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>>>;
};

export type UserVersionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 *
 *     Enumeration of validations that could prevent documentation record from submitting.
 *
 *     Each validation has the following format: <model>_<validation>
 *
 *     This can be used to show generic error for <model> - Check ground control points before proceeding,
 *     or for specific error: Video is not ready yet.
 *
 */
export enum Validations {
  ConstructionElementPhotoReady = "constructionElement_photoReady",
  DocumentationRecordAttributeRequired = "documentationRecord_attributeRequired",
  GeodeticSurveyIsReady = "geodeticSurvey_isReady",
  GeodeticSurveyIsSet = "geodeticSurvey_isSet",
  GroundControlPointsGeolocationSet = "groundControlPoints_geolocationSet",
  GroundControlPointsMinimalCount = "groundControlPoints_minimalCount",
  GroundControlPointsPhotoReady = "groundControlPoints_photoReady",
  GroundControlPointsPhotoSet = "groundControlPoints_photoSet",
  OrganizationHasCredits = "organization_hasCredits",
  OrganizationHasCreditsNoVideo = "organization_hasCreditsNoVideo",
  OutputExtensionIsReady = "outputExtension_isReady",
  ThreeDModelIsReady = "threeDModel_isReady",
  ThreeDModelIsSet = "threeDModel_isSet",
  VideoIsReady = "video_isReady",
  VideoIsSet = "video_isSet",
  VideoValidType = "video_validType"
}

export type VerifyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type VerifyPayload = {
  __typename?: "VerifyPayload";
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  payload: Scalars["GenericScalar"]["output"];
};

export type Version = {
  __typename?: "Version";
  comment: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  user: Maybe<User>;
};

export type VersionConnection = {
  __typename?: "VersionConnection";
  edgeCount: Maybe<Scalars["Int"]["output"]>;
  /** Contains the nodes in this connection. */
  edges: Array<VersionEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** A Relay edge containing a `Version` and its cursor. */
export type VersionEdge = {
  __typename?: "VersionEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge */
  node: Version;
};

export type CatalogFragment = { __typename?: "Catalog" } & Pick<
  Catalog,
  | "id"
  | "createDocumentationRecord"
  | "requestThreeDModelNoScale"
  | "requestThreeDModelScale"
  | "requestThreeDModelMaps"
  | "requestThreeDModelSpray"
  | "requestThreeDModelQrCodes"
  | "downloadThreeDModel"
  | "inviteManager"
  | "storageLimits"
  | "storagePrices"
> & {
    storageClassPrices: Maybe<Array<Maybe<{ __typename?: "StorageClass" } & Pick<StorageClass, "maxGb" | "price">>>>;
    requestModelPrices: Maybe<
      { __typename?: "ModelPrices" } & Pick<ModelPrices, "noScale" | "scale" | "maps" | "spray" | "qrCodes">
    >;
  };

export type ConfigFragment = { __typename?: "Config" } & Pick<
  Config,
  | "id"
  | "minimalGroundControlPoints"
  | "threeDModelDeleteAfterDays"
  | "documentationRecordDeleteAfterDays"
  | "organizationInviteSentValidDays"
  | "purchaseExpirationMonths"
  | "helpdeskUrl"
  | "externalAccountUrl"
  | "externalBaseName"
  | "externalBaseUrl"
  | "contactUrl"
  | "logoutRedirectUrl"
  | "providerName"
  | "providerUrl"
  | "providerCrn"
  | "transactionMessagesBccEmail"
  | "allowedGeodeticSurveyFileTypes"
  | "allowedImageFileTypes"
  | "allowedVideoFileTypes"
  | "allowedOtherFileTypes"
  | "shopUrlCs"
  | "shopUrlEn"
  | "shopUrlUk"
  | "shopUrlDe"
  | "defaultConstructionPhasesCs"
  | "defaultConstructionPhasesEn"
  | "defaultConstructionPhasesUk"
  | "defaultConstructionPhasesDe"
  | "documentationRecordReturnReasonsCs"
  | "documentationRecordReturnReasonsEn"
  | "documentationRecordReturnReasonsUk"
  | "documentationRecordReturnReasonsDe"
  | "documentationRecordRejectReasonsCs"
  | "documentationRecordRejectReasonsEn"
  | "documentationRecordRejectReasonsUk"
  | "documentationRecordRejectReasonsDe"
  | "storagePaymentIntervalMonths"
  | "transactionEmailsEnabled"
  | "oauthUserinfoUrl"
  | "fileUploadUrl"
  | "threeDViewerUrl"
  | "emailContactCs"
  | "emailContactEn"
  | "emailContactUk"
  | "emailContactDe"
  | "phoneContactCs"
  | "phoneContactEn"
  | "phoneContactUk"
  | "phoneContactDe"
>;

export type ConstructionElementFragment = { __typename?: "ConstructionElement" } & Pick<
  ConstructionElement,
  "id" | "createdAt" | "description" | "archivedAt"
> & {
    photo: { __typename?: "Media" } & Pick<
      Media,
      "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
    >;
  };

export type ConstructionPhaseSimpleFragment = { __typename?: "ConstructionPhase" } & Pick<
  ConstructionPhase,
  "id" | "name" | "order" | "createdAt" | "archivedAt" | "createdRecords" | "workInProgressRecords" | "completedRecords"
>;

export type ConstructionSiteTableFragment = { __typename?: "ConstructionSite" } & Pick<
  ConstructionSite,
  "id" | "name" | "state" | "createdAt" | "archivedAt" | "createdRecords" | "workInProgressRecords" | "completedRecords"
> & {
    accountableManager: Maybe<{ __typename?: "User" } & Pick<User, "id" | "firstName" | "lastName">>;
    constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
      ConstructionObjectConnection,
      "totalCount"
    >;
    constructionPhases: { __typename?: "ConstructionPhaseConnection" } & Pick<
      ConstructionPhaseConnection,
      "totalCount"
    >;
  };

export type ContactLocalesFragment = { __typename?: "Config" } & Pick<
  Config,
  | "emailContactCs"
  | "emailContactEn"
  | "emailContactUk"
  | "emailContactDe"
  | "phoneContactCs"
  | "phoneContactEn"
  | "phoneContactUk"
  | "phoneContactDe"
>;

export type DiskUsageStatsFragment = { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
    byKind: Array<Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>>;
  };

export type DocumentationRecordFragment = { __typename?: "DocumentationRecord" } & Pick<
  DocumentationRecord,
  "id" | "createdAt" | "archivedAt" | "name" | "type" | "state" | "description" | "simpleGroundControlPointCount"
> & {
    constructionObject: { __typename?: "ConstructionObject" } & Pick<
      ConstructionObject,
      "id" | "name" | "location" | "description"
    > & {
        constructionSite: { __typename?: "ConstructionSite" } & Pick<
          ConstructionSite,
          "id" | "name" | "location" | "description" | "autoRequestThreeD"
        > & { organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "credits"> };
      };
    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
      ConstructionPhase,
      | "id"
      | "name"
      | "order"
      | "createdAt"
      | "archivedAt"
      | "createdRecords"
      | "workInProgressRecords"
      | "completedRecords"
    >;
    threeDModel: Maybe<
      { __typename?: "ThreeDModel" } & Pick<
        ThreeDModel,
        "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
      > & {
          modelFile: Maybe<
            { __typename?: "Media" } & Pick<
              Media,
              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
            >
          >;
          textureFile: Maybe<
            { __typename?: "Media" } & Pick<
              Media,
              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
            >
          >;
          coordinatesFile: Maybe<
            { __typename?: "Media" } & Pick<
              Media,
              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
            >
          >;
          files: Maybe<
            Array<Maybe<{ __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">>>
          >;
        }
    >;
    accountableManager: Maybe<
      { __typename?: "User" } & Pick<
        User,
        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
      >
    >;
    accountableOperator: Maybe<
      { __typename?: "User" } & Pick<
        User,
        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
      >
    >;
    accountableSurveyor: Maybe<
      { __typename?: "User" } & Pick<
        User,
        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
      >
    >;
    accountableThreeDOperator: Maybe<
      { __typename?: "User" } & Pick<
        User,
        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
      >
    >;
    simpleGroundControlPoint: Maybe<
      { __typename?: "GroundControlPointDocumentation" } & Pick<
        GroundControlPointDocumentation,
        "id" | "description"
      > & {
          files: { __typename?: "MediaConnection" } & {
            edges: Array<
              { __typename?: "MediaEdge" } & {
                node: { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >;
              }
            >;
          };
        }
    >;
    groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
      edges: Array<
        { __typename?: "GroundControlPointDocumentationEdge" } & {
          node: { __typename?: "GroundControlPointDocumentation" } & Pick<
            GroundControlPointDocumentation,
            "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
          > & {
              files: { __typename?: "MediaConnection" } & {
                edges: Array<
                  { __typename?: "MediaEdge" } & {
                    node: { __typename?: "Media" } & Pick<
                      Media,
                      "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                    >;
                  }
                >;
              };
            };
        }
      >;
    };
    documentationRecordVideo: Maybe<
      { __typename?: "DocumentationRecordVideo" } & Pick<
        DocumentationRecordVideo,
        "id" | "type" | "captureLocation"
      > & {
          video: Maybe<
            { __typename?: "Media" } & Pick<
              Media,
              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
            >
          >;
        }
    >;
    geodeticSurvey: Maybe<
      { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
          txtSurvey: Maybe<
            { __typename?: "Media" } & Pick<
              Media,
              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
            >
          >;
          files: { __typename?: "MediaConnection" } & {
            edges: Array<
              { __typename?: "MediaEdge" } & {
                node: { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >;
              }
            >;
          };
        }
    >;
    constructionElements: { __typename?: "ConstructionElementConnection" } & {
      edges: Array<
        { __typename?: "ConstructionElementEdge" } & {
          node: { __typename?: "ConstructionElement" } & Pick<
            ConstructionElement,
            "id" | "createdAt" | "description" | "archivedAt"
          > & {
              photo: { __typename?: "Media" } & Pick<
                Media,
                "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
              >;
            };
        }
      >;
    };
    triggers: Array<
      Maybe<
        { __typename?: "DocumentationRecordTrigger" } & Pick<
          DocumentationRecordTrigger,
          "trigger" | "available" | "conditions"
        > & {
            conditionErrors: Array<
              Maybe<
                { __typename?: "DocumentationRecordValidation" } & Pick<
                  DocumentationRecordValidation,
                  "validation" | "attribute"
                >
              >
            >;
          }
      >
    >;
    stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
      edges: Array<
        { __typename?: "DocumentationRecordStateChangeEdge" } & {
          node: { __typename?: "DocumentationRecordStateChange" } & Pick<
            DocumentationRecordStateChange,
            "id" | "comment" | "createdAt" | "fromState" | "rejectionReason" | "returnReason" | "toState" | "trigger"
          > & {
              user: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                >
              >;
            };
        }
      >;
    };
    lastStateChange: Maybe<
      { __typename?: "DocumentationRecordStateChange" } & Pick<
        DocumentationRecordStateChange,
        "id" | "comment" | "createdAt" | "fromState" | "rejectionReason" | "returnReason" | "toState" | "trigger"
      > & {
          user: Maybe<
            { __typename?: "User" } & Pick<
              User,
              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
            >
          >;
        }
    >;
    outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<OutputExtensionConnection, "totalCount">;
    firstVersion: { __typename?: "VersionConnection" } & {
      edges: Array<
        { __typename?: "VersionEdge" } & {
          node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
              user: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                >
              >;
            };
        }
      >;
    };
    currentVersion: { __typename?: "VersionConnection" } & {
      edges: Array<
        { __typename?: "VersionEdge" } & {
          node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
              user: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                >
              >;
            };
        }
      >;
    };
  };

export type DocumentationRecordStateChangeFragment = { __typename?: "DocumentationRecordStateChange" } & Pick<
  DocumentationRecordStateChange,
  "id" | "comment" | "createdAt" | "fromState" | "rejectionReason" | "returnReason" | "toState" | "trigger"
> & {
    user: Maybe<
      { __typename?: "User" } & Pick<
        User,
        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
      >
    >;
  };

export type DocumentationRecordVideoFragment = { __typename?: "DocumentationRecordVideo" } & Pick<
  DocumentationRecordVideo,
  "id" | "type" | "captureLocation"
> & {
    video: Maybe<
      { __typename?: "Media" } & Pick<
        Media,
        "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
      >
    >;
  };

export type GeodeticSurveyFragment = { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
    txtSurvey: Maybe<
      { __typename?: "Media" } & Pick<
        Media,
        "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
      >
    >;
    files: { __typename?: "MediaConnection" } & {
      edges: Array<
        { __typename?: "MediaEdge" } & {
          node: { __typename?: "Media" } & Pick<
            Media,
            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
          >;
        }
      >;
    };
  };

export type GroundControlPointDocumentationFragment = { __typename?: "GroundControlPointDocumentation" } & Pick<
  GroundControlPointDocumentation,
  "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
> & {
    files: { __typename?: "MediaConnection" } & {
      edges: Array<
        { __typename?: "MediaEdge" } & {
          node: { __typename?: "Media" } & Pick<
            Media,
            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
          >;
        }
      >;
    };
  };

export type InquiryTopicLocalesFragment = { __typename?: "InquiryTopic" } & Pick<
  InquiryTopic,
  "topicCs" | "topicEn" | "topicUk" | "topicDe"
>;

export type MediaFragment = { __typename?: "Media" } & Pick<
  Media,
  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
>;

export type ModelPricesFragment = { __typename?: "ModelPrices" } & Pick<
  ModelPrices,
  "noScale" | "scale" | "maps" | "spray" | "qrCodes"
>;

export type OrganizationFragment = { __typename?: "Organization" } & Pick<
  Organization,
  | "id"
  | "name"
  | "activatedAt"
  | "archivedAt"
  | "createdAt"
  | "credits"
  | "crn"
  | "notificationsEnabled"
  | "nextStoragePaymentDate"
> & {
    organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
      edges: Array<
        { __typename?: "OrganizationMembershipEdge" } & {
          node: { __typename?: "OrganizationMembership" } & Pick<OrganizationMembership, "id" | "createdAt"> & {
              user: { __typename?: "User" } & Pick<
                User,
                "externalState" | "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
              >;
              organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "crn" | "activatedAt">;
            };
        }
      >;
    };
    constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<ConstructionSiteConnection, "totalCount">;
    organizationInvites: { __typename?: "OrganizationInviteConnection" } & {
      edges: Array<
        { __typename?: "OrganizationInviteEdge" } & {
          node: { __typename?: "OrganizationInvite" } & Pick<
            OrganizationInvite,
            "id" | "state" | "role" | "createdAt"
          > & {
              user: { __typename?: "User" } & Pick<
                User,
                "externalState" | "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
              >;
            };
        }
      >;
    };
    diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
        byKind: Array<Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>>;
      };
  };

export type OrganizationInviteFragment = { __typename?: "OrganizationInvite" } & Pick<
  OrganizationInvite,
  "id" | "state" | "role" | "createdAt" | "expiresAt"
> & {
    organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
    user: { __typename?: "User" } & Pick<
      User,
      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
    >;
    invitedBy: { __typename?: "User" } & Pick<
      User,
      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
    >;
  };

export type OrganizationInviteSimpleFragment = { __typename?: "OrganizationInvite" } & Pick<
  OrganizationInvite,
  "id" | "state" | "role" | "createdAt"
> & {
    user: { __typename?: "User" } & Pick<
      User,
      "externalState" | "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
    >;
  };

export type OrganizationSimpleFragment = { __typename?: "Organization" } & Pick<
  Organization,
  "id" | "name" | "crn" | "activatedAt"
>;

export type PurchaseSimpleFragment = { __typename?: "Purchase" } & Pick<
  Purchase,
  "id" | "type" | "createdAt" | "claimedAt" | "expiresAt" | "externalId" | "credits"
> & {
    organization: Maybe<
      { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "crn" | "activatedAt"> & {
          diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
              byKind: Array<Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>>;
            };
        }
    >;
  };

export type SimpleGroundControlPointFragment = { __typename?: "GroundControlPointDocumentation" } & Pick<
  GroundControlPointDocumentation,
  "id" | "description"
> & {
    files: { __typename?: "MediaConnection" } & {
      edges: Array<
        { __typename?: "MediaEdge" } & {
          node: { __typename?: "Media" } & Pick<
            Media,
            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
          >;
        }
      >;
    };
  };

export type StorageClassFragment = { __typename?: "StorageClass" } & Pick<StorageClass, "maxGb" | "price">;

export type TaskFragment = { __typename?: "DocumentationRecord" } & Pick<
  DocumentationRecord,
  "id" | "name" | "type" | "state" | "createdAt"
> & {
    accountableThreeDOperator: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
    constructionObject: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name"> & {
        constructionSite: { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name"> & {
            organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
          };
      };
    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id" | "name" | "order">;
    documentationRecordVideo: Maybe<
      { __typename?: "DocumentationRecordVideo" } & Pick<DocumentationRecordVideo, "id" | "type">
    >;
    groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & Pick<
      GroundControlPointDocumentationConnection,
      "totalCount"
    >;
    geodeticSurvey: Maybe<
      { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
          txtSurvey: Maybe<{ __typename?: "Media" } & Pick<Media, "id" | "url">>;
        }
    >;
    lastStateChange: Maybe<
      { __typename?: "DocumentationRecordStateChange" } & Pick<
        DocumentationRecordStateChange,
        "id" | "trigger" | "returnReason" | "createdAt"
      >
    >;
    threeDModel: Maybe<
      { __typename?: "ThreeDModel" } & Pick<
        ThreeDModel,
        "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
      > & {
          modelFile: Maybe<
            { __typename?: "Media" } & Pick<
              Media,
              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
            >
          >;
          textureFile: Maybe<
            { __typename?: "Media" } & Pick<
              Media,
              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
            >
          >;
          coordinatesFile: Maybe<
            { __typename?: "Media" } & Pick<
              Media,
              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
            >
          >;
          files: Maybe<
            Array<Maybe<{ __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">>>
          >;
        }
    >;
  };

export type ThreeDModelFragment = { __typename?: "ThreeDModel" } & Pick<
  ThreeDModel,
  "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
> & {
    modelFile: Maybe<
      { __typename?: "Media" } & Pick<
        Media,
        "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
      >
    >;
    textureFile: Maybe<
      { __typename?: "Media" } & Pick<
        Media,
        "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
      >
    >;
    coordinatesFile: Maybe<
      { __typename?: "Media" } & Pick<
        Media,
        "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
      >
    >;
    files: Maybe<
      Array<Maybe<{ __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">>>
    >;
  };

export type TriggerFragment = { __typename?: "DocumentationRecordTrigger" } & Pick<
  DocumentationRecordTrigger,
  "trigger" | "available" | "conditions"
> & {
    conditionErrors: Array<
      Maybe<
        { __typename?: "DocumentationRecordValidation" } & Pick<
          DocumentationRecordValidation,
          "validation" | "attribute"
        >
      >
    >;
  };

export type UserFragment = { __typename?: "User" } & Pick<
  User,
  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
>;

export type UserInvitationsFragment = { __typename?: "User" } & {
  receivedInvitations: { __typename?: "OrganizationInviteConnection" } & {
    edges: Array<
      { __typename?: "OrganizationInviteEdge" } & {
        node: { __typename?: "OrganizationInvite" } & Pick<
          OrganizationInvite,
          "id" | "state" | "role" | "createdAt" | "expiresAt"
        > & {
            organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
            user: { __typename?: "User" } & Pick<
              User,
              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
            >;
            invitedBy: { __typename?: "User" } & Pick<
              User,
              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
            >;
          };
      }
    >;
  };
};

export type UserOrganizationMembershipsFragment = { __typename?: "User" } & {
  organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
    edges: Array<
      { __typename?: "OrganizationMembershipEdge" } & {
        node: { __typename?: "OrganizationMembership" } & {
          organization: { __typename?: "Organization" } & Pick<
            Organization,
            "id" | "crn" | "name" | "credits" | "createdAt" | "archivedAt" | "activatedAt"
          > & {
              organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                edges: Array<
                  { __typename?: "OrganizationMembershipEdge" } & {
                    node: { __typename?: "OrganizationMembership" } & Pick<OrganizationMembership, "id"> & {
                        user: { __typename?: "User" } & Pick<
                          User,
                          "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                        >;
                      };
                  }
                >;
              };
            };
        };
      }
    >;
  };
};

export type UserPurchasesFragment = { __typename?: "User" } & {
  purchases: { __typename?: "PurchaseConnection" } & {
    edges: Array<
      { __typename?: "PurchaseEdge" } & {
        node: { __typename?: "Purchase" } & Pick<
          Purchase,
          "id" | "type" | "createdAt" | "claimedAt" | "expiresAt" | "externalId" | "credits"
        > & {
            organization: Maybe<
              { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "crn" | "activatedAt"> & {
                  diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                      byKind: Array<
                        Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>
                      >;
                    };
                }
            >;
          };
      }
    >;
  };
};

export type VersionFragment = { __typename?: "Version" } & Pick<Version, "id" | "createdAt" | "comment"> & {
    user: Maybe<
      { __typename?: "User" } & Pick<
        User,
        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
      >
    >;
  };

export type ConstructionElementCreateMutationVariables = Exact<{
  input: ConstructionElementCreateInput;
}>;

export type ConstructionElementCreateMutation = { __typename?: "Mutation" } & {
  constructionElement: Maybe<
    { __typename?: "ConstructionElementOps" } & {
      create: Maybe<
        { __typename?: "ConstructionElementCreatePayload" } & Pick<
          ConstructionElementCreatePayload,
          "clientMutationId"
        > & {
            constructionElement: { __typename?: "ConstructionElement" } & Pick<
              ConstructionElement,
              "id" | "createdAt" | "description" | "archivedAt"
            > & {
                documentationRecord: { __typename?: "DocumentationRecord" } & Pick<
                  DocumentationRecord,
                  | "id"
                  | "createdAt"
                  | "archivedAt"
                  | "name"
                  | "type"
                  | "state"
                  | "description"
                  | "simpleGroundControlPointCount"
                > & {
                    constructionObject: { __typename?: "ConstructionObject" } & Pick<
                      ConstructionObject,
                      "id" | "name" | "location" | "description"
                    > & {
                        constructionSite: { __typename?: "ConstructionSite" } & Pick<
                          ConstructionSite,
                          "id" | "name" | "location" | "description" | "autoRequestThreeD"
                        > & {
                            organization: { __typename?: "Organization" } & Pick<
                              Organization,
                              "id" | "name" | "credits"
                            >;
                          };
                      };
                    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                      ConstructionPhase,
                      | "id"
                      | "name"
                      | "order"
                      | "createdAt"
                      | "archivedAt"
                      | "createdRecords"
                      | "workInProgressRecords"
                      | "completedRecords"
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<
                        ThreeDModel,
                        "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                      > & {
                          modelFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          textureFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          coordinatesFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          files: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                              >
                            >
                          >;
                        }
                    >;
                    accountableManager: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    accountableOperator: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    accountableSurveyor: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    accountableThreeDOperator: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    simpleGroundControlPoint: Maybe<
                      { __typename?: "GroundControlPointDocumentation" } & Pick<
                        GroundControlPointDocumentation,
                        "id" | "description"
                      > & {
                          files: { __typename?: "MediaConnection" } & {
                            edges: Array<
                              { __typename?: "MediaEdge" } & {
                                node: { __typename?: "Media" } & Pick<
                                  Media,
                                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                >;
                              }
                            >;
                          };
                        }
                    >;
                    groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
                      edges: Array<
                        { __typename?: "GroundControlPointDocumentationEdge" } & {
                          node: { __typename?: "GroundControlPointDocumentation" } & Pick<
                            GroundControlPointDocumentation,
                            "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
                          > & {
                              files: { __typename?: "MediaConnection" } & {
                                edges: Array<
                                  { __typename?: "MediaEdge" } & {
                                    node: { __typename?: "Media" } & Pick<
                                      Media,
                                      "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                    >;
                                  }
                                >;
                              };
                            };
                        }
                      >;
                    };
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<
                        DocumentationRecordVideo,
                        "id" | "type" | "captureLocation"
                      > & {
                          video: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                        }
                    >;
                    geodeticSurvey: Maybe<
                      { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                          txtSurvey: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          files: { __typename?: "MediaConnection" } & {
                            edges: Array<
                              { __typename?: "MediaEdge" } & {
                                node: { __typename?: "Media" } & Pick<
                                  Media,
                                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                >;
                              }
                            >;
                          };
                        }
                    >;
                    constructionElements: { __typename?: "ConstructionElementConnection" } & {
                      edges: Array<
                        { __typename?: "ConstructionElementEdge" } & {
                          node: { __typename?: "ConstructionElement" } & Pick<
                            ConstructionElement,
                            "id" | "createdAt" | "description" | "archivedAt"
                          > & {
                              photo: { __typename?: "Media" } & Pick<
                                Media,
                                "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                              >;
                            };
                        }
                      >;
                    };
                    triggers: Array<
                      Maybe<
                        { __typename?: "DocumentationRecordTrigger" } & Pick<
                          DocumentationRecordTrigger,
                          "trigger" | "available" | "conditions"
                        > & {
                            conditionErrors: Array<
                              Maybe<
                                { __typename?: "DocumentationRecordValidation" } & Pick<
                                  DocumentationRecordValidation,
                                  "validation" | "attribute"
                                >
                              >
                            >;
                          }
                      >
                    >;
                    stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
                      edges: Array<
                        { __typename?: "DocumentationRecordStateChangeEdge" } & {
                          node: { __typename?: "DocumentationRecordStateChange" } & Pick<
                            DocumentationRecordStateChange,
                            | "id"
                            | "comment"
                            | "createdAt"
                            | "fromState"
                            | "rejectionReason"
                            | "returnReason"
                            | "toState"
                            | "trigger"
                          > & {
                              user: Maybe<
                                { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                                >
                              >;
                            };
                        }
                      >;
                    };
                    lastStateChange: Maybe<
                      { __typename?: "DocumentationRecordStateChange" } & Pick<
                        DocumentationRecordStateChange,
                        | "id"
                        | "comment"
                        | "createdAt"
                        | "fromState"
                        | "rejectionReason"
                        | "returnReason"
                        | "toState"
                        | "trigger"
                      > & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        }
                    >;
                    outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<
                      OutputExtensionConnection,
                      "totalCount"
                    >;
                    firstVersion: { __typename?: "VersionConnection" } & {
                      edges: Array<
                        { __typename?: "VersionEdge" } & {
                          node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                              user: Maybe<
                                { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                                >
                              >;
                            };
                        }
                      >;
                    };
                    currentVersion: { __typename?: "VersionConnection" } & {
                      edges: Array<
                        { __typename?: "VersionEdge" } & {
                          node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                              user: Maybe<
                                { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                                >
                              >;
                            };
                        }
                      >;
                    };
                  };
                photo: { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >;
              };
          }
      >;
    }
  >;
};

export type ConstructionElementDeleteMutationVariables = Exact<{
  input: ConstructionElementDeleteInput;
}>;

export type ConstructionElementDeleteMutation = { __typename?: "Mutation" } & {
  constructionElement: Maybe<
    { __typename?: "ConstructionElementOps" } & {
      delete: Maybe<
        { __typename?: "ConstructionElementDeletePayload" } & Pick<
          ConstructionElementDeletePayload,
          "clientMutationId"
        > & {
            constructionElement: { __typename?: "ConstructionElement" } & Pick<
              ConstructionElement,
              "id" | "createdAt" | "description" | "archivedAt"
            > & {
                documentationRecord: { __typename?: "DocumentationRecord" } & Pick<
                  DocumentationRecord,
                  | "id"
                  | "createdAt"
                  | "archivedAt"
                  | "name"
                  | "type"
                  | "state"
                  | "description"
                  | "simpleGroundControlPointCount"
                > & {
                    constructionObject: { __typename?: "ConstructionObject" } & Pick<
                      ConstructionObject,
                      "id" | "name" | "location" | "description"
                    > & {
                        constructionSite: { __typename?: "ConstructionSite" } & Pick<
                          ConstructionSite,
                          "id" | "name" | "location" | "description" | "autoRequestThreeD"
                        > & {
                            organization: { __typename?: "Organization" } & Pick<
                              Organization,
                              "id" | "name" | "credits"
                            >;
                          };
                      };
                    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                      ConstructionPhase,
                      | "id"
                      | "name"
                      | "order"
                      | "createdAt"
                      | "archivedAt"
                      | "createdRecords"
                      | "workInProgressRecords"
                      | "completedRecords"
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<
                        ThreeDModel,
                        "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                      > & {
                          modelFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          textureFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          coordinatesFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          files: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                              >
                            >
                          >;
                        }
                    >;
                    accountableManager: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    accountableOperator: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    accountableSurveyor: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    accountableThreeDOperator: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    simpleGroundControlPoint: Maybe<
                      { __typename?: "GroundControlPointDocumentation" } & Pick<
                        GroundControlPointDocumentation,
                        "id" | "description"
                      > & {
                          files: { __typename?: "MediaConnection" } & {
                            edges: Array<
                              { __typename?: "MediaEdge" } & {
                                node: { __typename?: "Media" } & Pick<
                                  Media,
                                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                >;
                              }
                            >;
                          };
                        }
                    >;
                    groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
                      edges: Array<
                        { __typename?: "GroundControlPointDocumentationEdge" } & {
                          node: { __typename?: "GroundControlPointDocumentation" } & Pick<
                            GroundControlPointDocumentation,
                            "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
                          > & {
                              files: { __typename?: "MediaConnection" } & {
                                edges: Array<
                                  { __typename?: "MediaEdge" } & {
                                    node: { __typename?: "Media" } & Pick<
                                      Media,
                                      "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                    >;
                                  }
                                >;
                              };
                            };
                        }
                      >;
                    };
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<
                        DocumentationRecordVideo,
                        "id" | "type" | "captureLocation"
                      > & {
                          video: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                        }
                    >;
                    geodeticSurvey: Maybe<
                      { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                          txtSurvey: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          files: { __typename?: "MediaConnection" } & {
                            edges: Array<
                              { __typename?: "MediaEdge" } & {
                                node: { __typename?: "Media" } & Pick<
                                  Media,
                                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                >;
                              }
                            >;
                          };
                        }
                    >;
                    constructionElements: { __typename?: "ConstructionElementConnection" } & {
                      edges: Array<
                        { __typename?: "ConstructionElementEdge" } & {
                          node: { __typename?: "ConstructionElement" } & Pick<
                            ConstructionElement,
                            "id" | "createdAt" | "description" | "archivedAt"
                          > & {
                              photo: { __typename?: "Media" } & Pick<
                                Media,
                                "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                              >;
                            };
                        }
                      >;
                    };
                    triggers: Array<
                      Maybe<
                        { __typename?: "DocumentationRecordTrigger" } & Pick<
                          DocumentationRecordTrigger,
                          "trigger" | "available" | "conditions"
                        > & {
                            conditionErrors: Array<
                              Maybe<
                                { __typename?: "DocumentationRecordValidation" } & Pick<
                                  DocumentationRecordValidation,
                                  "validation" | "attribute"
                                >
                              >
                            >;
                          }
                      >
                    >;
                    stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
                      edges: Array<
                        { __typename?: "DocumentationRecordStateChangeEdge" } & {
                          node: { __typename?: "DocumentationRecordStateChange" } & Pick<
                            DocumentationRecordStateChange,
                            | "id"
                            | "comment"
                            | "createdAt"
                            | "fromState"
                            | "rejectionReason"
                            | "returnReason"
                            | "toState"
                            | "trigger"
                          > & {
                              user: Maybe<
                                { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                                >
                              >;
                            };
                        }
                      >;
                    };
                    lastStateChange: Maybe<
                      { __typename?: "DocumentationRecordStateChange" } & Pick<
                        DocumentationRecordStateChange,
                        | "id"
                        | "comment"
                        | "createdAt"
                        | "fromState"
                        | "rejectionReason"
                        | "returnReason"
                        | "toState"
                        | "trigger"
                      > & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        }
                    >;
                    outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<
                      OutputExtensionConnection,
                      "totalCount"
                    >;
                    firstVersion: { __typename?: "VersionConnection" } & {
                      edges: Array<
                        { __typename?: "VersionEdge" } & {
                          node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                              user: Maybe<
                                { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                                >
                              >;
                            };
                        }
                      >;
                    };
                    currentVersion: { __typename?: "VersionConnection" } & {
                      edges: Array<
                        { __typename?: "VersionEdge" } & {
                          node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                              user: Maybe<
                                { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                                >
                              >;
                            };
                        }
                      >;
                    };
                  };
                photo: { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >;
              };
          }
      >;
    }
  >;
};

export type ConstructionElementUpdateMutationVariables = Exact<{
  input: ConstructionElementUpdateInput;
}>;

export type ConstructionElementUpdateMutation = { __typename?: "Mutation" } & {
  constructionElement: Maybe<
    { __typename?: "ConstructionElementOps" } & {
      update: Maybe<
        { __typename?: "ConstructionElementUpdatePayload" } & Pick<
          ConstructionElementUpdatePayload,
          "clientMutationId"
        > & {
            constructionElement: { __typename?: "ConstructionElement" } & Pick<
              ConstructionElement,
              "id" | "createdAt" | "description" | "archivedAt"
            > & {
                documentationRecord: { __typename?: "DocumentationRecord" } & Pick<
                  DocumentationRecord,
                  | "id"
                  | "createdAt"
                  | "archivedAt"
                  | "name"
                  | "type"
                  | "state"
                  | "description"
                  | "simpleGroundControlPointCount"
                > & {
                    constructionObject: { __typename?: "ConstructionObject" } & Pick<
                      ConstructionObject,
                      "id" | "name" | "location" | "description"
                    > & {
                        constructionSite: { __typename?: "ConstructionSite" } & Pick<
                          ConstructionSite,
                          "id" | "name" | "location" | "description" | "autoRequestThreeD"
                        > & {
                            organization: { __typename?: "Organization" } & Pick<
                              Organization,
                              "id" | "name" | "credits"
                            >;
                          };
                      };
                    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                      ConstructionPhase,
                      | "id"
                      | "name"
                      | "order"
                      | "createdAt"
                      | "archivedAt"
                      | "createdRecords"
                      | "workInProgressRecords"
                      | "completedRecords"
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<
                        ThreeDModel,
                        "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                      > & {
                          modelFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          textureFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          coordinatesFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          files: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                              >
                            >
                          >;
                        }
                    >;
                    accountableManager: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    accountableOperator: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    accountableSurveyor: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    accountableThreeDOperator: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                      >
                    >;
                    simpleGroundControlPoint: Maybe<
                      { __typename?: "GroundControlPointDocumentation" } & Pick<
                        GroundControlPointDocumentation,
                        "id" | "description"
                      > & {
                          files: { __typename?: "MediaConnection" } & {
                            edges: Array<
                              { __typename?: "MediaEdge" } & {
                                node: { __typename?: "Media" } & Pick<
                                  Media,
                                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                >;
                              }
                            >;
                          };
                        }
                    >;
                    groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
                      edges: Array<
                        { __typename?: "GroundControlPointDocumentationEdge" } & {
                          node: { __typename?: "GroundControlPointDocumentation" } & Pick<
                            GroundControlPointDocumentation,
                            "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
                          > & {
                              files: { __typename?: "MediaConnection" } & {
                                edges: Array<
                                  { __typename?: "MediaEdge" } & {
                                    node: { __typename?: "Media" } & Pick<
                                      Media,
                                      "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                    >;
                                  }
                                >;
                              };
                            };
                        }
                      >;
                    };
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<
                        DocumentationRecordVideo,
                        "id" | "type" | "captureLocation"
                      > & {
                          video: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                        }
                    >;
                    geodeticSurvey: Maybe<
                      { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                          txtSurvey: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          files: { __typename?: "MediaConnection" } & {
                            edges: Array<
                              { __typename?: "MediaEdge" } & {
                                node: { __typename?: "Media" } & Pick<
                                  Media,
                                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                >;
                              }
                            >;
                          };
                        }
                    >;
                    constructionElements: { __typename?: "ConstructionElementConnection" } & {
                      edges: Array<
                        { __typename?: "ConstructionElementEdge" } & {
                          node: { __typename?: "ConstructionElement" } & Pick<
                            ConstructionElement,
                            "id" | "createdAt" | "description" | "archivedAt"
                          > & {
                              photo: { __typename?: "Media" } & Pick<
                                Media,
                                "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                              >;
                            };
                        }
                      >;
                    };
                    triggers: Array<
                      Maybe<
                        { __typename?: "DocumentationRecordTrigger" } & Pick<
                          DocumentationRecordTrigger,
                          "trigger" | "available" | "conditions"
                        > & {
                            conditionErrors: Array<
                              Maybe<
                                { __typename?: "DocumentationRecordValidation" } & Pick<
                                  DocumentationRecordValidation,
                                  "validation" | "attribute"
                                >
                              >
                            >;
                          }
                      >
                    >;
                    stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
                      edges: Array<
                        { __typename?: "DocumentationRecordStateChangeEdge" } & {
                          node: { __typename?: "DocumentationRecordStateChange" } & Pick<
                            DocumentationRecordStateChange,
                            | "id"
                            | "comment"
                            | "createdAt"
                            | "fromState"
                            | "rejectionReason"
                            | "returnReason"
                            | "toState"
                            | "trigger"
                          > & {
                              user: Maybe<
                                { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                                >
                              >;
                            };
                        }
                      >;
                    };
                    lastStateChange: Maybe<
                      { __typename?: "DocumentationRecordStateChange" } & Pick<
                        DocumentationRecordStateChange,
                        | "id"
                        | "comment"
                        | "createdAt"
                        | "fromState"
                        | "rejectionReason"
                        | "returnReason"
                        | "toState"
                        | "trigger"
                      > & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        }
                    >;
                    outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<
                      OutputExtensionConnection,
                      "totalCount"
                    >;
                    firstVersion: { __typename?: "VersionConnection" } & {
                      edges: Array<
                        { __typename?: "VersionEdge" } & {
                          node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                              user: Maybe<
                                { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                                >
                              >;
                            };
                        }
                      >;
                    };
                    currentVersion: { __typename?: "VersionConnection" } & {
                      edges: Array<
                        { __typename?: "VersionEdge" } & {
                          node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                              user: Maybe<
                                { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                                >
                              >;
                            };
                        }
                      >;
                    };
                  };
                photo: { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >;
              };
          }
      >;
    }
  >;
};

export type ConstructionObjectCreateMutationVariables = Exact<{
  input: ConstructionObjectCreateInput;
}>;

export type ConstructionObjectCreateMutation = { __typename?: "Mutation" } & {
  constructionObject: Maybe<
    { __typename?: "ConstructionObjectOps" } & {
      create: Maybe<
        { __typename?: "ConstructionObjectCreatePayload" } & Pick<
          ConstructionObjectCreatePayload,
          "clientMutationId"
        > & {
            constructionObject: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name">;
            constructionSite: { __typename?: "ConstructionSite" } & {
              constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
                ConstructionObjectConnection,
                "totalCount"
              >;
            };
          }
      >;
    }
  >;
};

export type ConstructionObjectDeleteMutationVariables = Exact<{
  input: ConstructionObjectDeleteInput;
}>;

export type ConstructionObjectDeleteMutation = { __typename?: "Mutation" } & {
  constructionObject: Maybe<
    { __typename?: "ConstructionObjectOps" } & {
      delete: Maybe<
        { __typename?: "ConstructionObjectDeletePayload" } & Pick<
          ConstructionObjectDeletePayload,
          "clientMutationId" | "archived"
        > & {
            constructionObject: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name">;
            constructionSite: { __typename?: "ConstructionSite" } & {
              constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
                ConstructionObjectConnection,
                "totalCount"
              >;
            };
            failedRecords: Array<
              Maybe<{ __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id" | "name">>
            >;
          }
      >;
    }
  >;
};

export type ConstructionObjectUpdateMutationVariables = Exact<{
  input: ConstructionObjectUpdateInput;
}>;

export type ConstructionObjectUpdateMutation = { __typename?: "Mutation" } & {
  constructionObject: Maybe<
    { __typename?: "ConstructionObjectOps" } & {
      update: Maybe<
        { __typename?: "ConstructionObjectUpdatePayload" } & Pick<
          ConstructionObjectUpdatePayload,
          "clientMutationId"
        > & {
            constructionObject: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name"> & {
                versions: { __typename?: "VersionConnection" } & {
                  edges: Array<
                    { __typename?: "VersionEdge" } & {
                      node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt">;
                    }
                  >;
                };
              };
          }
      >;
    }
  >;
};

export type ConstructionPhaseCreateMutationVariables = Exact<{
  input: ConstructionPhaseCreateInput;
}>;

export type ConstructionPhaseCreateMutation = { __typename?: "Mutation" } & {
  constructionPhase: Maybe<
    { __typename?: "ConstructionPhaseOps" } & {
      create: Maybe<
        { __typename?: "ConstructionPhaseCreatePayload" } & Pick<ConstructionPhaseCreatePayload, "clientMutationId"> & {
            constructionPhase: { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id">;
          }
      >;
    }
  >;
};

export type ConstructionPhaseDeleteMutationVariables = Exact<{
  input: ConstructionPhaseDeleteInput;
}>;

export type ConstructionPhaseDeleteMutation = { __typename?: "Mutation" } & {
  constructionPhase: Maybe<
    { __typename?: "ConstructionPhaseOps" } & {
      delete: Maybe<
        { __typename?: "ConstructionPhaseDeletePayload" } & Pick<
          ConstructionPhaseDeletePayload,
          "clientMutationId" | "archived"
        > & {
            constructionPhase: { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id" | "name">;
            failedRecords: Array<
              Maybe<{ __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id" | "name">>
            >;
          }
      >;
    }
  >;
};

export type ConstructionPhaseMoveMutationVariables = Exact<{
  input: ConstructionPhaseMoveInput;
}>;

export type ConstructionPhaseMoveMutation = { __typename?: "Mutation" } & {
  constructionPhase: Maybe<
    { __typename?: "ConstructionPhaseOps" } & {
      move: Maybe<
        { __typename?: "ConstructionPhaseMovePayload" } & Pick<ConstructionPhaseMovePayload, "clientMutationId"> & {
            constructionPhase: { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id" | "order">;
          }
      >;
    }
  >;
};

export type ConstructionPhaseUpdateMutationVariables = Exact<{
  input: ConstructionPhaseUpdateInput;
}>;

export type ConstructionPhaseUpdateMutation = { __typename?: "Mutation" } & {
  constructionPhase: Maybe<
    { __typename?: "ConstructionPhaseOps" } & {
      update: Maybe<
        { __typename?: "ConstructionPhaseUpdatePayload" } & Pick<ConstructionPhaseUpdatePayload, "clientMutationId"> & {
            constructionPhase: { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id" | "name">;
          }
      >;
    }
  >;
};

export type ConstructionSiteCreateMutationVariables = Exact<{
  input: ConstructionSiteCreateInput;
}>;

export type ConstructionSiteCreateMutation = { __typename?: "Mutation" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSiteOps" } & {
      create: Maybe<
        { __typename?: "ConstructionSiteCreatePayload" } & Pick<ConstructionSiteCreatePayload, "clientMutationId"> & {
            constructionSite: { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id">;
          }
      >;
    }
  >;
};

export type ConstructionSiteDeleteMutationVariables = Exact<{
  input: ConstructionSiteDeleteInput;
}>;

export type ConstructionSiteDeleteMutation = { __typename?: "Mutation" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSiteOps" } & {
      delete: Maybe<
        { __typename?: "ConstructionSiteDeletePayload" } & Pick<
          ConstructionSiteDeletePayload,
          "archived" | "clientMutationId"
        > & {
            organization: { __typename?: "Organization" } & Pick<
              Organization,
              | "id"
              | "name"
              | "activatedAt"
              | "archivedAt"
              | "createdAt"
              | "credits"
              | "crn"
              | "notificationsEnabled"
              | "nextStoragePaymentDate"
            > & {
                organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                  edges: Array<
                    { __typename?: "OrganizationMembershipEdge" } & {
                      node: { __typename?: "OrganizationMembership" } & Pick<
                        OrganizationMembership,
                        "id" | "createdAt"
                      > & {
                          user: { __typename?: "User" } & Pick<
                            User,
                            | "externalState"
                            | "id"
                            | "role"
                            | "email"
                            | "firstName"
                            | "lastName"
                            | "phoneNumber"
                            | "language"
                          >;
                          organization: { __typename?: "Organization" } & Pick<
                            Organization,
                            "id" | "name" | "crn" | "activatedAt"
                          >;
                        };
                    }
                  >;
                };
                constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
                  ConstructionSiteConnection,
                  "totalCount"
                >;
                organizationInvites: { __typename?: "OrganizationInviteConnection" } & {
                  edges: Array<
                    { __typename?: "OrganizationInviteEdge" } & {
                      node: { __typename?: "OrganizationInvite" } & Pick<
                        OrganizationInvite,
                        "id" | "state" | "role" | "createdAt"
                      > & {
                          user: { __typename?: "User" } & Pick<
                            User,
                            | "externalState"
                            | "id"
                            | "role"
                            | "email"
                            | "firstName"
                            | "lastName"
                            | "phoneNumber"
                            | "language"
                          >;
                        };
                    }
                  >;
                };
                diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                    byKind: Array<
                      Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>
                    >;
                  };
              };
            constructionSite: { __typename?: "ConstructionSite" } & Pick<
              ConstructionSite,
              "id" | "name" | "archivedAt" | "createdRecords" | "workInProgressRecords" | "completedRecords"
            > & {
                constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
                  ConstructionObjectConnection,
                  "totalCount"
                >;
                constructionPhases: { __typename?: "ConstructionPhaseConnection" } & Pick<
                  ConstructionPhaseConnection,
                  "totalCount"
                >;
              };
            failedObjects: Array<
              Maybe<
                { __typename?: "ConstructionObject" } & Pick<
                  ConstructionObject,
                  | "id"
                  | "name"
                  | "createdAt"
                  | "archivedAt"
                  | "createdRecords"
                  | "workInProgressRecords"
                  | "completedRecords"
                >
              >
            >;
            failedPhases: Array<
              Maybe<
                { __typename?: "ConstructionPhase" } & Pick<
                  ConstructionPhase,
                  | "id"
                  | "name"
                  | "order"
                  | "createdAt"
                  | "archivedAt"
                  | "createdRecords"
                  | "workInProgressRecords"
                  | "completedRecords"
                >
              >
            >;
          }
      >;
    }
  >;
};

export type ConstructionSiteUpdateMutationVariables = Exact<{
  input: ConstructionSiteUpdateInput;
}>;

export type ConstructionSiteUpdateMutation = { __typename?: "Mutation" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSiteOps" } & {
      update: Maybe<
        { __typename?: "ConstructionSiteUpdatePayload" } & Pick<ConstructionSiteUpdatePayload, "clientMutationId"> & {
            constructionSite: { __typename?: "ConstructionSite" } & Pick<
              ConstructionSite,
              "id" | "name" | "description"
            >;
          }
      >;
    }
  >;
};

export type DocumentationRecordAssignThreeDOperatorMutationVariables = Exact<{
  input: DocumentationRecordAssignThreeDOperatorInput;
}>;

export type DocumentationRecordAssignThreeDOperatorMutation = { __typename?: "Mutation" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecordOps" } & {
      assignThreeDOperator: Maybe<
        { __typename?: "DocumentationRecordAssignThreeDOperatorPayload" } & Pick<
          DocumentationRecordAssignThreeDOperatorPayload,
          "clientMutationId"
        > & { documentationRecord: { __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id"> }
      >;
    }
  >;
};

export type DocumentationRecordCreateMutationVariables = Exact<{
  input: DocumentationRecordCreateInput;
}>;

export type DocumentationRecordCreateMutation = { __typename?: "Mutation" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecordOps" } & {
      create: Maybe<
        { __typename?: "DocumentationRecordCreatePayload" } & Pick<
          DocumentationRecordCreatePayload,
          "clientMutationId"
        > & {
            documentationRecord: Maybe<
              { __typename?: "DocumentationRecord" } & Pick<
                DocumentationRecord,
                | "id"
                | "createdAt"
                | "archivedAt"
                | "name"
                | "type"
                | "state"
                | "description"
                | "simpleGroundControlPointCount"
              > & {
                  constructionObject: { __typename?: "ConstructionObject" } & Pick<
                    ConstructionObject,
                    "id" | "name" | "location" | "description"
                  > & {
                      constructionSite: { __typename?: "ConstructionSite" } & Pick<
                        ConstructionSite,
                        "id" | "name" | "location" | "description" | "autoRequestThreeD"
                      > & {
                          organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "credits">;
                        };
                    };
                  constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                    ConstructionPhase,
                    | "id"
                    | "name"
                    | "order"
                    | "createdAt"
                    | "archivedAt"
                    | "createdRecords"
                    | "workInProgressRecords"
                    | "completedRecords"
                  >;
                  threeDModel: Maybe<
                    { __typename?: "ThreeDModel" } & Pick<
                      ThreeDModel,
                      "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                    > & {
                        modelFile: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                        textureFile: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                        coordinatesFile: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                        files: Maybe<
                          Array<
                            Maybe<
                              { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                            >
                          >
                        >;
                      }
                  >;
                  accountableManager: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                  accountableOperator: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                  accountableSurveyor: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                  accountableThreeDOperator: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                  simpleGroundControlPoint: Maybe<
                    { __typename?: "GroundControlPointDocumentation" } & Pick<
                      GroundControlPointDocumentation,
                      "id" | "description"
                    > & {
                        files: { __typename?: "MediaConnection" } & {
                          edges: Array<
                            { __typename?: "MediaEdge" } & {
                              node: { __typename?: "Media" } & Pick<
                                Media,
                                "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                              >;
                            }
                          >;
                        };
                      }
                  >;
                  groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
                    edges: Array<
                      { __typename?: "GroundControlPointDocumentationEdge" } & {
                        node: { __typename?: "GroundControlPointDocumentation" } & Pick<
                          GroundControlPointDocumentation,
                          "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
                        > & {
                            files: { __typename?: "MediaConnection" } & {
                              edges: Array<
                                { __typename?: "MediaEdge" } & {
                                  node: { __typename?: "Media" } & Pick<
                                    Media,
                                    "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                  >;
                                }
                              >;
                            };
                          };
                      }
                    >;
                  };
                  documentationRecordVideo: Maybe<
                    { __typename?: "DocumentationRecordVideo" } & Pick<
                      DocumentationRecordVideo,
                      "id" | "type" | "captureLocation"
                    > & {
                        video: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                      }
                  >;
                  geodeticSurvey: Maybe<
                    { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                        txtSurvey: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                        files: { __typename?: "MediaConnection" } & {
                          edges: Array<
                            { __typename?: "MediaEdge" } & {
                              node: { __typename?: "Media" } & Pick<
                                Media,
                                "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                              >;
                            }
                          >;
                        };
                      }
                  >;
                  constructionElements: { __typename?: "ConstructionElementConnection" } & {
                    edges: Array<
                      { __typename?: "ConstructionElementEdge" } & {
                        node: { __typename?: "ConstructionElement" } & Pick<
                          ConstructionElement,
                          "id" | "createdAt" | "description" | "archivedAt"
                        > & {
                            photo: { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >;
                          };
                      }
                    >;
                  };
                  triggers: Array<
                    Maybe<
                      { __typename?: "DocumentationRecordTrigger" } & Pick<
                        DocumentationRecordTrigger,
                        "trigger" | "available" | "conditions"
                      > & {
                          conditionErrors: Array<
                            Maybe<
                              { __typename?: "DocumentationRecordValidation" } & Pick<
                                DocumentationRecordValidation,
                                "validation" | "attribute"
                              >
                            >
                          >;
                        }
                    >
                  >;
                  stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
                    edges: Array<
                      { __typename?: "DocumentationRecordStateChangeEdge" } & {
                        node: { __typename?: "DocumentationRecordStateChange" } & Pick<
                          DocumentationRecordStateChange,
                          | "id"
                          | "comment"
                          | "createdAt"
                          | "fromState"
                          | "rejectionReason"
                          | "returnReason"
                          | "toState"
                          | "trigger"
                        > & {
                            user: Maybe<
                              { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >
                            >;
                          };
                      }
                    >;
                  };
                  lastStateChange: Maybe<
                    { __typename?: "DocumentationRecordStateChange" } & Pick<
                      DocumentationRecordStateChange,
                      | "id"
                      | "comment"
                      | "createdAt"
                      | "fromState"
                      | "rejectionReason"
                      | "returnReason"
                      | "toState"
                      | "trigger"
                    > & {
                        user: Maybe<
                          { __typename?: "User" } & Pick<
                            User,
                            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                          >
                        >;
                      }
                  >;
                  outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<
                    OutputExtensionConnection,
                    "totalCount"
                  >;
                  firstVersion: { __typename?: "VersionConnection" } & {
                    edges: Array<
                      { __typename?: "VersionEdge" } & {
                        node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                            user: Maybe<
                              { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >
                            >;
                          };
                      }
                    >;
                  };
                  currentVersion: { __typename?: "VersionConnection" } & {
                    edges: Array<
                      { __typename?: "VersionEdge" } & {
                        node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                            user: Maybe<
                              { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >
                            >;
                          };
                      }
                    >;
                  };
                }
            >;
          }
      >;
    }
  >;
};

export type DocumentationRecordDeleteMutationVariables = Exact<{
  input: DocumentationRecordDeleteInput;
}>;

export type DocumentationRecordDeleteMutation = { __typename?: "Mutation" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecordOps" } & {
      delete: Maybe<
        { __typename?: "DocumentationRecordDeletePayload" } & Pick<
          DocumentationRecordDeletePayload,
          "clientMutationId"
        > & {
            documentationRecord: { __typename?: "DocumentationRecord" } & Pick<
              DocumentationRecord,
              | "id"
              | "createdAt"
              | "archivedAt"
              | "name"
              | "type"
              | "state"
              | "description"
              | "simpleGroundControlPointCount"
            > & {
                constructionObject: { __typename?: "ConstructionObject" } & Pick<
                  ConstructionObject,
                  "id" | "name" | "location" | "description"
                > & {
                    constructionSite: { __typename?: "ConstructionSite" } & Pick<
                      ConstructionSite,
                      "id" | "name" | "location" | "description" | "autoRequestThreeD"
                    > & {
                        organization: { __typename?: "Organization" } & Pick<
                          Organization,
                          | "id"
                          | "name"
                          | "credits"
                          | "activatedAt"
                          | "archivedAt"
                          | "createdAt"
                          | "crn"
                          | "notificationsEnabled"
                          | "nextStoragePaymentDate"
                        > & {
                            organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                              edges: Array<
                                { __typename?: "OrganizationMembershipEdge" } & {
                                  node: { __typename?: "OrganizationMembership" } & Pick<
                                    OrganizationMembership,
                                    "id" | "createdAt"
                                  > & {
                                      user: { __typename?: "User" } & Pick<
                                        User,
                                        | "externalState"
                                        | "id"
                                        | "role"
                                        | "email"
                                        | "firstName"
                                        | "lastName"
                                        | "phoneNumber"
                                        | "language"
                                      >;
                                      organization: { __typename?: "Organization" } & Pick<
                                        Organization,
                                        "id" | "name" | "crn" | "activatedAt"
                                      >;
                                    };
                                }
                              >;
                            };
                            constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
                              ConstructionSiteConnection,
                              "totalCount"
                            >;
                            organizationInvites: { __typename?: "OrganizationInviteConnection" } & {
                              edges: Array<
                                { __typename?: "OrganizationInviteEdge" } & {
                                  node: { __typename?: "OrganizationInvite" } & Pick<
                                    OrganizationInvite,
                                    "id" | "state" | "role" | "createdAt"
                                  > & {
                                      user: { __typename?: "User" } & Pick<
                                        User,
                                        | "externalState"
                                        | "id"
                                        | "role"
                                        | "email"
                                        | "firstName"
                                        | "lastName"
                                        | "phoneNumber"
                                        | "language"
                                      >;
                                    };
                                }
                              >;
                            };
                            diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                                byKind: Array<
                                  Maybe<
                                    { __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">
                                  >
                                >;
                              };
                          };
                      };
                  };
                constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                  ConstructionPhase,
                  | "id"
                  | "name"
                  | "order"
                  | "createdAt"
                  | "archivedAt"
                  | "createdRecords"
                  | "workInProgressRecords"
                  | "completedRecords"
                >;
                threeDModel: Maybe<
                  { __typename?: "ThreeDModel" } & Pick<
                    ThreeDModel,
                    "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                  > & {
                      modelFile: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      textureFile: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      coordinatesFile: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      files: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                          >
                        >
                      >;
                    }
                >;
                accountableManager: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                accountableOperator: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                accountableSurveyor: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                accountableThreeDOperator: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                simpleGroundControlPoint: Maybe<
                  { __typename?: "GroundControlPointDocumentation" } & Pick<
                    GroundControlPointDocumentation,
                    "id" | "description"
                  > & {
                      files: { __typename?: "MediaConnection" } & {
                        edges: Array<
                          { __typename?: "MediaEdge" } & {
                            node: { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >;
                          }
                        >;
                      };
                    }
                >;
                groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
                  edges: Array<
                    { __typename?: "GroundControlPointDocumentationEdge" } & {
                      node: { __typename?: "GroundControlPointDocumentation" } & Pick<
                        GroundControlPointDocumentation,
                        "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
                      > & {
                          files: { __typename?: "MediaConnection" } & {
                            edges: Array<
                              { __typename?: "MediaEdge" } & {
                                node: { __typename?: "Media" } & Pick<
                                  Media,
                                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                >;
                              }
                            >;
                          };
                        };
                    }
                  >;
                };
                documentationRecordVideo: Maybe<
                  { __typename?: "DocumentationRecordVideo" } & Pick<
                    DocumentationRecordVideo,
                    "id" | "type" | "captureLocation"
                  > & {
                      video: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                    }
                >;
                geodeticSurvey: Maybe<
                  { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                      txtSurvey: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      files: { __typename?: "MediaConnection" } & {
                        edges: Array<
                          { __typename?: "MediaEdge" } & {
                            node: { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >;
                          }
                        >;
                      };
                    }
                >;
                constructionElements: { __typename?: "ConstructionElementConnection" } & {
                  edges: Array<
                    { __typename?: "ConstructionElementEdge" } & {
                      node: { __typename?: "ConstructionElement" } & Pick<
                        ConstructionElement,
                        "id" | "createdAt" | "description" | "archivedAt"
                      > & {
                          photo: { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >;
                        };
                    }
                  >;
                };
                triggers: Array<
                  Maybe<
                    { __typename?: "DocumentationRecordTrigger" } & Pick<
                      DocumentationRecordTrigger,
                      "trigger" | "available" | "conditions"
                    > & {
                        conditionErrors: Array<
                          Maybe<
                            { __typename?: "DocumentationRecordValidation" } & Pick<
                              DocumentationRecordValidation,
                              "validation" | "attribute"
                            >
                          >
                        >;
                      }
                  >
                >;
                stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
                  edges: Array<
                    { __typename?: "DocumentationRecordStateChangeEdge" } & {
                      node: { __typename?: "DocumentationRecordStateChange" } & Pick<
                        DocumentationRecordStateChange,
                        | "id"
                        | "comment"
                        | "createdAt"
                        | "fromState"
                        | "rejectionReason"
                        | "returnReason"
                        | "toState"
                        | "trigger"
                      > & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        };
                    }
                  >;
                };
                lastStateChange: Maybe<
                  { __typename?: "DocumentationRecordStateChange" } & Pick<
                    DocumentationRecordStateChange,
                    | "id"
                    | "comment"
                    | "createdAt"
                    | "fromState"
                    | "rejectionReason"
                    | "returnReason"
                    | "toState"
                    | "trigger"
                  > & {
                      user: Maybe<
                        { __typename?: "User" } & Pick<
                          User,
                          "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                        >
                      >;
                    }
                >;
                outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<
                  OutputExtensionConnection,
                  "totalCount"
                >;
                firstVersion: { __typename?: "VersionConnection" } & {
                  edges: Array<
                    { __typename?: "VersionEdge" } & {
                      node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        };
                    }
                  >;
                };
                currentVersion: { __typename?: "VersionConnection" } & {
                  edges: Array<
                    { __typename?: "VersionEdge" } & {
                      node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        };
                    }
                  >;
                };
              };
          }
      >;
    }
  >;
};

export type DocumentationRecordRelocateMutationVariables = Exact<{
  input: DocumentationRecordRelocateInput;
}>;

export type DocumentationRecordRelocateMutation = { __typename?: "Mutation" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecordOps" } & {
      relocate: Maybe<
        { __typename?: "DocumentationRecordRelocatePayload" } & {
          documentationRecord: Maybe<
            { __typename?: "DocumentationRecord" } & Pick<
              DocumentationRecord,
              | "id"
              | "createdAt"
              | "archivedAt"
              | "name"
              | "type"
              | "state"
              | "description"
              | "simpleGroundControlPointCount"
            > & {
                constructionObject: { __typename?: "ConstructionObject" } & Pick<
                  ConstructionObject,
                  "id" | "name" | "location" | "description"
                > & {
                    constructionSite: { __typename?: "ConstructionSite" } & Pick<
                      ConstructionSite,
                      "id" | "name" | "location" | "description" | "autoRequestThreeD"
                    > & {
                        organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "credits">;
                      };
                  };
                constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                  ConstructionPhase,
                  | "id"
                  | "name"
                  | "order"
                  | "createdAt"
                  | "archivedAt"
                  | "createdRecords"
                  | "workInProgressRecords"
                  | "completedRecords"
                >;
                threeDModel: Maybe<
                  { __typename?: "ThreeDModel" } & Pick<
                    ThreeDModel,
                    "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                  > & {
                      modelFile: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      textureFile: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      coordinatesFile: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      files: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                          >
                        >
                      >;
                    }
                >;
                accountableManager: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                accountableOperator: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                accountableSurveyor: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                accountableThreeDOperator: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                simpleGroundControlPoint: Maybe<
                  { __typename?: "GroundControlPointDocumentation" } & Pick<
                    GroundControlPointDocumentation,
                    "id" | "description"
                  > & {
                      files: { __typename?: "MediaConnection" } & {
                        edges: Array<
                          { __typename?: "MediaEdge" } & {
                            node: { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >;
                          }
                        >;
                      };
                    }
                >;
                groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
                  edges: Array<
                    { __typename?: "GroundControlPointDocumentationEdge" } & {
                      node: { __typename?: "GroundControlPointDocumentation" } & Pick<
                        GroundControlPointDocumentation,
                        "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
                      > & {
                          files: { __typename?: "MediaConnection" } & {
                            edges: Array<
                              { __typename?: "MediaEdge" } & {
                                node: { __typename?: "Media" } & Pick<
                                  Media,
                                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                >;
                              }
                            >;
                          };
                        };
                    }
                  >;
                };
                documentationRecordVideo: Maybe<
                  { __typename?: "DocumentationRecordVideo" } & Pick<
                    DocumentationRecordVideo,
                    "id" | "type" | "captureLocation"
                  > & {
                      video: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                    }
                >;
                geodeticSurvey: Maybe<
                  { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                      txtSurvey: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      files: { __typename?: "MediaConnection" } & {
                        edges: Array<
                          { __typename?: "MediaEdge" } & {
                            node: { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >;
                          }
                        >;
                      };
                    }
                >;
                constructionElements: { __typename?: "ConstructionElementConnection" } & {
                  edges: Array<
                    { __typename?: "ConstructionElementEdge" } & {
                      node: { __typename?: "ConstructionElement" } & Pick<
                        ConstructionElement,
                        "id" | "createdAt" | "description" | "archivedAt"
                      > & {
                          photo: { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >;
                        };
                    }
                  >;
                };
                triggers: Array<
                  Maybe<
                    { __typename?: "DocumentationRecordTrigger" } & Pick<
                      DocumentationRecordTrigger,
                      "trigger" | "available" | "conditions"
                    > & {
                        conditionErrors: Array<
                          Maybe<
                            { __typename?: "DocumentationRecordValidation" } & Pick<
                              DocumentationRecordValidation,
                              "validation" | "attribute"
                            >
                          >
                        >;
                      }
                  >
                >;
                stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
                  edges: Array<
                    { __typename?: "DocumentationRecordStateChangeEdge" } & {
                      node: { __typename?: "DocumentationRecordStateChange" } & Pick<
                        DocumentationRecordStateChange,
                        | "id"
                        | "comment"
                        | "createdAt"
                        | "fromState"
                        | "rejectionReason"
                        | "returnReason"
                        | "toState"
                        | "trigger"
                      > & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        };
                    }
                  >;
                };
                lastStateChange: Maybe<
                  { __typename?: "DocumentationRecordStateChange" } & Pick<
                    DocumentationRecordStateChange,
                    | "id"
                    | "comment"
                    | "createdAt"
                    | "fromState"
                    | "rejectionReason"
                    | "returnReason"
                    | "toState"
                    | "trigger"
                  > & {
                      user: Maybe<
                        { __typename?: "User" } & Pick<
                          User,
                          "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                        >
                      >;
                    }
                >;
                outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<
                  OutputExtensionConnection,
                  "totalCount"
                >;
                firstVersion: { __typename?: "VersionConnection" } & {
                  edges: Array<
                    { __typename?: "VersionEdge" } & {
                      node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        };
                    }
                  >;
                };
                currentVersion: { __typename?: "VersionConnection" } & {
                  edges: Array<
                    { __typename?: "VersionEdge" } & {
                      node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        };
                    }
                  >;
                };
              }
          >;
        }
      >;
    }
  >;
};

export type DocumentationRecordTriggerMutationVariables = Exact<{
  input: DocumentationRecordTriggerInput;
}>;

export type DocumentationRecordTriggerMutation = { __typename?: "Mutation" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecordOps" } & {
      trigger: Maybe<
        { __typename?: "DocumentationRecordTriggerPayload" } & Pick<
          DocumentationRecordTriggerPayload,
          "clientMutationId"
        > & {
            documentationRecord: { __typename?: "DocumentationRecord" } & Pick<
              DocumentationRecord,
              | "id"
              | "createdAt"
              | "archivedAt"
              | "name"
              | "type"
              | "state"
              | "description"
              | "simpleGroundControlPointCount"
            > & {
                constructionObject: { __typename?: "ConstructionObject" } & Pick<
                  ConstructionObject,
                  "id" | "name" | "location" | "description"
                > & {
                    constructionSite: { __typename?: "ConstructionSite" } & Pick<
                      ConstructionSite,
                      "id" | "name" | "location" | "description" | "autoRequestThreeD"
                    > & {
                        organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "credits">;
                      };
                  };
                constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                  ConstructionPhase,
                  | "id"
                  | "name"
                  | "order"
                  | "createdAt"
                  | "archivedAt"
                  | "createdRecords"
                  | "workInProgressRecords"
                  | "completedRecords"
                >;
                threeDModel: Maybe<
                  { __typename?: "ThreeDModel" } & Pick<
                    ThreeDModel,
                    "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                  > & {
                      modelFile: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      textureFile: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      coordinatesFile: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      files: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                          >
                        >
                      >;
                    }
                >;
                accountableManager: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                accountableOperator: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                accountableSurveyor: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                accountableThreeDOperator: Maybe<
                  { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >
                >;
                simpleGroundControlPoint: Maybe<
                  { __typename?: "GroundControlPointDocumentation" } & Pick<
                    GroundControlPointDocumentation,
                    "id" | "description"
                  > & {
                      files: { __typename?: "MediaConnection" } & {
                        edges: Array<
                          { __typename?: "MediaEdge" } & {
                            node: { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >;
                          }
                        >;
                      };
                    }
                >;
                groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
                  edges: Array<
                    { __typename?: "GroundControlPointDocumentationEdge" } & {
                      node: { __typename?: "GroundControlPointDocumentation" } & Pick<
                        GroundControlPointDocumentation,
                        "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
                      > & {
                          files: { __typename?: "MediaConnection" } & {
                            edges: Array<
                              { __typename?: "MediaEdge" } & {
                                node: { __typename?: "Media" } & Pick<
                                  Media,
                                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                >;
                              }
                            >;
                          };
                        };
                    }
                  >;
                };
                documentationRecordVideo: Maybe<
                  { __typename?: "DocumentationRecordVideo" } & Pick<
                    DocumentationRecordVideo,
                    "id" | "type" | "captureLocation"
                  > & {
                      video: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                    }
                >;
                geodeticSurvey: Maybe<
                  { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                      txtSurvey: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >
                      >;
                      files: { __typename?: "MediaConnection" } & {
                        edges: Array<
                          { __typename?: "MediaEdge" } & {
                            node: { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >;
                          }
                        >;
                      };
                    }
                >;
                constructionElements: { __typename?: "ConstructionElementConnection" } & {
                  edges: Array<
                    { __typename?: "ConstructionElementEdge" } & {
                      node: { __typename?: "ConstructionElement" } & Pick<
                        ConstructionElement,
                        "id" | "createdAt" | "description" | "archivedAt"
                      > & {
                          photo: { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >;
                        };
                    }
                  >;
                };
                triggers: Array<
                  Maybe<
                    { __typename?: "DocumentationRecordTrigger" } & Pick<
                      DocumentationRecordTrigger,
                      "trigger" | "available" | "conditions"
                    > & {
                        conditionErrors: Array<
                          Maybe<
                            { __typename?: "DocumentationRecordValidation" } & Pick<
                              DocumentationRecordValidation,
                              "validation" | "attribute"
                            >
                          >
                        >;
                      }
                  >
                >;
                stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
                  edges: Array<
                    { __typename?: "DocumentationRecordStateChangeEdge" } & {
                      node: { __typename?: "DocumentationRecordStateChange" } & Pick<
                        DocumentationRecordStateChange,
                        | "id"
                        | "comment"
                        | "createdAt"
                        | "fromState"
                        | "rejectionReason"
                        | "returnReason"
                        | "toState"
                        | "trigger"
                      > & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        };
                    }
                  >;
                };
                lastStateChange: Maybe<
                  { __typename?: "DocumentationRecordStateChange" } & Pick<
                    DocumentationRecordStateChange,
                    | "id"
                    | "comment"
                    | "createdAt"
                    | "fromState"
                    | "rejectionReason"
                    | "returnReason"
                    | "toState"
                    | "trigger"
                  > & {
                      user: Maybe<
                        { __typename?: "User" } & Pick<
                          User,
                          "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                        >
                      >;
                    }
                >;
                outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<
                  OutputExtensionConnection,
                  "totalCount"
                >;
                firstVersion: { __typename?: "VersionConnection" } & {
                  edges: Array<
                    { __typename?: "VersionEdge" } & {
                      node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        };
                    }
                  >;
                };
                currentVersion: { __typename?: "VersionConnection" } & {
                  edges: Array<
                    { __typename?: "VersionEdge" } & {
                      node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                          user: Maybe<
                            { __typename?: "User" } & Pick<
                              User,
                              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                            >
                          >;
                        };
                    }
                  >;
                };
              };
          }
      >;
    }
  >;
};

export type DocumentationRecordUpdateMutationVariables = Exact<{
  input: DocumentationRecordUpdateInput;
}>;

export type DocumentationRecordUpdateMutation = { __typename?: "Mutation" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecordOps" } & {
      update: Maybe<
        { __typename?: "DocumentationRecordUpdatePayload" } & Pick<
          DocumentationRecordUpdatePayload,
          "clientMutationId"
        > & {
            documentationRecord: Maybe<
              { __typename?: "DocumentationRecord" } & Pick<
                DocumentationRecord,
                | "id"
                | "createdAt"
                | "archivedAt"
                | "name"
                | "type"
                | "state"
                | "description"
                | "simpleGroundControlPointCount"
              > & {
                  constructionObject: { __typename?: "ConstructionObject" } & Pick<
                    ConstructionObject,
                    "id" | "name" | "location" | "description"
                  > & {
                      constructionSite: { __typename?: "ConstructionSite" } & Pick<
                        ConstructionSite,
                        "id" | "name" | "location" | "description" | "autoRequestThreeD"
                      > & {
                          organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "credits">;
                        };
                    };
                  constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                    ConstructionPhase,
                    | "id"
                    | "name"
                    | "order"
                    | "createdAt"
                    | "archivedAt"
                    | "createdRecords"
                    | "workInProgressRecords"
                    | "completedRecords"
                  >;
                  threeDModel: Maybe<
                    { __typename?: "ThreeDModel" } & Pick<
                      ThreeDModel,
                      "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                    > & {
                        modelFile: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                        textureFile: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                        coordinatesFile: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                        files: Maybe<
                          Array<
                            Maybe<
                              { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                            >
                          >
                        >;
                      }
                  >;
                  accountableManager: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                  accountableOperator: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                  accountableSurveyor: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                  accountableThreeDOperator: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                  simpleGroundControlPoint: Maybe<
                    { __typename?: "GroundControlPointDocumentation" } & Pick<
                      GroundControlPointDocumentation,
                      "id" | "description"
                    > & {
                        files: { __typename?: "MediaConnection" } & {
                          edges: Array<
                            { __typename?: "MediaEdge" } & {
                              node: { __typename?: "Media" } & Pick<
                                Media,
                                "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                              >;
                            }
                          >;
                        };
                      }
                  >;
                  groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
                    edges: Array<
                      { __typename?: "GroundControlPointDocumentationEdge" } & {
                        node: { __typename?: "GroundControlPointDocumentation" } & Pick<
                          GroundControlPointDocumentation,
                          "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
                        > & {
                            files: { __typename?: "MediaConnection" } & {
                              edges: Array<
                                { __typename?: "MediaEdge" } & {
                                  node: { __typename?: "Media" } & Pick<
                                    Media,
                                    "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                                  >;
                                }
                              >;
                            };
                          };
                      }
                    >;
                  };
                  documentationRecordVideo: Maybe<
                    { __typename?: "DocumentationRecordVideo" } & Pick<
                      DocumentationRecordVideo,
                      "id" | "type" | "captureLocation"
                    > & {
                        video: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                      }
                  >;
                  geodeticSurvey: Maybe<
                    { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                        txtSurvey: Maybe<
                          { __typename?: "Media" } & Pick<
                            Media,
                            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                          >
                        >;
                        files: { __typename?: "MediaConnection" } & {
                          edges: Array<
                            { __typename?: "MediaEdge" } & {
                              node: { __typename?: "Media" } & Pick<
                                Media,
                                "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                              >;
                            }
                          >;
                        };
                      }
                  >;
                  constructionElements: { __typename?: "ConstructionElementConnection" } & {
                    edges: Array<
                      { __typename?: "ConstructionElementEdge" } & {
                        node: { __typename?: "ConstructionElement" } & Pick<
                          ConstructionElement,
                          "id" | "createdAt" | "description" | "archivedAt"
                        > & {
                            photo: { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >;
                          };
                      }
                    >;
                  };
                  triggers: Array<
                    Maybe<
                      { __typename?: "DocumentationRecordTrigger" } & Pick<
                        DocumentationRecordTrigger,
                        "trigger" | "available" | "conditions"
                      > & {
                          conditionErrors: Array<
                            Maybe<
                              { __typename?: "DocumentationRecordValidation" } & Pick<
                                DocumentationRecordValidation,
                                "validation" | "attribute"
                              >
                            >
                          >;
                        }
                    >
                  >;
                  stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
                    edges: Array<
                      { __typename?: "DocumentationRecordStateChangeEdge" } & {
                        node: { __typename?: "DocumentationRecordStateChange" } & Pick<
                          DocumentationRecordStateChange,
                          | "id"
                          | "comment"
                          | "createdAt"
                          | "fromState"
                          | "rejectionReason"
                          | "returnReason"
                          | "toState"
                          | "trigger"
                        > & {
                            user: Maybe<
                              { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >
                            >;
                          };
                      }
                    >;
                  };
                  lastStateChange: Maybe<
                    { __typename?: "DocumentationRecordStateChange" } & Pick<
                      DocumentationRecordStateChange,
                      | "id"
                      | "comment"
                      | "createdAt"
                      | "fromState"
                      | "rejectionReason"
                      | "returnReason"
                      | "toState"
                      | "trigger"
                    > & {
                        user: Maybe<
                          { __typename?: "User" } & Pick<
                            User,
                            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                          >
                        >;
                      }
                  >;
                  outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<
                    OutputExtensionConnection,
                    "totalCount"
                  >;
                  firstVersion: { __typename?: "VersionConnection" } & {
                    edges: Array<
                      { __typename?: "VersionEdge" } & {
                        node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                            user: Maybe<
                              { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >
                            >;
                          };
                      }
                    >;
                  };
                  currentVersion: { __typename?: "VersionConnection" } & {
                    edges: Array<
                      { __typename?: "VersionEdge" } & {
                        node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                            user: Maybe<
                              { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >
                            >;
                          };
                      }
                    >;
                  };
                }
            >;
          }
      >;
    }
  >;
};

export type GroundControlPointDocumentationCreateMutationVariables = Exact<{
  input: GroundControlPointDocumentationCreateInput;
}>;

export type GroundControlPointDocumentationCreateMutation = { __typename?: "Mutation" } & {
  groundControlPointDocumentation: Maybe<
    { __typename?: "GroundControlPointDocumentationOps" } & {
      create: Maybe<
        { __typename?: "GroundControlPointDocumentationCreatePayload" } & Pick<
          GroundControlPointDocumentationCreatePayload,
          "clientMutationId"
        > & {
            groundControlPointDocumentation: { __typename?: "GroundControlPointDocumentation" } & Pick<
              GroundControlPointDocumentation,
              "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
            > & {
                documentationRecord: { __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id"> & {
                    triggers: Array<
                      Maybe<
                        { __typename?: "DocumentationRecordTrigger" } & Pick<
                          DocumentationRecordTrigger,
                          "trigger" | "available" | "conditions"
                        > & {
                            conditionErrors: Array<
                              Maybe<
                                { __typename?: "DocumentationRecordValidation" } & Pick<
                                  DocumentationRecordValidation,
                                  "validation" | "attribute"
                                >
                              >
                            >;
                          }
                      >
                    >;
                  };
                files: { __typename?: "MediaConnection" } & {
                  edges: Array<
                    { __typename?: "MediaEdge" } & {
                      node: { __typename?: "Media" } & Pick<
                        Media,
                        "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                      >;
                    }
                  >;
                };
              };
          }
      >;
    }
  >;
};

export type GroundControlPointDocumentationDeleteMutationVariables = Exact<{
  input: GroundControlPointDocumentationDeleteInput;
}>;

export type GroundControlPointDocumentationDeleteMutation = { __typename?: "Mutation" } & {
  groundControlPointDocumentation: Maybe<
    { __typename?: "GroundControlPointDocumentationOps" } & {
      delete: Maybe<
        { __typename?: "GroundControlPointDocumentationDeletePayload" } & Pick<
          GroundControlPointDocumentationDeletePayload,
          "clientMutationId"
        > & {
            groundControlPointDocumentation: { __typename?: "GroundControlPointDocumentation" } & Pick<
              GroundControlPointDocumentation,
              "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
            > & {
                documentationRecord: { __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id"> & {
                    triggers: Array<
                      Maybe<
                        { __typename?: "DocumentationRecordTrigger" } & Pick<
                          DocumentationRecordTrigger,
                          "trigger" | "available" | "conditions"
                        > & {
                            conditionErrors: Array<
                              Maybe<
                                { __typename?: "DocumentationRecordValidation" } & Pick<
                                  DocumentationRecordValidation,
                                  "validation" | "attribute"
                                >
                              >
                            >;
                          }
                      >
                    >;
                  };
                files: { __typename?: "MediaConnection" } & {
                  edges: Array<
                    { __typename?: "MediaEdge" } & {
                      node: { __typename?: "Media" } & Pick<
                        Media,
                        "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                      >;
                    }
                  >;
                };
              };
          }
      >;
    }
  >;
};

export type GroundControlPointDocumentationUpdateMutationVariables = Exact<{
  input: GroundControlPointDocumentationUpdateInput;
}>;

export type GroundControlPointDocumentationUpdateMutation = { __typename?: "Mutation" } & {
  groundControlPointDocumentation: Maybe<
    { __typename?: "GroundControlPointDocumentationOps" } & {
      update: Maybe<
        { __typename?: "GroundControlPointDocumentationUpdatePayload" } & Pick<
          GroundControlPointDocumentationUpdatePayload,
          "clientMutationId"
        > & {
            groundControlPointDocumentation: { __typename?: "GroundControlPointDocumentation" } & Pick<
              GroundControlPointDocumentation,
              "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
            > & {
                documentationRecord: { __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id"> & {
                    triggers: Array<
                      Maybe<
                        { __typename?: "DocumentationRecordTrigger" } & Pick<
                          DocumentationRecordTrigger,
                          "trigger" | "available" | "conditions"
                        > & {
                            conditionErrors: Array<
                              Maybe<
                                { __typename?: "DocumentationRecordValidation" } & Pick<
                                  DocumentationRecordValidation,
                                  "validation" | "attribute"
                                >
                              >
                            >;
                          }
                      >
                    >;
                  };
                files: { __typename?: "MediaConnection" } & {
                  edges: Array<
                    { __typename?: "MediaEdge" } & {
                      node: { __typename?: "Media" } & Pick<
                        Media,
                        "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                      >;
                    }
                  >;
                };
              };
          }
      >;
    }
  >;
};

export type InquiryResolveMutationVariables = Exact<{
  input: InquiryResolveInput;
}>;

export type InquiryResolveMutation = { __typename?: "Mutation" } & {
  inquiry: Maybe<
    { __typename?: "InquiryOps" } & {
      resolve: Maybe<
        { __typename?: "InquiryResolvePayload" } & { inquiry: { __typename?: "Inquiry" } & Pick<Inquiry, "id"> }
      >;
    }
  >;
};

export type InquirySendMutationVariables = Exact<{
  input: InquirySendInput;
}>;

export type InquirySendMutation = { __typename?: "Mutation" } & {
  inquiry: Maybe<
    { __typename?: "InquiryOps" } & {
      send: Maybe<{ __typename?: "InquirySendPayload" } & Pick<InquirySendPayload, "clientMutationId">>;
    }
  >;
};

export type LoginMutationVariables = Exact<{
  input: LoginAuthTokenInput;
}>;

export type LoginMutation = { __typename?: "Mutation" } & {
  login: Maybe<
    { __typename?: "LoginOps" } & {
      authToken: Maybe<
        { __typename?: "LoginAuthTokenPayload" } & Pick<LoginAuthTokenPayload, "token" | "refreshToken">
      >;
    }
  >;
};

export type LoginByCredentialsMutationVariables = Exact<{
  input: LoginCredentialsInput;
}>;

export type LoginByCredentialsMutation = { __typename?: "Mutation" } & {
  login: Maybe<
    { __typename?: "LoginOps" } & {
      credentials: Maybe<
        { __typename?: "LoginCredentialsPayload" } & Pick<LoginCredentialsPayload, "token" | "refreshToken">
      >;
    }
  >;
};

export type MediaCreateMutationVariables = Exact<{
  input: MediaCreateInput;
}>;

export type MediaCreateMutation = { __typename?: "Mutation" } & {
  media: Maybe<
    { __typename?: "MediaOps" } & {
      create: Maybe<
        { __typename?: "MediaCreatePayload" } & Pick<MediaCreatePayload, "clientMutationId"> & {
            media: { __typename?: "Media" } & Pick<
              Media,
              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
            >;
          }
      >;
    }
  >;
};

export type NotifyOutputExtensionsCompletedMutationVariables = Exact<{
  input: NotifyOutputExtensionsCompletedInput;
}>;

export type NotifyOutputExtensionsCompletedMutation = { __typename?: "Mutation" } & {
  outputExtension: Maybe<
    { __typename?: "OutputExtensionOps" } & {
      notifyOutputExtensionsCompleted: Maybe<
        { __typename?: "NotifyOutputExtensionsCompletedPayload" } & Pick<
          NotifyOutputExtensionsCompletedPayload,
          "clientMutationId" | "success"
        >
      >;
    }
  >;
};

export type OrganizationCancelInviteMutationVariables = Exact<{
  input: OrganizationCancelInviteInput;
}>;

export type OrganizationCancelInviteMutation = { __typename?: "Mutation" } & {
  organization: Maybe<
    { __typename?: "OrganizationOps" } & {
      cancelInvite: Maybe<
        { __typename?: "OrganizationCancelInvitePayload" } & Pick<
          OrganizationCancelInvitePayload,
          "clientMutationId"
        > & {
            invite: { __typename?: "OrganizationInvite" } & Pick<OrganizationInvite, "id"> & {
                organization: { __typename?: "Organization" } & Pick<
                  Organization,
                  | "id"
                  | "name"
                  | "activatedAt"
                  | "archivedAt"
                  | "createdAt"
                  | "credits"
                  | "crn"
                  | "notificationsEnabled"
                  | "nextStoragePaymentDate"
                > & {
                    organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                      edges: Array<
                        { __typename?: "OrganizationMembershipEdge" } & {
                          node: { __typename?: "OrganizationMembership" } & Pick<
                            OrganizationMembership,
                            "id" | "createdAt"
                          > & {
                              user: { __typename?: "User" } & Pick<
                                User,
                                | "externalState"
                                | "id"
                                | "role"
                                | "email"
                                | "firstName"
                                | "lastName"
                                | "phoneNumber"
                                | "language"
                              >;
                              organization: { __typename?: "Organization" } & Pick<
                                Organization,
                                "id" | "name" | "crn" | "activatedAt"
                              >;
                            };
                        }
                      >;
                    };
                    constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
                      ConstructionSiteConnection,
                      "totalCount"
                    >;
                    organizationInvites: { __typename?: "OrganizationInviteConnection" } & {
                      edges: Array<
                        { __typename?: "OrganizationInviteEdge" } & {
                          node: { __typename?: "OrganizationInvite" } & Pick<
                            OrganizationInvite,
                            "id" | "state" | "role" | "createdAt"
                          > & {
                              user: { __typename?: "User" } & Pick<
                                User,
                                | "externalState"
                                | "id"
                                | "role"
                                | "email"
                                | "firstName"
                                | "lastName"
                                | "phoneNumber"
                                | "language"
                              >;
                            };
                        }
                      >;
                    };
                    diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                        byKind: Array<
                          Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>
                        >;
                      };
                  };
                user: { __typename?: "User" } & Pick<
                  User,
                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                >;
              };
          }
      >;
    }
  >;
};

export type OrganizationInviteUserMutationVariables = Exact<{
  input: OrganizationInviteUserInput;
}>;

export type OrganizationInviteUserMutation = { __typename?: "Mutation" } & {
  organization: Maybe<
    { __typename?: "OrganizationOps" } & {
      inviteUser: Maybe<
        { __typename?: "OrganizationInviteUserPayload" } & Pick<OrganizationInviteUserPayload, "clientMutationId"> & {
            invite: { __typename?: "OrganizationInvite" } & {
              user: { __typename?: "User" } & Pick<
                User,
                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
              >;
              organization: { __typename?: "Organization" } & Pick<
                Organization,
                | "id"
                | "name"
                | "activatedAt"
                | "archivedAt"
                | "createdAt"
                | "credits"
                | "crn"
                | "notificationsEnabled"
                | "nextStoragePaymentDate"
              > & {
                  organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                    edges: Array<
                      { __typename?: "OrganizationMembershipEdge" } & {
                        node: { __typename?: "OrganizationMembership" } & Pick<
                          OrganizationMembership,
                          "id" | "createdAt"
                        > & {
                            user: { __typename?: "User" } & Pick<
                              User,
                              | "externalState"
                              | "id"
                              | "role"
                              | "email"
                              | "firstName"
                              | "lastName"
                              | "phoneNumber"
                              | "language"
                            >;
                            organization: { __typename?: "Organization" } & Pick<
                              Organization,
                              "id" | "name" | "crn" | "activatedAt"
                            >;
                          };
                      }
                    >;
                  };
                  constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
                    ConstructionSiteConnection,
                    "totalCount"
                  >;
                  organizationInvites: { __typename?: "OrganizationInviteConnection" } & {
                    edges: Array<
                      { __typename?: "OrganizationInviteEdge" } & {
                        node: { __typename?: "OrganizationInvite" } & Pick<
                          OrganizationInvite,
                          "id" | "state" | "role" | "createdAt"
                        > & {
                            user: { __typename?: "User" } & Pick<
                              User,
                              | "externalState"
                              | "id"
                              | "role"
                              | "email"
                              | "firstName"
                              | "lastName"
                              | "phoneNumber"
                              | "language"
                            >;
                          };
                      }
                    >;
                  };
                  diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                      byKind: Array<
                        Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>
                      >;
                    };
                };
            };
          }
      >;
    }
  >;
};

export type OrganizationLeaveMutationVariables = Exact<{
  input: OrganizationLeaveInput;
}>;

export type OrganizationLeaveMutation = { __typename?: "Mutation" } & {
  organization: Maybe<
    { __typename?: "OrganizationOps" } & {
      leave: Maybe<
        { __typename?: "OrganizationLeavePayload" } & Pick<OrganizationLeavePayload, "clientMutationId"> & {
            user: { __typename?: "User" } & Pick<
              User,
              "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
            >;
          }
      >;
    }
  >;
};

export type OrganizationLogEventMutationVariables = Exact<{
  input: OrganizationLogEventInput;
}>;

export type OrganizationLogEventMutation = { __typename?: "Mutation" } & {
  organization: Maybe<
    { __typename?: "OrganizationOps" } & {
      logEvent: Maybe<
        { __typename?: "OrganizationLogEventPayload" } & Pick<
          OrganizationLogEventPayload,
          "clientMutationId" | "success"
        >
      >;
    }
  >;
};

export type OrganizationRemoveUserMutationVariables = Exact<{
  input: OrganizationRemoveUserInput;
}>;

export type OrganizationRemoveUserMutation = { __typename?: "Mutation" } & {
  organization: Maybe<
    { __typename?: "OrganizationOps" } & {
      removeUser: Maybe<
        { __typename?: "OrganizationRemoveUserPayload" } & Pick<OrganizationRemoveUserPayload, "clientMutationId"> & {
            organization: { __typename?: "Organization" } & Pick<
              Organization,
              | "id"
              | "name"
              | "activatedAt"
              | "archivedAt"
              | "createdAt"
              | "credits"
              | "crn"
              | "notificationsEnabled"
              | "nextStoragePaymentDate"
            > & {
                organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                  edges: Array<
                    { __typename?: "OrganizationMembershipEdge" } & {
                      node: { __typename?: "OrganizationMembership" } & Pick<
                        OrganizationMembership,
                        "id" | "createdAt"
                      > & {
                          user: { __typename?: "User" } & Pick<
                            User,
                            | "externalState"
                            | "id"
                            | "role"
                            | "email"
                            | "firstName"
                            | "lastName"
                            | "phoneNumber"
                            | "language"
                          >;
                          organization: { __typename?: "Organization" } & Pick<
                            Organization,
                            "id" | "name" | "crn" | "activatedAt"
                          >;
                        };
                    }
                  >;
                };
                constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
                  ConstructionSiteConnection,
                  "totalCount"
                >;
                organizationInvites: { __typename?: "OrganizationInviteConnection" } & {
                  edges: Array<
                    { __typename?: "OrganizationInviteEdge" } & {
                      node: { __typename?: "OrganizationInvite" } & Pick<
                        OrganizationInvite,
                        "id" | "state" | "role" | "createdAt"
                      > & {
                          user: { __typename?: "User" } & Pick<
                            User,
                            | "externalState"
                            | "id"
                            | "role"
                            | "email"
                            | "firstName"
                            | "lastName"
                            | "phoneNumber"
                            | "language"
                          >;
                        };
                    }
                  >;
                };
                diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                    byKind: Array<
                      Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>
                    >;
                  };
              };
          }
      >;
    }
  >;
};

export type OrganizationResolveInviteMutationVariables = Exact<{
  input: OrganizationResolveInviteInput;
}>;

export type OrganizationResolveInviteMutation = { __typename?: "Mutation" } & {
  organization: Maybe<
    { __typename?: "OrganizationOps" } & {
      resolveInvite: Maybe<
        { __typename?: "OrganizationResolveInvitePayload" } & Pick<
          OrganizationResolveInvitePayload,
          "clientMutationId"
        > & {
            invite: { __typename?: "OrganizationInvite" } & Pick<OrganizationInvite, "id"> & {
                organization: { __typename?: "Organization" } & Pick<
                  Organization,
                  | "id"
                  | "name"
                  | "activatedAt"
                  | "archivedAt"
                  | "createdAt"
                  | "credits"
                  | "crn"
                  | "notificationsEnabled"
                  | "nextStoragePaymentDate"
                > & {
                    organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                      edges: Array<
                        { __typename?: "OrganizationMembershipEdge" } & {
                          node: { __typename?: "OrganizationMembership" } & Pick<
                            OrganizationMembership,
                            "id" | "createdAt"
                          > & {
                              user: { __typename?: "User" } & Pick<
                                User,
                                | "externalState"
                                | "id"
                                | "role"
                                | "email"
                                | "firstName"
                                | "lastName"
                                | "phoneNumber"
                                | "language"
                              >;
                              organization: { __typename?: "Organization" } & Pick<
                                Organization,
                                "id" | "name" | "crn" | "activatedAt"
                              >;
                            };
                        }
                      >;
                    };
                    constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
                      ConstructionSiteConnection,
                      "totalCount"
                    >;
                    organizationInvites: { __typename?: "OrganizationInviteConnection" } & {
                      edges: Array<
                        { __typename?: "OrganizationInviteEdge" } & {
                          node: { __typename?: "OrganizationInvite" } & Pick<
                            OrganizationInvite,
                            "id" | "state" | "role" | "createdAt"
                          > & {
                              user: { __typename?: "User" } & Pick<
                                User,
                                | "externalState"
                                | "id"
                                | "role"
                                | "email"
                                | "firstName"
                                | "lastName"
                                | "phoneNumber"
                                | "language"
                              >;
                            };
                        }
                      >;
                    };
                    diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                        byKind: Array<
                          Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>
                        >;
                      };
                  };
                user: { __typename?: "User" } & Pick<
                  User,
                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                > & {
                    organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                      edges: Array<
                        { __typename?: "OrganizationMembershipEdge" } & {
                          node: { __typename?: "OrganizationMembership" } & {
                            organization: { __typename?: "Organization" } & Pick<
                              Organization,
                              "id" | "crn" | "name" | "credits" | "createdAt" | "archivedAt" | "activatedAt"
                            > & {
                                organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                                  edges: Array<
                                    { __typename?: "OrganizationMembershipEdge" } & {
                                      node: { __typename?: "OrganizationMembership" } & Pick<
                                        OrganizationMembership,
                                        "id"
                                      > & {
                                          user: { __typename?: "User" } & Pick<
                                            User,
                                            | "id"
                                            | "role"
                                            | "email"
                                            | "firstName"
                                            | "lastName"
                                            | "phoneNumber"
                                            | "language"
                                          >;
                                        };
                                    }
                                  >;
                                };
                              };
                          };
                        }
                      >;
                    };
                    receivedInvitations: { __typename?: "OrganizationInviteConnection" } & {
                      edges: Array<
                        { __typename?: "OrganizationInviteEdge" } & {
                          node: { __typename?: "OrganizationInvite" } & Pick<
                            OrganizationInvite,
                            "id" | "state" | "role" | "createdAt" | "expiresAt"
                          > & {
                              organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
                              user: { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >;
                              invitedBy: { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >;
                            };
                        }
                      >;
                    };
                  };
              };
          }
      >;
    }
  >;
};

export type OutputExtensionDeleteMutationVariables = Exact<{
  input: OutputExtensionDeleteInput;
}>;

export type OutputExtensionDeleteMutation = { __typename?: "Mutation" } & {
  outputExtension: Maybe<
    { __typename?: "OutputExtensionOps" } & {
      delete: Maybe<
        { __typename?: "OutputExtensionDeletePayload" } & Pick<
          OutputExtensionDeletePayload,
          "clientMutationId" | "success"
        >
      >;
    }
  >;
};

export type OutputExtensionsUpdateMutationVariables = Exact<{
  input: OutputExtensionUpdateInput;
}>;

export type OutputExtensionsUpdateMutation = { __typename?: "Mutation" } & {
  outputExtension: Maybe<
    { __typename?: "OutputExtensionOps" } & {
      update: Maybe<
        { __typename?: "OutputExtensionUpdatePayload" } & Pick<OutputExtensionUpdatePayload, "clientMutationId"> & {
            outputExtension: { __typename?: "OutputExtension" } & Pick<
              OutputExtension,
              "id" | "comment" | "createdAt"
            > & {
                files: { __typename?: "MediaConnection" } & {
                  edges: Array<
                    { __typename?: "MediaEdge" } & {
                      node: { __typename?: "Media" } & Pick<
                        Media,
                        "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                      >;
                    }
                  >;
                };
              };
          }
      >;
    }
  >;
};

export type PurchaseMutationVariables = Exact<{
  input: PurchaseClaimInput;
}>;

export type PurchaseMutation = { __typename?: "Mutation" } & {
  purchase: Maybe<
    { __typename?: "PurchaseOps" } & {
      claim: Maybe<
        { __typename?: "PurchaseClaimPayload" } & {
          purchase: { __typename?: "Purchase" } & Pick<
            Purchase,
            "id" | "type" | "createdAt" | "claimedAt" | "expiresAt" | "externalId" | "credits"
          > & {
              user: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                > & {
                    purchases: { __typename?: "PurchaseConnection" } & {
                      edges: Array<
                        { __typename?: "PurchaseEdge" } & {
                          node: { __typename?: "Purchase" } & Pick<
                            Purchase,
                            "id" | "type" | "createdAt" | "claimedAt" | "expiresAt" | "externalId" | "credits"
                          > & {
                              organization: Maybe<
                                { __typename?: "Organization" } & Pick<
                                  Organization,
                                  "id" | "name" | "crn" | "activatedAt"
                                > & {
                                    diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<
                                      DiskUsageStats,
                                      "total"
                                    > & {
                                        byKind: Array<
                                          Maybe<
                                            { __typename?: "DiskUsageKindStats" } & Pick<
                                              DiskUsageKindStats,
                                              "kind" | "total"
                                            >
                                          >
                                        >;
                                      };
                                  }
                              >;
                            };
                        }
                      >;
                    };
                  }
              >;
              organization: Maybe<
                { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "crn" | "activatedAt"> & {
                    diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                        byKind: Array<
                          Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>
                        >;
                      };
                  }
              >;
            };
        }
      >;
    }
  >;
};

export type RefreshPayloadMutationVariables = Exact<{
  input: RefreshInput;
}>;

export type RefreshPayloadMutation = { __typename?: "Mutation" } & {
  refreshToken: Maybe<{ __typename?: "RefreshPayload" } & Pick<RefreshPayload, "payload" | "refreshToken" | "token">>;
};

export type RequestThreeDModelDownloadLinkMutationVariables = Exact<{
  input: RequestDownloadLinkInput;
}>;

export type RequestThreeDModelDownloadLinkMutation = { __typename?: "Mutation" } & {
  threeDModel: Maybe<
    { __typename?: "ThreeDModelOps" } & {
      requestDownloadLink: Maybe<
        { __typename?: "RequestDownloadLinkPayload" } & Pick<RequestDownloadLinkPayload, "clientMutationId" | "url">
      >;
    }
  >;
};

export type SendInquiryMutationVariables = Exact<{
  input: InquirySendInput;
}>;

export type SendInquiryMutation = { __typename?: "Mutation" } & {
  inquiry: Maybe<
    { __typename?: "InquiryOps" } & {
      send: Maybe<
        { __typename?: "InquirySendPayload" } & { inquiry: { __typename?: "Inquiry" } & Pick<Inquiry, "id"> }
      >;
    }
  >;
};

export type SetLanguageMutationVariables = Exact<{
  input: SetLanguageInput;
}>;

export type SetLanguageMutation = { __typename?: "Mutation" } & {
  organization: Maybe<
    { __typename?: "OrganizationOps" } & {
      setLanguage: Maybe<
        { __typename?: "SetLanguagePayload" } & {
          user: { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >;
        }
      >;
    }
  >;
};

export type SetSimplifiedGroundControlPointMutationVariables = Exact<{
  input: GroundControlPointDocumentationSetSimplifiedInput;
}>;

export type SetSimplifiedGroundControlPointMutation = { __typename?: "Mutation" } & {
  groundControlPointDocumentation: Maybe<
    { __typename?: "GroundControlPointDocumentationOps" } & {
      setSimplified: Maybe<
        { __typename?: "GroundControlPointDocumentationSetSimplifiedPayload" } & Pick<
          GroundControlPointDocumentationSetSimplifiedPayload,
          "clientMutationId"
        > & {
            documentationRecord: { __typename?: "DocumentationRecord" } & Pick<
              DocumentationRecord,
              "id" | "simpleGroundControlPointCount"
            > & {
                triggers: Array<
                  Maybe<
                    { __typename?: "DocumentationRecordTrigger" } & Pick<
                      DocumentationRecordTrigger,
                      "trigger" | "available" | "conditions"
                    > & {
                        conditionErrors: Array<
                          Maybe<
                            { __typename?: "DocumentationRecordValidation" } & Pick<
                              DocumentationRecordValidation,
                              "validation" | "attribute"
                            >
                          >
                        >;
                      }
                  >
                >;
                simpleGroundControlPoint: Maybe<
                  { __typename?: "GroundControlPointDocumentation" } & Pick<
                    GroundControlPointDocumentation,
                    "id" | "description"
                  > & {
                      files: { __typename?: "MediaConnection" } & {
                        edges: Array<
                          { __typename?: "MediaEdge" } & {
                            node: { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >;
                          }
                        >;
                      };
                    }
                >;
              };
          }
      >;
    }
  >;
};

export type ThreeDModelDeleteMutationVariables = Exact<{
  input: ThreeDModelDeleteInput;
}>;

export type ThreeDModelDeleteMutation = { __typename?: "Mutation" } & {
  threeDModel: Maybe<
    { __typename?: "ThreeDModelOps" } & {
      delete: Maybe<
        { __typename?: "ThreeDModelDeletePayload" } & Pick<ThreeDModelDeletePayload, "clientMutationId"> & {
            threeDModel: { __typename?: "ThreeDModel" } & Pick<
              ThreeDModel,
              "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
            > & {
                modelFile: Maybe<
                  { __typename?: "Media" } & Pick<
                    Media,
                    "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                  >
                >;
                textureFile: Maybe<
                  { __typename?: "Media" } & Pick<
                    Media,
                    "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                  >
                >;
                coordinatesFile: Maybe<
                  { __typename?: "Media" } & Pick<
                    Media,
                    "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                  >
                >;
                files: Maybe<
                  Array<Maybe<{ __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">>>
                >;
              };
          }
      >;
    }
  >;
};

export type ThreeDModelRevokeSharesMutationVariables = Exact<{
  input: ThreeDModelRevokeSharesInput;
}>;

export type ThreeDModelRevokeSharesMutation = { __typename?: "Mutation" } & {
  threeDModel: Maybe<
    { __typename?: "ThreeDModelOps" } & {
      revokeShares: Maybe<
        { __typename?: "ThreeDModelRevokeSharesPayload" } & Pick<ThreeDModelRevokeSharesPayload, "clientMutationId"> & {
            threeDModel: { __typename?: "ThreeDModel" } & Pick<
              ThreeDModel,
              "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
            > & {
                modelFile: Maybe<
                  { __typename?: "Media" } & Pick<
                    Media,
                    "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                  >
                >;
                textureFile: Maybe<
                  { __typename?: "Media" } & Pick<
                    Media,
                    "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                  >
                >;
                coordinatesFile: Maybe<
                  { __typename?: "Media" } & Pick<
                    Media,
                    "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                  >
                >;
                files: Maybe<
                  Array<Maybe<{ __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">>>
                >;
              };
          }
      >;
    }
  >;
};

export type ThreeDModelSendShareMutationVariables = Exact<{
  input: ThreeDModelSendShareInput;
}>;

export type ThreeDModelSendShareMutation = { __typename?: "Mutation" } & {
  threeDModel: Maybe<
    { __typename?: "ThreeDModelOps" } & {
      sendShare: Maybe<
        { __typename?: "ThreeDModelSendSharePayload" } & Pick<ThreeDModelSendSharePayload, "clientMutationId" | "sent">
      >;
    }
  >;
};

export type ThreeDModelShareMutationVariables = Exact<{
  input: ThreeDModelShareInput;
}>;

export type ThreeDModelShareMutation = { __typename?: "Mutation" } & {
  threeDModel: Maybe<
    { __typename?: "ThreeDModelOps" } & {
      share: Maybe<
        { __typename?: "ThreeDModelSharePayload" } & Pick<ThreeDModelSharePayload, "clientMutationId" | "code">
      >;
    }
  >;
};

export type AllTasksForThreeDOperatorQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  threeDOperatorName?: InputMaybe<Scalars["String"]["input"]>;
  states?: InputMaybe<
    Array<InputMaybe<DocumentationRecordStateFilterEnum>> | InputMaybe<DocumentationRecordStateFilterEnum>
  >;
  types?: InputMaybe<
    Array<InputMaybe<DocumentationRecordTypeFilterEnum>> | InputMaybe<DocumentationRecordTypeFilterEnum>
  >;
  videoTypes?: InputMaybe<
    Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>> | InputMaybe<DocumentationRecordVideoTypeFilterEnum>
  >;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>> | InputMaybe<DocumentationRecordOrderByEnum>>;
}>;

export type AllTasksForThreeDOperatorQuery = { __typename?: "Query" } & {
  me: Maybe<
    { __typename?: "User" } & Pick<User, "id"> & {
        tasks: { __typename?: "DocumentationRecordConnection" } & Pick<DocumentationRecordConnection, "totalCount"> & {
            edges: Array<
              { __typename?: "DocumentationRecordEdge" } & {
                node: { __typename?: "DocumentationRecord" } & Pick<
                  DocumentationRecord,
                  "id" | "name" | "createdAt" | "state" | "type"
                > & {
                    constructionObject: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id"> & {
                        constructionSite: { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id"> & {
                            organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
                          };
                      };
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<DocumentationRecordVideo, "id" | "type">
                    >;
                    accountableThreeDOperator: Maybe<
                      { __typename?: "User" } & Pick<User, "id" | "firstName" | "lastName">
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<ThreeDModel, "id"> & {
                          modelFile: Maybe<{ __typename?: "Media" } & Pick<Media, "id" | "url">>;
                        }
                    >;
                  };
              }
            >;
          };
      }
  >;
};

export type CatalogQueryVariables = Exact<{ [key: string]: never }>;

export type CatalogQuery = { __typename?: "Query" } & {
  catalog: Maybe<
    { __typename?: "Catalog" } & Pick<
      Catalog,
      | "id"
      | "createDocumentationRecord"
      | "requestThreeDModelNoScale"
      | "requestThreeDModelScale"
      | "requestThreeDModelMaps"
      | "requestThreeDModelSpray"
      | "requestThreeDModelQrCodes"
      | "downloadThreeDModel"
      | "inviteManager"
      | "storageLimits"
      | "storagePrices"
    > & {
        storageClassPrices: Maybe<
          Array<Maybe<{ __typename?: "StorageClass" } & Pick<StorageClass, "maxGb" | "price">>>
        >;
        requestModelPrices: Maybe<
          { __typename?: "ModelPrices" } & Pick<ModelPrices, "noScale" | "scale" | "maps" | "spray" | "qrCodes">
        >;
      }
  >;
};

export type ConfigQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigQuery = { __typename?: "Query" } & {
  config: Maybe<
    { __typename?: "Config" } & Pick<
      Config,
      | "id"
      | "minimalGroundControlPoints"
      | "threeDModelDeleteAfterDays"
      | "documentationRecordDeleteAfterDays"
      | "organizationInviteSentValidDays"
      | "purchaseExpirationMonths"
      | "helpdeskUrl"
      | "externalAccountUrl"
      | "externalBaseName"
      | "externalBaseUrl"
      | "contactUrl"
      | "logoutRedirectUrl"
      | "providerName"
      | "providerUrl"
      | "providerCrn"
      | "transactionMessagesBccEmail"
      | "allowedGeodeticSurveyFileTypes"
      | "allowedImageFileTypes"
      | "allowedVideoFileTypes"
      | "allowedOtherFileTypes"
      | "shopUrlCs"
      | "shopUrlEn"
      | "shopUrlUk"
      | "shopUrlDe"
      | "defaultConstructionPhasesCs"
      | "defaultConstructionPhasesEn"
      | "defaultConstructionPhasesUk"
      | "defaultConstructionPhasesDe"
      | "documentationRecordReturnReasonsCs"
      | "documentationRecordReturnReasonsEn"
      | "documentationRecordReturnReasonsUk"
      | "documentationRecordReturnReasonsDe"
      | "documentationRecordRejectReasonsCs"
      | "documentationRecordRejectReasonsEn"
      | "documentationRecordRejectReasonsUk"
      | "documentationRecordRejectReasonsDe"
      | "storagePaymentIntervalMonths"
      | "transactionEmailsEnabled"
      | "oauthUserinfoUrl"
      | "fileUploadUrl"
      | "threeDViewerUrl"
      | "emailContactCs"
      | "emailContactEn"
      | "emailContactUk"
      | "emailContactDe"
      | "phoneContactCs"
      | "phoneContactEn"
      | "phoneContactUk"
      | "phoneContactDe"
    >
  >;
};

export type ConstructionObjectCreatePageQueryVariables = Exact<{
  constructionObjectId: Scalars["ID"]["input"];
}>;

export type ConstructionObjectCreatePageQuery = { __typename?: "Query" } & {
  constructionObject: Maybe<
    { __typename?: "ConstructionObject" } & Pick<
      ConstructionObject,
      | "id"
      | "name"
      | "state"
      | "location"
      | "createdAt"
      | "archivedAt"
      | "description"
      | "createdRecords"
      | "workInProgressRecords"
      | "completedRecords"
    > & {
        accountableManager: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableOperator: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableSurveyor: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        constructionSite: { __typename?: "ConstructionSite" } & Pick<
          ConstructionSite,
          "id" | "name" | "description" | "location"
        > & { organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name"> };
      }
  >;
};

export type ConstructionObjectDetailPageQueryVariables = Exact<{
  constructionObjectId: Scalars["ID"]["input"];
}>;

export type ConstructionObjectDetailPageQuery = { __typename?: "Query" } & {
  constructionObject: Maybe<
    { __typename?: "ConstructionObject" } & Pick<
      ConstructionObject,
      "id" | "name" | "state" | "location" | "createdAt" | "archivedAt" | "description"
    > & {
        accountableManager: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableOperator: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableSurveyor: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        constructionSite: { __typename?: "ConstructionSite" } & Pick<
          ConstructionSite,
          "id" | "name" | "description" | "location"
        >;
        firstVersion: { __typename?: "VersionConnection" } & {
          edges: Array<
            { __typename?: "VersionEdge" } & {
              node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                  user: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                };
            }
          >;
        };
        currentVersion: { __typename?: "VersionConnection" } & {
          edges: Array<
            { __typename?: "VersionEdge" } & {
              node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                  user: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                };
            }
          >;
        };
      }
  >;
};

export type ConstructionObjectsAndPhasesForSummaryTableQueryVariables = Exact<{
  constructionSiteId: Scalars["ID"]["input"];
}>;

export type ConstructionObjectsAndPhasesForSummaryTableQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id"> & {
        constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
          ConstructionObjectConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "ConstructionObjectEdge" } & {
                node: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name">;
              }
            >;
          };
        constructionPhases: { __typename?: "ConstructionPhaseConnection" } & Pick<
          ConstructionPhaseConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "ConstructionPhaseEdge" } & {
                node: { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id" | "name" | "order">;
              }
            >;
          };
      }
  >;
};

export type ConstructionObjectsForSelectQueryVariables = Exact<{
  constructionSiteId: Scalars["ID"]["input"];
  first: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  accountableManagerId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ConstructionObjectsForSelectQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id"> & {
        constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
          ConstructionObjectConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "ConstructionObjectEdge" } & {
                node: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name">;
              }
            >;
          };
      }
  >;
};

export type ConstructionObjectsTableQueryVariables = Exact<{
  constructionSiteId: Scalars["ID"]["input"];
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  states?: InputMaybe<
    Array<InputMaybe<ConstructionObjectStateFilterEnum>> | InputMaybe<ConstructionObjectStateFilterEnum>
  >;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionObjectOrderByEnum>> | InputMaybe<ConstructionObjectOrderByEnum>>;
}>;

export type ConstructionObjectsTableQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id"> & {
        constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
          ConstructionObjectConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "ConstructionObjectEdge" } & {
                node: { __typename?: "ConstructionObject" } & Pick<
                  ConstructionObject,
                  "id" | "name" | "state" | "createdRecords" | "workInProgressRecords" | "completedRecords"
                >;
              }
            >;
          };
      }
  >;
};

export type ConstructionPhaseQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ConstructionPhaseQuery = { __typename?: "Query" } & {
  constructionPhase: Maybe<
    { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id" | "name" | "createdAt"> & {
        constructionSite: { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">;
      }
  >;
};

export type ConstructionPhasesForSelectQueryVariables = Exact<{
  constructionSiteId: Scalars["ID"]["input"];
  first: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ConstructionPhasesForSelectQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id"> & {
        constructionPhases: { __typename?: "ConstructionPhaseConnection" } & Pick<
          ConstructionPhaseConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "ConstructionPhaseEdge" } & {
                node: { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id" | "name">;
              }
            >;
          };
      }
  >;
};

export type ConstructionPhasesTableQueryVariables = Exact<{
  constructionSiteId: Scalars["ID"]["input"];
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionPhaseOrderByEnum>> | InputMaybe<ConstructionPhaseOrderByEnum>>;
}>;

export type ConstructionPhasesTableQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id"> & {
        constructionPhases: { __typename?: "ConstructionPhaseConnection" } & Pick<
          ConstructionPhaseConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "ConstructionPhaseEdge" } & {
                node: { __typename?: "ConstructionPhase" } & Pick<
                  ConstructionPhase,
                  "id" | "name" | "order" | "createdRecords" | "workInProgressRecords" | "completedRecords"
                >;
              }
            >;
          };
      }
  >;
};

export type ConstructionSiteCreateEditPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ConstructionSiteCreateEditPageQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<
      ConstructionSite,
      | "id"
      | "name"
      | "description"
      | "state"
      | "autoRequestThreeD"
      | "location"
      | "createdRecords"
      | "workInProgressRecords"
      | "completedRecords"
    > & {
        organization: { __typename?: "Organization" } & Pick<Organization, "id">;
        accountableManager: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableOperator: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableSurveyor: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
          ConstructionObjectConnection,
          "totalCount"
        >;
        constructionPhases: { __typename?: "ConstructionPhaseConnection" } & Pick<
          ConstructionPhaseConnection,
          "totalCount"
        >;
        firstVersion: { __typename?: "VersionConnection" } & {
          edges: Array<
            { __typename?: "VersionEdge" } & {
              node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                  user: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                };
            }
          >;
        };
        currentVersion: { __typename?: "VersionConnection" } & {
          edges: Array<
            { __typename?: "VersionEdge" } & {
              node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                  user: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                };
            }
          >;
        };
      }
  >;
};

export type ConstructionSiteDetailPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ConstructionSiteDetailPageQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<
      ConstructionSite,
      "id" | "name" | "description" | "state" | "autoRequestThreeD" | "location"
    > & {
        organization: { __typename?: "Organization" } & Pick<Organization, "id">;
        accountableManager: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableOperator: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableSurveyor: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        firstVersion: { __typename?: "VersionConnection" } & {
          edges: Array<
            { __typename?: "VersionEdge" } & {
              node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                  user: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                };
            }
          >;
        };
        currentVersion: { __typename?: "VersionConnection" } & {
          edges: Array<
            { __typename?: "VersionEdge" } & {
              node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                  user: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                };
            }
          >;
        };
      }
  >;
};

export type ConstructionSiteForConstructionObjectCreatePageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ConstructionSiteForConstructionObjectCreatePageQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name" | "location"> & {
        organization: { __typename?: "Organization" } & Pick<Organization, "id">;
        accountableManager: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableOperator: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableSurveyor: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
      }
  >;
};

export type ConstructionSiteForDocumentationRecordCreateSimplifiedPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name" | "accountableManagerId">
  >;
};

export type ConstructionSiteNameQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ConstructionSiteNameQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<{ __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">>;
};

export type ConstructionSitesQueryVariables = Exact<{
  organizationId: Scalars["ID"]["input"];
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  accountableManagerName?: InputMaybe<Scalars["String"]["input"]>;
  states?: InputMaybe<Array<InputMaybe<ConstructionSiteStateFilterEnum>> | InputMaybe<ConstructionSiteStateFilterEnum>>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionSiteOrderByEnum>> | InputMaybe<ConstructionSiteOrderByEnum>>;
}>;

export type ConstructionSitesQuery = { __typename?: "Query" } & {
  organization: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id"> & {
        constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
          ConstructionSiteConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "ConstructionSiteEdge" } & {
                node: { __typename?: "ConstructionSite" } & Pick<
                  ConstructionSite,
                  | "id"
                  | "name"
                  | "state"
                  | "createdAt"
                  | "archivedAt"
                  | "createdRecords"
                  | "workInProgressRecords"
                  | "completedRecords"
                > & {
                    accountableManager: Maybe<{ __typename?: "User" } & Pick<User, "id" | "firstName" | "lastName">>;
                    constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
                      ConstructionObjectConnection,
                      "totalCount"
                    >;
                    constructionPhases: { __typename?: "ConstructionPhaseConnection" } & Pick<
                      ConstructionPhaseConnection,
                      "totalCount"
                    >;
                  };
              }
            >;
          };
      }
  >;
};

export type ConstructionSitesForSelectQueryVariables = Exact<{
  organizationId: Scalars["ID"]["input"];
  first: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  accountableManagerId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ConstructionSitesForSelectQuery = { __typename?: "Query" } & {
  organization: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id"> & {
        constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
          ConstructionSiteConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "ConstructionSiteEdge" } & {
                node: { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">;
              }
            >;
          };
      }
  >;
};

export type DocumentationRecordQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DocumentationRecordQuery = { __typename?: "Query" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecord" } & Pick<
      DocumentationRecord,
      "id" | "createdAt" | "archivedAt" | "name" | "type" | "state" | "description" | "simpleGroundControlPointCount"
    > & {
        constructionObject: { __typename?: "ConstructionObject" } & Pick<
          ConstructionObject,
          "id" | "name" | "location" | "description"
        > & {
            constructionSite: { __typename?: "ConstructionSite" } & Pick<
              ConstructionSite,
              "id" | "name" | "location" | "description" | "autoRequestThreeD"
            > & { organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "credits"> };
          };
        constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
          ConstructionPhase,
          | "id"
          | "name"
          | "order"
          | "createdAt"
          | "archivedAt"
          | "createdRecords"
          | "workInProgressRecords"
          | "completedRecords"
        >;
        threeDModel: Maybe<
          { __typename?: "ThreeDModel" } & Pick<
            ThreeDModel,
            "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
          > & {
              modelFile: Maybe<
                { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >
              >;
              textureFile: Maybe<
                { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >
              >;
              coordinatesFile: Maybe<
                { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >
              >;
              files: Maybe<
                Array<Maybe<{ __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">>>
              >;
            }
        >;
        accountableManager: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableOperator: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableSurveyor: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableThreeDOperator: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        simpleGroundControlPoint: Maybe<
          { __typename?: "GroundControlPointDocumentation" } & Pick<
            GroundControlPointDocumentation,
            "id" | "description"
          > & {
              files: { __typename?: "MediaConnection" } & {
                edges: Array<
                  { __typename?: "MediaEdge" } & {
                    node: { __typename?: "Media" } & Pick<
                      Media,
                      "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                    >;
                  }
                >;
              };
            }
        >;
        groundControlPointDocumentations: { __typename?: "GroundControlPointDocumentationConnection" } & {
          edges: Array<
            { __typename?: "GroundControlPointDocumentationEdge" } & {
              node: { __typename?: "GroundControlPointDocumentation" } & Pick<
                GroundControlPointDocumentation,
                "id" | "order" | "createdAt" | "archivedAt" | "location" | "description"
              > & {
                  files: { __typename?: "MediaConnection" } & {
                    edges: Array<
                      { __typename?: "MediaEdge" } & {
                        node: { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >;
                      }
                    >;
                  };
                };
            }
          >;
        };
        documentationRecordVideo: Maybe<
          { __typename?: "DocumentationRecordVideo" } & Pick<
            DocumentationRecordVideo,
            "id" | "type" | "captureLocation"
          > & {
              video: Maybe<
                { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >
              >;
            }
        >;
        geodeticSurvey: Maybe<
          { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
              txtSurvey: Maybe<
                { __typename?: "Media" } & Pick<
                  Media,
                  "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                >
              >;
              files: { __typename?: "MediaConnection" } & {
                edges: Array<
                  { __typename?: "MediaEdge" } & {
                    node: { __typename?: "Media" } & Pick<
                      Media,
                      "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                    >;
                  }
                >;
              };
            }
        >;
        constructionElements: { __typename?: "ConstructionElementConnection" } & {
          edges: Array<
            { __typename?: "ConstructionElementEdge" } & {
              node: { __typename?: "ConstructionElement" } & Pick<
                ConstructionElement,
                "id" | "createdAt" | "description" | "archivedAt"
              > & {
                  photo: { __typename?: "Media" } & Pick<
                    Media,
                    "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                  >;
                };
            }
          >;
        };
        triggers: Array<
          Maybe<
            { __typename?: "DocumentationRecordTrigger" } & Pick<
              DocumentationRecordTrigger,
              "trigger" | "available" | "conditions"
            > & {
                conditionErrors: Array<
                  Maybe<
                    { __typename?: "DocumentationRecordValidation" } & Pick<
                      DocumentationRecordValidation,
                      "validation" | "attribute"
                    >
                  >
                >;
              }
          >
        >;
        stateChanges: { __typename?: "DocumentationRecordStateChangeConnection" } & {
          edges: Array<
            { __typename?: "DocumentationRecordStateChangeEdge" } & {
              node: { __typename?: "DocumentationRecordStateChange" } & Pick<
                DocumentationRecordStateChange,
                | "id"
                | "comment"
                | "createdAt"
                | "fromState"
                | "rejectionReason"
                | "returnReason"
                | "toState"
                | "trigger"
              > & {
                  user: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                };
            }
          >;
        };
        lastStateChange: Maybe<
          { __typename?: "DocumentationRecordStateChange" } & Pick<
            DocumentationRecordStateChange,
            "id" | "comment" | "createdAt" | "fromState" | "rejectionReason" | "returnReason" | "toState" | "trigger"
          > & {
              user: Maybe<
                { __typename?: "User" } & Pick<
                  User,
                  "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                >
              >;
            }
        >;
        outputExtensions: { __typename?: "OutputExtensionConnection" } & Pick<OutputExtensionConnection, "totalCount">;
        firstVersion: { __typename?: "VersionConnection" } & {
          edges: Array<
            { __typename?: "VersionEdge" } & {
              node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                  user: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                };
            }
          >;
        };
        currentVersion: { __typename?: "VersionConnection" } & {
          edges: Array<
            { __typename?: "VersionEdge" } & {
              node: { __typename?: "Version" } & Pick<Version, "id" | "createdAt"> & {
                  user: Maybe<
                    { __typename?: "User" } & Pick<
                      User,
                      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                    >
                  >;
                };
            }
          >;
        };
      }
  >;
};

export type DocumentationRecordCreatePageQueryVariables = Exact<{
  constructionSiteId: Scalars["ID"]["input"];
  constructionObjectId: Scalars["ID"]["input"];
}>;

export type DocumentationRecordCreatePageQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<
    { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name" | "autoRequestThreeD"> & {
        constructionPhases: { __typename?: "ConstructionPhaseConnection" } & {
          edges: Array<
            { __typename?: "ConstructionPhaseEdge" } & {
              node: { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id">;
            }
          >;
        };
        organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
      }
  >;
  constructionObject: Maybe<
    { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name" | "suggestedDocumentationName"> & {
        accountableManager: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableOperator: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
        accountableSurveyor: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
          >
        >;
      }
  >;
};

export type DocumentationRecordCreateTypePageQueryVariables = Exact<{
  constructionSiteId: Scalars["ID"]["input"];
  constructionObjectId: Scalars["ID"]["input"];
}>;

export type DocumentationRecordCreateTypePageQuery = { __typename?: "Query" } & {
  constructionSite: Maybe<{ __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">>;
  constructionObject: Maybe<
    { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name" | "totalRecords">
  >;
};

export type DocumentationRecordEditPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DocumentationRecordEditPageQuery = { __typename?: "Query" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id" | "name" | "description"> & {
        constructionObject: { __typename?: "ConstructionObject" } & Pick<
          ConstructionObject,
          "id" | "name" | "suggestedDocumentationName"
        > & {
            accountableManager: Maybe<
              { __typename?: "User" } & Pick<
                User,
                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
              >
            >;
            accountableOperator: Maybe<
              { __typename?: "User" } & Pick<
                User,
                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
              >
            >;
            accountableSurveyor: Maybe<
              { __typename?: "User" } & Pick<
                User,
                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
              >
            >;
          };
      }
  >;
};

export type DocumentationRecordsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>> | InputMaybe<DocumentationRecordOrderByEnum>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteName?: InputMaybe<Scalars["String"]["input"]>;
  types?: InputMaybe<
    Array<InputMaybe<DocumentationRecordTypeFilterEnum>> | InputMaybe<DocumentationRecordTypeFilterEnum>
  >;
  videoTypes?: InputMaybe<
    Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>> | InputMaybe<DocumentationRecordVideoTypeFilterEnum>
  >;
  states?: InputMaybe<
    Array<InputMaybe<DocumentationRecordStateFilterEnum>> | InputMaybe<DocumentationRecordStateFilterEnum>
  >;
  threeDModelUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type DocumentationRecordsQuery = { __typename?: "Query" } & {
  organization: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id"> & {
        tasks: { __typename?: "DocumentationRecordConnection" } & Pick<DocumentationRecordConnection, "totalCount"> & {
            edges: Array<
              { __typename?: "DocumentationRecordEdge" } & {
                node: { __typename?: "DocumentationRecord" } & Pick<
                  DocumentationRecord,
                  "id" | "name" | "type" | "state" | "createdAt"
                > & {
                    accountableManager: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
                    accountableOperator: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
                    accountableSurveyor: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
                    accountableThreeDOperator: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
                    constructionObject: { __typename?: "ConstructionObject" } & Pick<
                      ConstructionObject,
                      "id" | "name"
                    > & {
                        constructionSite: { __typename?: "ConstructionSite" } & Pick<
                          ConstructionSite,
                          "id" | "name"
                        > & { organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name"> };
                      };
                    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                      ConstructionPhase,
                      "id" | "name" | "order"
                    >;
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<DocumentationRecordVideo, "id" | "type">
                    >;
                    groundControlPointDocumentations: {
                      __typename?: "GroundControlPointDocumentationConnection";
                    } & Pick<GroundControlPointDocumentationConnection, "totalCount">;
                    geodeticSurvey: Maybe<
                      { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                          txtSurvey: Maybe<{ __typename?: "Media" } & Pick<Media, "id" | "url">>;
                        }
                    >;
                    lastStateChange: Maybe<
                      { __typename?: "DocumentationRecordStateChange" } & Pick<
                        DocumentationRecordStateChange,
                        "id" | "trigger" | "returnReason" | "createdAt"
                      >
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<
                        ThreeDModel,
                        "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                      > & {
                          modelFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          textureFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          coordinatesFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          files: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                              >
                            >
                          >;
                        }
                    >;
                  };
              }
            >;
          };
      }
  >;
};

export type DocumentationRecordsForSummaryTableQueryVariables = Exact<{
  constructionObjectId: Scalars["ID"]["input"];
}>;

export type DocumentationRecordsForSummaryTableQuery = { __typename?: "Query" } & {
  constructionObject: Maybe<
    { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id"> & {
        documentationRecords: { __typename?: "DocumentationRecordConnection" } & {
          edges: Array<
            { __typename?: "DocumentationRecordEdge" } & {
              node: { __typename?: "DocumentationRecord" } & Pick<
                DocumentationRecord,
                "id" | "name" | "type" | "state" | "constructionPhaseId"
              >;
            }
          >;
        };
      }
  >;
};

export type DocumentationRecordsTableAtConstructionObjectDetailPageQueryVariables = Exact<{
  constructionObjectId: Scalars["ID"]["input"];
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>> | InputMaybe<DocumentationRecordOrderByEnum>>;
  types?: InputMaybe<
    Array<InputMaybe<DocumentationRecordTypeFilterEnum>> | InputMaybe<DocumentationRecordTypeFilterEnum>
  >;
  videoTypes?: InputMaybe<
    Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>> | InputMaybe<DocumentationRecordVideoTypeFilterEnum>
  >;
  states?: InputMaybe<
    Array<InputMaybe<DocumentationRecordStateFilterEnum>> | InputMaybe<DocumentationRecordStateFilterEnum>
  >;
}>;

export type DocumentationRecordsTableAtConstructionObjectDetailPageQuery = { __typename?: "Query" } & {
  constructionObject: Maybe<
    { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id"> & {
        documentationRecords: { __typename?: "DocumentationRecordConnection" } & Pick<
          DocumentationRecordConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "DocumentationRecordEdge" } & {
                node: { __typename?: "DocumentationRecord" } & Pick<
                  DocumentationRecord,
                  "id" | "name" | "createdAt" | "state" | "type"
                > & {
                    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                      ConstructionPhase,
                      "id" | "name" | "order"
                    >;
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<DocumentationRecordVideo, "id" | "type">
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<ThreeDModel, "id"> & {
                          modelFile: Maybe<{ __typename?: "Media" } & Pick<Media, "id" | "url">>;
                        }
                    >;
                  };
              }
            >;
          };
      }
  >;
};

export type DocumentationRecordsTableAtConstructionPhaseDetailPageQueryVariables = Exact<{
  constructionPhaseId: Scalars["ID"]["input"];
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>> | InputMaybe<DocumentationRecordOrderByEnum>>;
  types?: InputMaybe<
    Array<InputMaybe<DocumentationRecordTypeFilterEnum>> | InputMaybe<DocumentationRecordTypeFilterEnum>
  >;
  videoTypes?: InputMaybe<
    Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>> | InputMaybe<DocumentationRecordVideoTypeFilterEnum>
  >;
  states?: InputMaybe<
    Array<InputMaybe<DocumentationRecordStateFilterEnum>> | InputMaybe<DocumentationRecordStateFilterEnum>
  >;
}>;

export type DocumentationRecordsTableAtConstructionPhaseDetailPageQuery = { __typename?: "Query" } & {
  constructionPhase: Maybe<
    { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id"> & {
        documentationRecords: { __typename?: "DocumentationRecordConnection" } & Pick<
          DocumentationRecordConnection,
          "totalCount"
        > & {
            edges: Array<
              { __typename?: "DocumentationRecordEdge" } & {
                node: { __typename?: "DocumentationRecord" } & Pick<
                  DocumentationRecord,
                  "id" | "name" | "createdAt" | "state" | "type"
                > & {
                    constructionObject: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name">;
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<DocumentationRecordVideo, "id" | "type">
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<ThreeDModel, "id"> & {
                          modelFile: Maybe<{ __typename?: "Media" } & Pick<Media, "id" | "url">>;
                        }
                    >;
                  };
              }
            >;
          };
      }
  >;
};

export type DocumentationRecordsTableAtConstructionSiteDetailPageQueryVariables = Exact<{
  organizationId: Scalars["ID"]["input"];
  constructionSiteId: Scalars["String"]["input"];
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>> | InputMaybe<DocumentationRecordOrderByEnum>>;
  types?: InputMaybe<
    Array<InputMaybe<DocumentationRecordTypeFilterEnum>> | InputMaybe<DocumentationRecordTypeFilterEnum>
  >;
  videoTypes?: InputMaybe<
    Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>> | InputMaybe<DocumentationRecordVideoTypeFilterEnum>
  >;
  states?: InputMaybe<
    Array<InputMaybe<DocumentationRecordStateFilterEnum>> | InputMaybe<DocumentationRecordStateFilterEnum>
  >;
}>;

export type DocumentationRecordsTableAtConstructionSiteDetailPageQuery = { __typename?: "Query" } & {
  organization: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id"> & {
        tasks: { __typename?: "DocumentationRecordConnection" } & Pick<DocumentationRecordConnection, "totalCount"> & {
            edges: Array<
              { __typename?: "DocumentationRecordEdge" } & {
                node: { __typename?: "DocumentationRecord" } & Pick<
                  DocumentationRecord,
                  "id" | "name" | "createdAt" | "state" | "type"
                > & {
                    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                      ConstructionPhase,
                      "id" | "name" | "order"
                    >;
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<DocumentationRecordVideo, "id" | "type">
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<ThreeDModel, "id"> & {
                          modelFile: Maybe<{ __typename?: "Media" } & Pick<Media, "id" | "url">>;
                        }
                    >;
                  };
              }
            >;
          };
      }
  >;
};

export type InquiriesForAdditionalServicesPageQueryVariables = Exact<{
  organizationId: Scalars["ID"]["input"];
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByEnum>> | InputMaybe<InquiryOrderByEnum>>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusFilterEnum>> | InputMaybe<InquiryStatusFilterEnum>>;
}>;

export type InquiriesForAdditionalServicesPageQuery = { __typename?: "Query" } & {
  organization: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id"> & {
        inquiries: { __typename?: "InquiryConnection" } & Pick<InquiryConnection, "totalCount"> & {
            edges: Array<
              { __typename?: "InquiryEdge" } & {
                node: { __typename?: "Inquiry" } & Pick<Inquiry, "id" | "createdAt" | "status"> & {
                    requests: { __typename?: "InquiryRequestedTopicConnection" } & {
                      edges: Array<
                        { __typename?: "InquiryRequestedTopicEdge" } & {
                          node: { __typename?: "InquiryRequestedTopic" } & Pick<InquiryRequestedTopic, "id"> & {
                              constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
                                ConstructionSiteConnection,
                                "totalCount"
                              > & {
                                  edges: Array<
                                    { __typename?: "ConstructionSiteEdge" } & {
                                      node: { __typename: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">;
                                    }
                                  >;
                                };
                              constructionObjects: { __typename?: "ConstructionObjectConnection" } & Pick<
                                ConstructionObjectConnection,
                                "totalCount"
                              > & {
                                  edges: Array<
                                    { __typename?: "ConstructionObjectEdge" } & {
                                      node: { __typename: "ConstructionObject" } & Pick<
                                        ConstructionObject,
                                        "id" | "name"
                                      > & {
                                          constructionSite: { __typename?: "ConstructionSite" } & Pick<
                                            ConstructionSite,
                                            "id" | "name"
                                          >;
                                        };
                                    }
                                  >;
                                };
                              documentationRecords: { __typename?: "DocumentationRecordConnection" } & Pick<
                                DocumentationRecordConnection,
                                "totalCount"
                              > & {
                                  edges: Array<
                                    { __typename?: "DocumentationRecordEdge" } & {
                                      node: { __typename: "DocumentationRecord" } & Pick<
                                        DocumentationRecord,
                                        "id" | "name"
                                      > & {
                                          constructionObject: { __typename?: "ConstructionObject" } & Pick<
                                            ConstructionObject,
                                            "id"
                                          > & {
                                              constructionSite: { __typename?: "ConstructionSite" } & Pick<
                                                ConstructionSite,
                                                "id"
                                              >;
                                            };
                                        };
                                    }
                                  >;
                                };
                              inquiryTopic: { __typename?: "InquiryTopic" } & Pick<
                                InquiryTopic,
                                "id" | "topicCs" | "topicEn" | "topicUk" | "topicDe"
                              >;
                            };
                        }
                      >;
                    };
                  };
              }
            >;
          };
      }
  >;
};

export type InquiriesForAdditionalServicesReviewPageQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByEnum>> | InputMaybe<InquiryOrderByEnum>>;
  inquiryNumber?: InputMaybe<Scalars["String"]["input"]>;
  requestedByName?: InputMaybe<Scalars["String"]["input"]>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusFilterEnum>> | InputMaybe<InquiryStatusFilterEnum>>;
}>;

export type InquiriesForAdditionalServicesReviewPageQuery = { __typename?: "Query" } & {
  inquiries: { __typename?: "InquiryConnection" } & Pick<InquiryConnection, "totalCount"> & {
      edges: Array<
        { __typename?: "InquiryEdge" } & {
          node: { __typename?: "Inquiry" } & Pick<Inquiry, "id" | "status" | "createdAt"> & {
              requests: { __typename?: "InquiryRequestedTopicConnection" } & {
                edges: Array<
                  { __typename?: "InquiryRequestedTopicEdge" } & {
                    node: { __typename?: "InquiryRequestedTopic" } & Pick<InquiryRequestedTopic, "id"> & {
                        inquiryTopic: { __typename?: "InquiryTopic" } & Pick<
                          InquiryTopic,
                          "id" | "topicCs" | "topicEn" | "topicUk" | "topicDe"
                        >;
                      };
                  }
                >;
              };
              user: { __typename?: "User" } & Pick<User, "id" | "firstName" | "lastName">;
              organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
            };
        }
      >;
    };
};

export type InquiryForDetailPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type InquiryForDetailPageQuery = { __typename?: "Query" } & {
  inquiry: Maybe<
    { __typename?: "Inquiry" } & Pick<
      Inquiry,
      "id" | "status" | "createdAt" | "resolvedAt" | "userComment" | "resolverComment"
    > & {
        resolvedBy: Maybe<{ __typename?: "User" } & Pick<User, "id" | "firstName" | "lastName">>;
        files: { __typename?: "MediaConnection" } & {
          edges: Array<
            { __typename?: "MediaEdge" } & {
              node: { __typename?: "Media" } & Pick<Media, "id" | "fileName" | "url" | "state">;
            }
          >;
        };
        requests: { __typename?: "InquiryRequestedTopicConnection" } & {
          edges: Array<
            { __typename?: "InquiryRequestedTopicEdge" } & {
              node: { __typename?: "InquiryRequestedTopic" } & Pick<InquiryRequestedTopic, "id"> & {
                  inquiryTopic: { __typename?: "InquiryTopic" } & Pick<
                    InquiryTopic,
                    "id" | "topicCs" | "topicEn" | "topicUk" | "topicDe"
                  >;
                  documentationRecords: { __typename?: "DocumentationRecordConnection" } & {
                    edges: Array<
                      { __typename?: "DocumentationRecordEdge" } & {
                        node: { __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id" | "name"> & {
                            constructionObject: { __typename?: "ConstructionObject" } & Pick<
                              ConstructionObject,
                              "id" | "name"
                            > & {
                                constructionSite: { __typename?: "ConstructionSite" } & Pick<
                                  ConstructionSite,
                                  "id" | "name"
                                >;
                              };
                          };
                      }
                    >;
                  };
                  constructionSites: { __typename?: "ConstructionSiteConnection" } & {
                    edges: Array<
                      { __typename?: "ConstructionSiteEdge" } & {
                        node: { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">;
                      }
                    >;
                  };
                  constructionObjects: { __typename?: "ConstructionObjectConnection" } & {
                    edges: Array<
                      { __typename?: "ConstructionObjectEdge" } & {
                        node: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name"> & {
                            constructionSite: { __typename?: "ConstructionSite" } & Pick<
                              ConstructionSite,
                              "id" | "name"
                            >;
                          };
                      }
                    >;
                  };
                };
            }
          >;
        };
      }
  >;
};

export type InquiryForReviewDetailPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type InquiryForReviewDetailPageQuery = { __typename?: "Query" } & {
  inquiry: Maybe<
    { __typename?: "Inquiry" } & Pick<
      Inquiry,
      "id" | "status" | "createdAt" | "resolvedAt" | "userComment" | "resolverComment"
    > & {
        organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
        user: { __typename?: "User" } & Pick<User, "id" | "firstName" | "lastName">;
        files: { __typename?: "MediaConnection" } & {
          edges: Array<
            { __typename?: "MediaEdge" } & {
              node: { __typename?: "Media" } & Pick<Media, "id" | "fileName" | "url" | "state">;
            }
          >;
        };
        requests: { __typename?: "InquiryRequestedTopicConnection" } & {
          edges: Array<
            { __typename?: "InquiryRequestedTopicEdge" } & {
              node: { __typename?: "InquiryRequestedTopic" } & Pick<InquiryRequestedTopic, "id"> & {
                  inquiryTopic: { __typename?: "InquiryTopic" } & Pick<
                    InquiryTopic,
                    "id" | "topicCs" | "topicEn" | "topicUk" | "topicDe"
                  >;
                  documentationRecords: { __typename?: "DocumentationRecordConnection" } & {
                    edges: Array<
                      { __typename?: "DocumentationRecordEdge" } & {
                        node: { __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id" | "name"> & {
                            constructionObject: { __typename?: "ConstructionObject" } & Pick<
                              ConstructionObject,
                              "id" | "name"
                            > & {
                                constructionSite: { __typename?: "ConstructionSite" } & Pick<
                                  ConstructionSite,
                                  "id" | "name"
                                >;
                              };
                          };
                      }
                    >;
                  };
                  constructionSites: { __typename?: "ConstructionSiteConnection" } & {
                    edges: Array<
                      { __typename?: "ConstructionSiteEdge" } & {
                        node: { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">;
                      }
                    >;
                  };
                  constructionObjects: { __typename?: "ConstructionObjectConnection" } & {
                    edges: Array<
                      { __typename?: "ConstructionObjectEdge" } & {
                        node: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name"> & {
                            constructionSite: { __typename?: "ConstructionSite" } & Pick<
                              ConstructionSite,
                              "id" | "name"
                            >;
                          };
                      }
                    >;
                  };
                };
            }
          >;
        };
      }
  >;
};

export type InquiryTopicsQueryVariables = Exact<{ [key: string]: never }>;

export type InquiryTopicsQuery = { __typename?: "Query" } & {
  inquiryTopics: Array<
    Maybe<
      { __typename?: "InquiryTopic" } & Pick<
        InquiryTopic,
        "id" | "isStructural" | "topicCs" | "topicEn" | "topicUk" | "topicDe"
      >
    >
  >;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: "Query" } & {
  me: Maybe<
    { __typename?: "User" } & Pick<
      User,
      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
    > & {
        organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
          edges: Array<
            { __typename?: "OrganizationMembershipEdge" } & {
              node: { __typename?: "OrganizationMembership" } & {
                organization: { __typename?: "Organization" } & Pick<
                  Organization,
                  "id" | "crn" | "name" | "credits" | "createdAt" | "archivedAt" | "activatedAt"
                > & {
                    organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                      edges: Array<
                        { __typename?: "OrganizationMembershipEdge" } & {
                          node: { __typename?: "OrganizationMembership" } & Pick<OrganizationMembership, "id"> & {
                              user: { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >;
                            };
                        }
                      >;
                    };
                  };
              };
            }
          >;
        };
        receivedInvitations: { __typename?: "OrganizationInviteConnection" } & {
          edges: Array<
            { __typename?: "OrganizationInviteEdge" } & {
              node: { __typename?: "OrganizationInvite" } & Pick<
                OrganizationInvite,
                "id" | "state" | "role" | "createdAt" | "expiresAt"
              > & {
                  organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
                  user: { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >;
                  invitedBy: { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >;
                };
            }
          >;
        };
        purchases: { __typename?: "PurchaseConnection" } & {
          edges: Array<
            { __typename?: "PurchaseEdge" } & {
              node: { __typename?: "Purchase" } & Pick<
                Purchase,
                "id" | "type" | "createdAt" | "claimedAt" | "expiresAt" | "externalId" | "credits"
              > & {
                  organization: Maybe<
                    { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "crn" | "activatedAt"> & {
                        diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                            byKind: Array<
                              Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>
                            >;
                          };
                      }
                  >;
                };
            }
          >;
        };
      }
  >;
};

export type MeLanguageQueryVariables = Exact<{ [key: string]: never }>;

export type MeLanguageQuery = { __typename?: "Query" } & {
  me: Maybe<{ __typename?: "User" } & Pick<User, "id" | "language">>;
};

export type MediaQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type MediaQuery = { __typename?: "Query" } & {
  media: Maybe<
    { __typename?: "Media" } & Pick<
      Media,
      "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
    >
  >;
};

export type MediaStateQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type MediaStateQuery = { __typename?: "Query" } & {
  media: Maybe<{ __typename?: "Media" } & Pick<Media, "id" | "state">>;
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OrganizationQuery = { __typename?: "Query" } & {
  organization: Maybe<
    { __typename?: "Organization" } & Pick<
      Organization,
      | "id"
      | "name"
      | "activatedAt"
      | "archivedAt"
      | "createdAt"
      | "credits"
      | "crn"
      | "notificationsEnabled"
      | "nextStoragePaymentDate"
    > & {
        organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
          edges: Array<
            { __typename?: "OrganizationMembershipEdge" } & {
              node: { __typename?: "OrganizationMembership" } & Pick<OrganizationMembership, "id" | "createdAt"> & {
                  user: { __typename?: "User" } & Pick<
                    User,
                    "externalState" | "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >;
                  organization: { __typename?: "Organization" } & Pick<
                    Organization,
                    "id" | "name" | "crn" | "activatedAt"
                  >;
                };
            }
          >;
        };
        constructionSites: { __typename?: "ConstructionSiteConnection" } & Pick<
          ConstructionSiteConnection,
          "totalCount"
        >;
        organizationInvites: { __typename?: "OrganizationInviteConnection" } & {
          edges: Array<
            { __typename?: "OrganizationInviteEdge" } & {
              node: { __typename?: "OrganizationInvite" } & Pick<
                OrganizationInvite,
                "id" | "state" | "role" | "createdAt"
              > & {
                  user: { __typename?: "User" } & Pick<
                    User,
                    "externalState" | "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >;
                };
            }
          >;
        };
        diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
            byKind: Array<Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>>;
          };
      }
  >;
};

export type OrganizationTasksQueryVariables = Exact<{
  organizationId: Scalars["ID"]["input"];
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<
    Array<InputMaybe<DocumentationRecordStateFilterEnum>> | InputMaybe<DocumentationRecordStateFilterEnum>
  >;
  types?: InputMaybe<
    Array<InputMaybe<DocumentationRecordTypeFilterEnum>> | InputMaybe<DocumentationRecordTypeFilterEnum>
  >;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>> | InputMaybe<DocumentationRecordOrderByEnum>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteName?: InputMaybe<Scalars["String"]["input"]>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  constructionObjectName?: InputMaybe<Scalars["String"]["input"]>;
  threeDModelUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  videoTypes?: InputMaybe<
    Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>> | InputMaybe<DocumentationRecordVideoTypeFilterEnum>
  >;
  withTotalSize: Scalars["Boolean"]["input"];
  withModelSize: Scalars["Boolean"]["input"];
  withAccountableManager: Scalars["Boolean"]["input"];
}>;

export type OrganizationTasksQuery = { __typename?: "Query" } & {
  organization: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id"> & {
        tasks: { __typename?: "DocumentationRecordConnection" } & Pick<DocumentationRecordConnection, "totalCount"> & {
            edges: Array<
              { __typename?: "DocumentationRecordEdge" } & {
                node: { __typename?: "DocumentationRecord" } & MakeMaybe<
                  Pick<DocumentationRecord, "totalSize" | "modelSize" | "id" | "name" | "type" | "state" | "createdAt">,
                  "totalSize" | "modelSize"
                > & {
                    accountableManager?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
                    accountableThreeDOperator: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
                    constructionObject: { __typename?: "ConstructionObject" } & Pick<
                      ConstructionObject,
                      "id" | "name"
                    > & {
                        constructionSite: { __typename?: "ConstructionSite" } & Pick<
                          ConstructionSite,
                          "id" | "name"
                        > & { organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name"> };
                      };
                    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                      ConstructionPhase,
                      "id" | "name" | "order"
                    >;
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<DocumentationRecordVideo, "id" | "type">
                    >;
                    groundControlPointDocumentations: {
                      __typename?: "GroundControlPointDocumentationConnection";
                    } & Pick<GroundControlPointDocumentationConnection, "totalCount">;
                    geodeticSurvey: Maybe<
                      { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                          txtSurvey: Maybe<{ __typename?: "Media" } & Pick<Media, "id" | "url">>;
                        }
                    >;
                    lastStateChange: Maybe<
                      { __typename?: "DocumentationRecordStateChange" } & Pick<
                        DocumentationRecordStateChange,
                        "id" | "trigger" | "returnReason" | "createdAt"
                      >
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<
                        ThreeDModel,
                        "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                      > & {
                          modelFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          textureFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          coordinatesFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          files: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                              >
                            >
                          >;
                        }
                    >;
                  };
              }
            >;
          };
      }
  >;
};

export type OutputExtensionsForDocumentationRecordDetailPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OutputExtensionsForDocumentationRecordDetailPageQuery = { __typename?: "Query" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id"> & {
        outputExtensions: { __typename?: "OutputExtensionConnection" } & {
          edges: Array<
            { __typename?: "OutputExtensionEdge" } & {
              node: { __typename?: "OutputExtension" } & Pick<
                OutputExtension,
                "id" | "createdAt" | "completedAt" | "comment"
              > & {
                  files: { __typename?: "MediaConnection" } & {
                    edges: Array<
                      { __typename?: "MediaEdge" } & {
                        node: { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >;
                      }
                    >;
                  };
                  inquiry: { __typename?: "Inquiry" } & Pick<Inquiry, "id" | "status">;
                  inquiryTopic: { __typename?: "InquiryTopic" } & Pick<
                    InquiryTopic,
                    "id" | "topicCs" | "topicEn" | "topicUk" | "topicDe"
                  >;
                };
            }
          >;
        };
      }
  >;
};

export type OutputExtensionsForTaskThreeDOperatorQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type OutputExtensionsForTaskThreeDOperatorQuery = { __typename?: "Query" } & {
  documentationRecord: Maybe<
    { __typename?: "DocumentationRecord" } & Pick<DocumentationRecord, "id"> & {
        outputExtensions: { __typename?: "OutputExtensionConnection" } & {
          edges: Array<
            { __typename?: "OutputExtensionEdge" } & {
              node: { __typename?: "OutputExtension" } & Pick<OutputExtension, "id" | "comment"> & {
                  files: { __typename?: "MediaConnection" } & {
                    edges: Array<
                      { __typename?: "MediaEdge" } & {
                        node: { __typename?: "Media" } & Pick<
                          Media,
                          "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                        >;
                      }
                    >;
                  };
                  inquiry: { __typename?: "Inquiry" } & Pick<
                    Inquiry,
                    "id" | "status" | "createdAt" | "userComment" | "resolverComment"
                  > & { user: { __typename?: "User" } & Pick<User, "id" | "firstName" | "lastName"> };
                  inquiryTopic: { __typename?: "InquiryTopic" } & Pick<
                    InquiryTopic,
                    "id" | "topicCs" | "topicEn" | "topicUk" | "topicDe"
                  >;
                };
            }
          >;
        };
      }
  >;
};

export type PrefetchQueryVariables = Exact<{ [key: string]: never }>;

export type PrefetchQuery = { __typename?: "Query" } & {
  me: Maybe<
    { __typename?: "User" } & Pick<
      User,
      "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
    > & {
        organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
          edges: Array<
            { __typename?: "OrganizationMembershipEdge" } & {
              node: { __typename?: "OrganizationMembership" } & {
                organization: { __typename?: "Organization" } & Pick<
                  Organization,
                  "id" | "crn" | "name" | "credits" | "createdAt" | "archivedAt" | "activatedAt"
                > & {
                    organizationMemberships: { __typename?: "OrganizationMembershipConnection" } & {
                      edges: Array<
                        { __typename?: "OrganizationMembershipEdge" } & {
                          node: { __typename?: "OrganizationMembership" } & Pick<OrganizationMembership, "id"> & {
                              user: { __typename?: "User" } & Pick<
                                User,
                                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                              >;
                            };
                        }
                      >;
                    };
                  };
              };
            }
          >;
        };
        receivedInvitations: { __typename?: "OrganizationInviteConnection" } & {
          edges: Array<
            { __typename?: "OrganizationInviteEdge" } & {
              node: { __typename?: "OrganizationInvite" } & Pick<
                OrganizationInvite,
                "id" | "state" | "role" | "createdAt" | "expiresAt"
              > & {
                  organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name">;
                  user: { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >;
                  invitedBy: { __typename?: "User" } & Pick<
                    User,
                    "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
                  >;
                };
            }
          >;
        };
        purchases: { __typename?: "PurchaseConnection" } & {
          edges: Array<
            { __typename?: "PurchaseEdge" } & {
              node: { __typename?: "Purchase" } & Pick<
                Purchase,
                "id" | "type" | "createdAt" | "claimedAt" | "expiresAt" | "externalId" | "credits"
              > & {
                  organization: Maybe<
                    { __typename?: "Organization" } & Pick<Organization, "id" | "name" | "crn" | "activatedAt"> & {
                        diskUsageStats: { __typename?: "DiskUsageStats" } & Pick<DiskUsageStats, "total"> & {
                            byKind: Array<
                              Maybe<{ __typename?: "DiskUsageKindStats" } & Pick<DiskUsageKindStats, "kind" | "total">>
                            >;
                          };
                      }
                  >;
                };
            }
          >;
        };
      }
  >;
  catalog: Maybe<
    { __typename?: "Catalog" } & Pick<
      Catalog,
      | "id"
      | "createDocumentationRecord"
      | "requestThreeDModelNoScale"
      | "requestThreeDModelScale"
      | "requestThreeDModelMaps"
      | "requestThreeDModelSpray"
      | "requestThreeDModelQrCodes"
      | "downloadThreeDModel"
      | "inviteManager"
      | "storageLimits"
      | "storagePrices"
    > & {
        storageClassPrices: Maybe<
          Array<Maybe<{ __typename?: "StorageClass" } & Pick<StorageClass, "maxGb" | "price">>>
        >;
        requestModelPrices: Maybe<
          { __typename?: "ModelPrices" } & Pick<ModelPrices, "noScale" | "scale" | "maps" | "spray" | "qrCodes">
        >;
      }
  >;
  config: Maybe<
    { __typename?: "Config" } & Pick<
      Config,
      | "id"
      | "minimalGroundControlPoints"
      | "threeDModelDeleteAfterDays"
      | "documentationRecordDeleteAfterDays"
      | "organizationInviteSentValidDays"
      | "purchaseExpirationMonths"
      | "helpdeskUrl"
      | "externalAccountUrl"
      | "externalBaseName"
      | "externalBaseUrl"
      | "contactUrl"
      | "logoutRedirectUrl"
      | "providerName"
      | "providerUrl"
      | "providerCrn"
      | "transactionMessagesBccEmail"
      | "allowedGeodeticSurveyFileTypes"
      | "allowedImageFileTypes"
      | "allowedVideoFileTypes"
      | "allowedOtherFileTypes"
      | "shopUrlCs"
      | "shopUrlEn"
      | "shopUrlUk"
      | "shopUrlDe"
      | "defaultConstructionPhasesCs"
      | "defaultConstructionPhasesEn"
      | "defaultConstructionPhasesUk"
      | "defaultConstructionPhasesDe"
      | "documentationRecordReturnReasonsCs"
      | "documentationRecordReturnReasonsEn"
      | "documentationRecordReturnReasonsUk"
      | "documentationRecordReturnReasonsDe"
      | "documentationRecordRejectReasonsCs"
      | "documentationRecordRejectReasonsEn"
      | "documentationRecordRejectReasonsUk"
      | "documentationRecordRejectReasonsDe"
      | "storagePaymentIntervalMonths"
      | "transactionEmailsEnabled"
      | "oauthUserinfoUrl"
      | "fileUploadUrl"
      | "threeDViewerUrl"
      | "emailContactCs"
      | "emailContactEn"
      | "emailContactUk"
      | "emailContactDe"
      | "phoneContactCs"
      | "phoneContactEn"
      | "phoneContactUk"
      | "phoneContactDe"
    >
  >;
};

export type SearchQueryVariables = Exact<{
  query: Scalars["String"]["input"];
  types: Array<InputMaybe<SearchType>> | InputMaybe<SearchType>;
}>;

export type SearchQuery = { __typename?: "Query" } & {
  search: Maybe<
    Array<
      Maybe<
        | ({ __typename: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name">)
        | ({ __typename: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">)
        | ({ __typename: "DocumentationRecord" } & Pick<DocumentationRecord, "id" | "name">)
      >
    >
  >;
};

export type SearchForInquiryFormQueryVariables = Exact<{
  query: Scalars["String"]["input"];
}>;

export type SearchForInquiryFormQuery = { __typename?: "Query" } & {
  search: Maybe<
    Array<
      Maybe<
        | ({ __typename: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name">)
        | ({ __typename: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">)
        | ({ __typename: "DocumentationRecord" } & Pick<DocumentationRecord, "id" | "name">)
      >
    >
  >;
};

export type TaskCountsQueryVariables = Exact<{ [key: string]: never }>;

export type TaskCountsQuery = { __typename?: "Query" } & {
  me: Maybe<
    { __typename?: "User" } & Pick<User, "id"> & {
        allTasks: { __typename?: "DocumentationRecordConnection" } & Pick<DocumentationRecordConnection, "totalCount">;
        mobileTasks: { __typename?: "DocumentationRecordConnection" } & Pick<
          DocumentationRecordConnection,
          "totalCount"
        >;
      }
  >;
};

export type TasksQueryVariables = Exact<{
  state?: InputMaybe<
    Array<InputMaybe<DocumentationRecordStateFilterEnum>> | InputMaybe<DocumentationRecordStateFilterEnum>
  >;
  toProcess?: InputMaybe<Scalars["Boolean"]["input"]>;
  types?: InputMaybe<
    Array<InputMaybe<DocumentationRecordTypeFilterEnum>> | InputMaybe<DocumentationRecordTypeFilterEnum>
  >;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  constructionSiteName?: InputMaybe<Scalars["String"]["input"]>;
  constructionObjectName?: InputMaybe<Scalars["String"]["input"]>;
  constructionPhaseOrder?: InputMaybe<Scalars["String"]["input"]>;
  surveyUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDModelUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  threeDOperatorAssigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  videoTypes?: InputMaybe<
    Array<InputMaybe<DocumentationRecordVideoTypeFilterEnum>> | InputMaybe<DocumentationRecordVideoTypeFilterEnum>
  >;
  orderBy?: InputMaybe<Array<InputMaybe<DocumentationRecordOrderByEnum>> | InputMaybe<DocumentationRecordOrderByEnum>>;
}>;

export type TasksQuery = { __typename?: "Query" } & {
  me: Maybe<
    { __typename?: "User" } & Pick<User, "id"> & {
        tasks: { __typename?: "DocumentationRecordConnection" } & Pick<DocumentationRecordConnection, "totalCount"> & {
            edges: Array<
              { __typename?: "DocumentationRecordEdge" } & {
                node: { __typename?: "DocumentationRecord" } & Pick<
                  DocumentationRecord,
                  "id" | "name" | "type" | "state" | "createdAt"
                > & {
                    accountableThreeDOperator: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
                    constructionObject: { __typename?: "ConstructionObject" } & Pick<
                      ConstructionObject,
                      "id" | "name"
                    > & {
                        constructionSite: { __typename?: "ConstructionSite" } & Pick<
                          ConstructionSite,
                          "id" | "name"
                        > & { organization: { __typename?: "Organization" } & Pick<Organization, "id" | "name"> };
                      };
                    constructionPhase: { __typename?: "ConstructionPhase" } & Pick<
                      ConstructionPhase,
                      "id" | "name" | "order"
                    >;
                    documentationRecordVideo: Maybe<
                      { __typename?: "DocumentationRecordVideo" } & Pick<DocumentationRecordVideo, "id" | "type">
                    >;
                    groundControlPointDocumentations: {
                      __typename?: "GroundControlPointDocumentationConnection";
                    } & Pick<GroundControlPointDocumentationConnection, "totalCount">;
                    geodeticSurvey: Maybe<
                      { __typename?: "GeodeticSurvey" } & Pick<GeodeticSurvey, "id"> & {
                          txtSurvey: Maybe<{ __typename?: "Media" } & Pick<Media, "id" | "url">>;
                        }
                    >;
                    lastStateChange: Maybe<
                      { __typename?: "DocumentationRecordStateChange" } & Pick<
                        DocumentationRecordStateChange,
                        "id" | "trigger" | "returnReason" | "createdAt"
                      >
                    >;
                    threeDModel: Maybe<
                      { __typename?: "ThreeDModel" } & Pick<
                        ThreeDModel,
                        "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
                      > & {
                          modelFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          textureFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          coordinatesFile: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
                            >
                          >;
                          files: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">
                              >
                            >
                          >;
                        }
                    >;
                  };
              }
            >;
          };
      }
  >;
};

export type ThreeDModelQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ThreeDModelQuery = { __typename?: "Query" } & {
  threeDModel: Maybe<
    { __typename?: "ThreeDModel" } & Pick<
      ThreeDModel,
      "id" | "createdAt" | "archivedAt" | "isShared" | "coordinateSystem"
    > & {
        documentationRecord: { __typename?: "DocumentationRecord" } & Pick<
          DocumentationRecord,
          "id" | "name" | "type"
        > & {
            accountableThreeDOperator: Maybe<
              { __typename?: "User" } & Pick<
                User,
                "id" | "role" | "email" | "firstName" | "lastName" | "phoneNumber" | "language"
              >
            >;
            constructionPhase: { __typename?: "ConstructionPhase" } & Pick<ConstructionPhase, "id" | "name">;
            constructionObject: { __typename?: "ConstructionObject" } & Pick<ConstructionObject, "id" | "name"> & {
                constructionSite: { __typename?: "ConstructionSite" } & Pick<ConstructionSite, "id" | "name">;
              };
            documentationRecordVideo: Maybe<
              { __typename?: "DocumentationRecordVideo" } & Pick<DocumentationRecordVideo, "id" | "type">
            >;
          };
        modelFile: Maybe<
          { __typename?: "Media" } & Pick<
            Media,
            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
          >
        >;
        textureFile: Maybe<
          { __typename?: "Media" } & Pick<
            Media,
            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
          >
        >;
        coordinatesFile: Maybe<
          { __typename?: "Media" } & Pick<
            Media,
            "id" | "createdAt" | "fileName" | "fileType" | "path" | "size" | "state" | "url"
          >
        >;
        files: Maybe<
          Array<Maybe<{ __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">>>
        >;
      }
  >;
};

export type ThreeDModelForDownloadQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ThreeDModelForDownloadQuery = { __typename?: "Query" } & {
  threeDModel: Maybe<
    { __typename?: "ThreeDModel" } & Pick<ThreeDModel, "id" | "isShared"> & {
        files: Maybe<
          Array<Maybe<{ __typename?: "DownloadableFile" } & Pick<DownloadableFile, "id" | "fileName" | "size">>>
        >;
      }
  >;
};

export type ThreeDModelForShareQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ThreeDModelForShareQuery = { __typename?: "Query" } & {
  threeDModel: Maybe<{ __typename?: "ThreeDModel" } & Pick<ThreeDModel, "id" | "isShared">>;
};

export const StorageClassFragmentDoc = gql`
  fragment StorageClass on StorageClass {
    maxGb
    price
  }
`;
export const ModelPricesFragmentDoc = gql`
  fragment ModelPrices on ModelPrices {
    noScale
    scale
    maps
    spray
    qrCodes
  }
`;
export const CatalogFragmentDoc = gql`
  fragment Catalog on Catalog {
    id
    createDocumentationRecord
    requestThreeDModelNoScale
    requestThreeDModelScale
    requestThreeDModelMaps
    requestThreeDModelSpray
    requestThreeDModelQrCodes
    downloadThreeDModel
    inviteManager
    downloadThreeDModel
    storageLimits
    storagePrices
    storageClassPrices {
      ...StorageClass
    }
    requestModelPrices {
      ...ModelPrices
    }
  }
  ${StorageClassFragmentDoc}
  ${ModelPricesFragmentDoc}
`;
export const ContactLocalesFragmentDoc = gql`
  fragment ContactLocales on Config {
    emailContactCs
    emailContactEn
    emailContactUk
    emailContactDe
    phoneContactCs
    phoneContactEn
    phoneContactUk
    phoneContactDe
  }
`;
export const ConfigFragmentDoc = gql`
  fragment Config on Config {
    id
    minimalGroundControlPoints
    threeDModelDeleteAfterDays
    documentationRecordDeleteAfterDays
    organizationInviteSentValidDays
    purchaseExpirationMonths
    helpdeskUrl
    externalAccountUrl
    externalBaseName
    externalBaseUrl
    contactUrl
    logoutRedirectUrl
    ...ContactLocales
    providerName
    providerUrl
    providerCrn
    transactionMessagesBccEmail
    allowedGeodeticSurveyFileTypes
    allowedImageFileTypes
    allowedVideoFileTypes
    allowedOtherFileTypes
    shopUrlCs
    shopUrlEn
    shopUrlUk
    shopUrlDe
    defaultConstructionPhasesCs
    defaultConstructionPhasesEn
    defaultConstructionPhasesUk
    defaultConstructionPhasesDe
    documentationRecordReturnReasonsCs
    documentationRecordReturnReasonsEn
    documentationRecordReturnReasonsUk
    documentationRecordReturnReasonsDe
    documentationRecordRejectReasonsCs
    documentationRecordRejectReasonsEn
    documentationRecordRejectReasonsUk
    documentationRecordRejectReasonsDe
    storagePaymentIntervalMonths
    transactionEmailsEnabled
    oauthUserinfoUrl
    fileUploadUrl
    threeDViewerUrl
  }
  ${ContactLocalesFragmentDoc}
`;
export const ConstructionSiteTableFragmentDoc = gql`
  fragment ConstructionSiteTableFragment on ConstructionSite {
    id
    name
    state
    createdAt
    archivedAt
    accountableManager {
      id
      firstName
      lastName
    }
    constructionObjects(first: 1) {
      totalCount
    }
    constructionPhases(first: 1) {
      totalCount
    }
    createdRecords
    workInProgressRecords
    completedRecords
  }
`;
export const ConstructionPhaseSimpleFragmentDoc = gql`
  fragment ConstructionPhaseSimple on ConstructionPhase {
    id
    name
    order
    createdAt
    archivedAt
    createdRecords
    workInProgressRecords
    completedRecords
  }
`;
export const MediaFragmentDoc = gql`
  fragment Media on Media {
    id
    createdAt
    fileName
    fileType
    path
    size
    state
    url
  }
`;
export const ThreeDModelFragmentDoc = gql`
  fragment ThreeDModel on ThreeDModel {
    id
    createdAt
    archivedAt
    isShared
    modelFile {
      ...Media
    }
    textureFile {
      ...Media
    }
    coordinatesFile {
      ...Media
    }
    coordinateSystem
    files {
      id
      fileName
      size
    }
  }
  ${MediaFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    role
    email
    firstName
    lastName
    phoneNumber
    language
  }
`;
export const SimpleGroundControlPointFragmentDoc = gql`
  fragment SimpleGroundControlPoint on GroundControlPointDocumentation {
    id
    description
    files {
      edges {
        node {
          ...Media
        }
      }
    }
  }
  ${MediaFragmentDoc}
`;
export const GroundControlPointDocumentationFragmentDoc = gql`
  fragment GroundControlPointDocumentation on GroundControlPointDocumentation {
    id
    order
    createdAt
    archivedAt
    location
    description
    files {
      edges {
        node {
          ...Media
        }
      }
    }
  }
  ${MediaFragmentDoc}
`;
export const DocumentationRecordVideoFragmentDoc = gql`
  fragment DocumentationRecordVideo on DocumentationRecordVideo {
    id
    type
    captureLocation
    video {
      ...Media
    }
  }
  ${MediaFragmentDoc}
`;
export const GeodeticSurveyFragmentDoc = gql`
  fragment GeodeticSurvey on GeodeticSurvey {
    id
    txtSurvey {
      ...Media
    }
    files {
      edges {
        node {
          ...Media
        }
      }
    }
  }
  ${MediaFragmentDoc}
`;
export const ConstructionElementFragmentDoc = gql`
  fragment ConstructionElement on ConstructionElement {
    id
    createdAt
    description
    archivedAt
    photo {
      ...Media
    }
  }
  ${MediaFragmentDoc}
`;
export const TriggerFragmentDoc = gql`
  fragment Trigger on DocumentationRecordTrigger {
    trigger
    available
    conditions
    conditionErrors {
      validation
      attribute
    }
  }
`;
export const DocumentationRecordStateChangeFragmentDoc = gql`
  fragment DocumentationRecordStateChange on DocumentationRecordStateChange {
    id
    comment
    createdAt
    fromState
    rejectionReason
    returnReason
    toState
    trigger
    user {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export const DocumentationRecordFragmentDoc = gql`
  fragment DocumentationRecord on DocumentationRecord {
    id
    createdAt
    archivedAt
    name
    type
    state
    description
    constructionObject {
      id
      name
      location
      description
      constructionSite {
        id
        name
        location
        description
        autoRequestThreeD
        organization {
          id
          name
          credits
        }
      }
    }
    constructionPhase {
      ...ConstructionPhaseSimple
    }
    threeDModel {
      ...ThreeDModel
    }
    accountableManager {
      ...User
    }
    accountableOperator {
      ...User
    }
    accountableSurveyor {
      ...User
    }
    accountableThreeDOperator {
      ...User
    }
    simpleGroundControlPointCount
    simpleGroundControlPoint {
      ...SimpleGroundControlPoint
    }
    groundControlPointDocumentations {
      edges {
        node {
          ...GroundControlPointDocumentation
        }
      }
    }
    documentationRecordVideo {
      ...DocumentationRecordVideo
    }
    geodeticSurvey {
      ...GeodeticSurvey
    }
    constructionElements {
      edges {
        node {
          ...ConstructionElement
        }
      }
    }
    triggers {
      ...Trigger
    }
    stateChanges {
      edges {
        node {
          ...DocumentationRecordStateChange
        }
      }
    }
    lastStateChange {
      ...DocumentationRecordStateChange
    }
    outputExtensions {
      totalCount
    }
    firstVersion: versions(first: 1) {
      edges {
        node {
          id
          createdAt
          user {
            ...User
          }
        }
      }
    }
    currentVersion: versions(last: 1) {
      edges {
        node {
          id
          createdAt
          user {
            ...User
          }
        }
      }
    }
  }
  ${ConstructionPhaseSimpleFragmentDoc}
  ${ThreeDModelFragmentDoc}
  ${UserFragmentDoc}
  ${SimpleGroundControlPointFragmentDoc}
  ${GroundControlPointDocumentationFragmentDoc}
  ${DocumentationRecordVideoFragmentDoc}
  ${GeodeticSurveyFragmentDoc}
  ${ConstructionElementFragmentDoc}
  ${TriggerFragmentDoc}
  ${DocumentationRecordStateChangeFragmentDoc}
`;
export const InquiryTopicLocalesFragmentDoc = gql`
  fragment InquiryTopicLocalesFragment on InquiryTopic {
    topicCs
    topicEn
    topicUk
    topicDe
  }
`;
export const OrganizationSimpleFragmentDoc = gql`
  fragment OrganizationSimple on Organization {
    id
    name
    crn
    activatedAt
  }
`;
export const OrganizationInviteSimpleFragmentDoc = gql`
  fragment OrganizationInviteSimple on OrganizationInvite {
    id
    user {
      ...User
      externalState
    }
    state
    role
    createdAt
  }
  ${UserFragmentDoc}
`;
export const DiskUsageStatsFragmentDoc = gql`
  fragment DiskUsageStats on DiskUsageStats {
    total
    byKind {
      kind
      total
    }
  }
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    activatedAt
    archivedAt
    createdAt
    credits
    crn
    notificationsEnabled
    nextStoragePaymentDate
    organizationMemberships {
      edges {
        node {
          id
          createdAt
          user {
            ...User
            externalState
          }
          organization {
            ...OrganizationSimple
          }
        }
      }
    }
    constructionSites(first: 1) {
      totalCount
    }
    organizationInvites {
      edges {
        node {
          ...OrganizationInviteSimple
        }
      }
    }
    diskUsageStats {
      ...DiskUsageStats
    }
  }
  ${UserFragmentDoc}
  ${OrganizationSimpleFragmentDoc}
  ${OrganizationInviteSimpleFragmentDoc}
  ${DiskUsageStatsFragmentDoc}
`;
export const TaskFragmentDoc = gql`
  fragment Task on DocumentationRecord {
    id
    name
    type
    state
    createdAt
    accountableThreeDOperator {
      id
    }
    constructionObject {
      id
      name
      constructionSite {
        id
        name
        organization {
          id
          name
        }
      }
    }
    constructionPhase {
      id
      name
      order
    }
    documentationRecordVideo {
      id
      type
    }
    groundControlPointDocumentations {
      totalCount
    }
    geodeticSurvey {
      id
      txtSurvey {
        id
        url
      }
    }
    lastStateChange {
      id
      trigger
      returnReason
      createdAt
    }
    threeDModel {
      ...ThreeDModel
    }
  }
  ${ThreeDModelFragmentDoc}
`;
export const OrganizationInviteFragmentDoc = gql`
  fragment OrganizationInvite on OrganizationInvite {
    id
    organization {
      id
      name
    }
    user {
      ...User
    }
    invitedBy {
      ...User
    }
    state
    role
    createdAt
    expiresAt
  }
  ${UserFragmentDoc}
`;
export const UserInvitationsFragmentDoc = gql`
  fragment UserInvitations on User {
    receivedInvitations {
      edges {
        node {
          ...OrganizationInvite
        }
      }
    }
  }
  ${OrganizationInviteFragmentDoc}
`;
export const UserOrganizationMembershipsFragmentDoc = gql`
  fragment UserOrganizationMemberships on User {
    organizationMemberships {
      edges {
        node {
          organization {
            id
            crn
            name
            credits
            createdAt
            archivedAt
            activatedAt
            organizationMemberships {
              edges {
                node {
                  id
                  user {
                    ...User
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
`;
export const PurchaseSimpleFragmentDoc = gql`
  fragment PurchaseSimple on Purchase {
    id
    type
    createdAt
    claimedAt
    expiresAt
    externalId
    credits
    organization {
      ...OrganizationSimple
      diskUsageStats {
        ...DiskUsageStats
      }
    }
  }
  ${OrganizationSimpleFragmentDoc}
  ${DiskUsageStatsFragmentDoc}
`;
export const UserPurchasesFragmentDoc = gql`
  fragment UserPurchases on User {
    purchases {
      edges {
        node {
          ...PurchaseSimple
        }
      }
    }
  }
  ${PurchaseSimpleFragmentDoc}
`;
export const VersionFragmentDoc = gql`
  fragment Version on Version {
    id
    createdAt
    user {
      ...User
    }
    comment
  }
  ${UserFragmentDoc}
`;
export const ConstructionElementCreateDocument = gql`
  mutation constructionElementCreate($input: ConstructionElementCreateInput!) {
    constructionElement {
      create(input: $input) {
        clientMutationId
        constructionElement {
          ...ConstructionElement
          documentationRecord {
            ...DocumentationRecord
          }
        }
      }
    }
  }
  ${ConstructionElementFragmentDoc}
  ${DocumentationRecordFragmentDoc}
`;

/**
 * __useConstructionElementCreateMutation__
 *
 * To run a mutation, you first call `useConstructionElementCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionElementCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionElementCreateMutation, { data, loading, error }] = useConstructionElementCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionElementCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionElementCreateMutation,
    ConstructionElementCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionElementCreateMutation, ConstructionElementCreateMutationVariables>(
    ConstructionElementCreateDocument,
    options
  );
}
export type ConstructionElementCreateMutationHookResult = ReturnType<typeof useConstructionElementCreateMutation>;
export type ConstructionElementCreateMutationResult =
  ApolloReactCommon.MutationResult<ConstructionElementCreateMutation>;
export type ConstructionElementCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionElementCreateMutation,
  ConstructionElementCreateMutationVariables
>;
export const ConstructionElementDeleteDocument = gql`
  mutation constructionElementDelete($input: ConstructionElementDeleteInput!) {
    constructionElement {
      delete(input: $input) {
        clientMutationId
        constructionElement {
          ...ConstructionElement
          documentationRecord {
            ...DocumentationRecord
          }
        }
      }
    }
  }
  ${ConstructionElementFragmentDoc}
  ${DocumentationRecordFragmentDoc}
`;

/**
 * __useConstructionElementDeleteMutation__
 *
 * To run a mutation, you first call `useConstructionElementDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionElementDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionElementDeleteMutation, { data, loading, error }] = useConstructionElementDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionElementDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionElementDeleteMutation,
    ConstructionElementDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionElementDeleteMutation, ConstructionElementDeleteMutationVariables>(
    ConstructionElementDeleteDocument,
    options
  );
}
export type ConstructionElementDeleteMutationHookResult = ReturnType<typeof useConstructionElementDeleteMutation>;
export type ConstructionElementDeleteMutationResult =
  ApolloReactCommon.MutationResult<ConstructionElementDeleteMutation>;
export type ConstructionElementDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionElementDeleteMutation,
  ConstructionElementDeleteMutationVariables
>;
export const ConstructionElementUpdateDocument = gql`
  mutation constructionElementUpdate($input: ConstructionElementUpdateInput!) {
    constructionElement {
      update(input: $input) {
        clientMutationId
        constructionElement {
          ...ConstructionElement
          documentationRecord {
            ...DocumentationRecord
          }
        }
      }
    }
  }
  ${ConstructionElementFragmentDoc}
  ${DocumentationRecordFragmentDoc}
`;

/**
 * __useConstructionElementUpdateMutation__
 *
 * To run a mutation, you first call `useConstructionElementUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionElementUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionElementUpdateMutation, { data, loading, error }] = useConstructionElementUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionElementUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionElementUpdateMutation,
    ConstructionElementUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionElementUpdateMutation, ConstructionElementUpdateMutationVariables>(
    ConstructionElementUpdateDocument,
    options
  );
}
export type ConstructionElementUpdateMutationHookResult = ReturnType<typeof useConstructionElementUpdateMutation>;
export type ConstructionElementUpdateMutationResult =
  ApolloReactCommon.MutationResult<ConstructionElementUpdateMutation>;
export type ConstructionElementUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionElementUpdateMutation,
  ConstructionElementUpdateMutationVariables
>;
export const ConstructionObjectCreateDocument = gql`
  mutation constructionObjectCreate($input: ConstructionObjectCreateInput!) {
    constructionObject {
      create(input: $input) {
        clientMutationId
        constructionObject {
          id
          name
        }
        constructionSite {
          constructionObjects(first: 1) {
            totalCount
          }
        }
      }
    }
  }
`;

/**
 * __useConstructionObjectCreateMutation__
 *
 * To run a mutation, you first call `useConstructionObjectCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionObjectCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionObjectCreateMutation, { data, loading, error }] = useConstructionObjectCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionObjectCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionObjectCreateMutation,
    ConstructionObjectCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionObjectCreateMutation, ConstructionObjectCreateMutationVariables>(
    ConstructionObjectCreateDocument,
    options
  );
}
export type ConstructionObjectCreateMutationHookResult = ReturnType<typeof useConstructionObjectCreateMutation>;
export type ConstructionObjectCreateMutationResult = ApolloReactCommon.MutationResult<ConstructionObjectCreateMutation>;
export type ConstructionObjectCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionObjectCreateMutation,
  ConstructionObjectCreateMutationVariables
>;
export const ConstructionObjectDeleteDocument = gql`
  mutation constructionObjectDelete($input: ConstructionObjectDeleteInput!) {
    constructionObject {
      delete(input: $input) {
        clientMutationId
        archived
        constructionObject {
          id
          name
        }
        constructionSite {
          constructionObjects(first: 1) {
            totalCount
          }
        }
        failedRecords {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useConstructionObjectDeleteMutation__
 *
 * To run a mutation, you first call `useConstructionObjectDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionObjectDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionObjectDeleteMutation, { data, loading, error }] = useConstructionObjectDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionObjectDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionObjectDeleteMutation,
    ConstructionObjectDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionObjectDeleteMutation, ConstructionObjectDeleteMutationVariables>(
    ConstructionObjectDeleteDocument,
    options
  );
}
export type ConstructionObjectDeleteMutationHookResult = ReturnType<typeof useConstructionObjectDeleteMutation>;
export type ConstructionObjectDeleteMutationResult = ApolloReactCommon.MutationResult<ConstructionObjectDeleteMutation>;
export type ConstructionObjectDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionObjectDeleteMutation,
  ConstructionObjectDeleteMutationVariables
>;
export const ConstructionObjectUpdateDocument = gql`
  mutation constructionObjectUpdate($input: ConstructionObjectUpdateInput!) {
    constructionObject {
      update(input: $input) {
        clientMutationId
        constructionObject {
          id
          name
          versions(last: 1) {
            edges {
              node {
                id
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useConstructionObjectUpdateMutation__
 *
 * To run a mutation, you first call `useConstructionObjectUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionObjectUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionObjectUpdateMutation, { data, loading, error }] = useConstructionObjectUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionObjectUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionObjectUpdateMutation,
    ConstructionObjectUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionObjectUpdateMutation, ConstructionObjectUpdateMutationVariables>(
    ConstructionObjectUpdateDocument,
    options
  );
}
export type ConstructionObjectUpdateMutationHookResult = ReturnType<typeof useConstructionObjectUpdateMutation>;
export type ConstructionObjectUpdateMutationResult = ApolloReactCommon.MutationResult<ConstructionObjectUpdateMutation>;
export type ConstructionObjectUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionObjectUpdateMutation,
  ConstructionObjectUpdateMutationVariables
>;
export const ConstructionPhaseCreateDocument = gql`
  mutation constructionPhaseCreate($input: ConstructionPhaseCreateInput!) {
    constructionPhase {
      create(input: $input) {
        clientMutationId
        constructionPhase {
          id
        }
      }
    }
  }
`;

/**
 * __useConstructionPhaseCreateMutation__
 *
 * To run a mutation, you first call `useConstructionPhaseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionPhaseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionPhaseCreateMutation, { data, loading, error }] = useConstructionPhaseCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionPhaseCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionPhaseCreateMutation,
    ConstructionPhaseCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionPhaseCreateMutation, ConstructionPhaseCreateMutationVariables>(
    ConstructionPhaseCreateDocument,
    options
  );
}
export type ConstructionPhaseCreateMutationHookResult = ReturnType<typeof useConstructionPhaseCreateMutation>;
export type ConstructionPhaseCreateMutationResult = ApolloReactCommon.MutationResult<ConstructionPhaseCreateMutation>;
export type ConstructionPhaseCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionPhaseCreateMutation,
  ConstructionPhaseCreateMutationVariables
>;
export const ConstructionPhaseDeleteDocument = gql`
  mutation constructionPhaseDelete($input: ConstructionPhaseDeleteInput!) {
    constructionPhase {
      delete(input: $input) {
        clientMutationId
        archived
        constructionPhase {
          id
          name
        }
        failedRecords {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useConstructionPhaseDeleteMutation__
 *
 * To run a mutation, you first call `useConstructionPhaseDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionPhaseDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionPhaseDeleteMutation, { data, loading, error }] = useConstructionPhaseDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionPhaseDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionPhaseDeleteMutation,
    ConstructionPhaseDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionPhaseDeleteMutation, ConstructionPhaseDeleteMutationVariables>(
    ConstructionPhaseDeleteDocument,
    options
  );
}
export type ConstructionPhaseDeleteMutationHookResult = ReturnType<typeof useConstructionPhaseDeleteMutation>;
export type ConstructionPhaseDeleteMutationResult = ApolloReactCommon.MutationResult<ConstructionPhaseDeleteMutation>;
export type ConstructionPhaseDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionPhaseDeleteMutation,
  ConstructionPhaseDeleteMutationVariables
>;
export const ConstructionPhaseMoveDocument = gql`
  mutation constructionPhaseMove($input: ConstructionPhaseMoveInput!) {
    constructionPhase {
      move(input: $input) {
        clientMutationId
        constructionPhase {
          id
          order
        }
      }
    }
  }
`;

/**
 * __useConstructionPhaseMoveMutation__
 *
 * To run a mutation, you first call `useConstructionPhaseMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionPhaseMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionPhaseMoveMutation, { data, loading, error }] = useConstructionPhaseMoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionPhaseMoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionPhaseMoveMutation,
    ConstructionPhaseMoveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionPhaseMoveMutation, ConstructionPhaseMoveMutationVariables>(
    ConstructionPhaseMoveDocument,
    options
  );
}
export type ConstructionPhaseMoveMutationHookResult = ReturnType<typeof useConstructionPhaseMoveMutation>;
export type ConstructionPhaseMoveMutationResult = ApolloReactCommon.MutationResult<ConstructionPhaseMoveMutation>;
export type ConstructionPhaseMoveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionPhaseMoveMutation,
  ConstructionPhaseMoveMutationVariables
>;
export const ConstructionPhaseUpdateDocument = gql`
  mutation constructionPhaseUpdate($input: ConstructionPhaseUpdateInput!) {
    constructionPhase {
      update(input: $input) {
        clientMutationId
        constructionPhase {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useConstructionPhaseUpdateMutation__
 *
 * To run a mutation, you first call `useConstructionPhaseUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionPhaseUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionPhaseUpdateMutation, { data, loading, error }] = useConstructionPhaseUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionPhaseUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionPhaseUpdateMutation,
    ConstructionPhaseUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionPhaseUpdateMutation, ConstructionPhaseUpdateMutationVariables>(
    ConstructionPhaseUpdateDocument,
    options
  );
}
export type ConstructionPhaseUpdateMutationHookResult = ReturnType<typeof useConstructionPhaseUpdateMutation>;
export type ConstructionPhaseUpdateMutationResult = ApolloReactCommon.MutationResult<ConstructionPhaseUpdateMutation>;
export type ConstructionPhaseUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionPhaseUpdateMutation,
  ConstructionPhaseUpdateMutationVariables
>;
export const ConstructionSiteCreateDocument = gql`
  mutation constructionSiteCreate($input: ConstructionSiteCreateInput!) {
    constructionSite {
      create(input: $input) {
        clientMutationId
        constructionSite {
          id
        }
      }
    }
  }
`;

/**
 * __useConstructionSiteCreateMutation__
 *
 * To run a mutation, you first call `useConstructionSiteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionSiteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionSiteCreateMutation, { data, loading, error }] = useConstructionSiteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionSiteCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionSiteCreateMutation,
    ConstructionSiteCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionSiteCreateMutation, ConstructionSiteCreateMutationVariables>(
    ConstructionSiteCreateDocument,
    options
  );
}
export type ConstructionSiteCreateMutationHookResult = ReturnType<typeof useConstructionSiteCreateMutation>;
export type ConstructionSiteCreateMutationResult = ApolloReactCommon.MutationResult<ConstructionSiteCreateMutation>;
export type ConstructionSiteCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionSiteCreateMutation,
  ConstructionSiteCreateMutationVariables
>;
export const ConstructionSiteDeleteDocument = gql`
  mutation constructionSiteDelete($input: ConstructionSiteDeleteInput!) {
    constructionSite {
      delete(input: $input) {
        organization {
          ...Organization
        }
        constructionSite {
          id
          name
          archivedAt
          createdRecords
          workInProgressRecords
          completedRecords
          constructionObjects(first: 1) {
            totalCount
          }
          constructionPhases(first: 1) {
            totalCount
          }
        }
        archived
        failedObjects {
          id
          name
          createdAt
          archivedAt
          createdRecords
          workInProgressRecords
          completedRecords
        }
        failedPhases {
          id
          name
          order
          createdAt
          archivedAt
          createdRecords
          workInProgressRecords
          completedRecords
        }
        clientMutationId
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useConstructionSiteDeleteMutation__
 *
 * To run a mutation, you first call `useConstructionSiteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionSiteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionSiteDeleteMutation, { data, loading, error }] = useConstructionSiteDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionSiteDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionSiteDeleteMutation,
    ConstructionSiteDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionSiteDeleteMutation, ConstructionSiteDeleteMutationVariables>(
    ConstructionSiteDeleteDocument,
    options
  );
}
export type ConstructionSiteDeleteMutationHookResult = ReturnType<typeof useConstructionSiteDeleteMutation>;
export type ConstructionSiteDeleteMutationResult = ApolloReactCommon.MutationResult<ConstructionSiteDeleteMutation>;
export type ConstructionSiteDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionSiteDeleteMutation,
  ConstructionSiteDeleteMutationVariables
>;
export const ConstructionSiteUpdateDocument = gql`
  mutation constructionSiteUpdate($input: ConstructionSiteUpdateInput!) {
    constructionSite {
      update(input: $input) {
        clientMutationId
        constructionSite {
          id
          name
          description
        }
      }
    }
  }
`;

/**
 * __useConstructionSiteUpdateMutation__
 *
 * To run a mutation, you first call `useConstructionSiteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConstructionSiteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [constructionSiteUpdateMutation, { data, loading, error }] = useConstructionSiteUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConstructionSiteUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConstructionSiteUpdateMutation,
    ConstructionSiteUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConstructionSiteUpdateMutation, ConstructionSiteUpdateMutationVariables>(
    ConstructionSiteUpdateDocument,
    options
  );
}
export type ConstructionSiteUpdateMutationHookResult = ReturnType<typeof useConstructionSiteUpdateMutation>;
export type ConstructionSiteUpdateMutationResult = ApolloReactCommon.MutationResult<ConstructionSiteUpdateMutation>;
export type ConstructionSiteUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConstructionSiteUpdateMutation,
  ConstructionSiteUpdateMutationVariables
>;
export const DocumentationRecordAssignThreeDOperatorDocument = gql`
  mutation documentationRecordAssignThreeDOperator($input: DocumentationRecordAssignThreeDOperatorInput!) {
    documentationRecord {
      assignThreeDOperator(input: $input) {
        clientMutationId
        documentationRecord {
          id
        }
      }
    }
  }
`;

/**
 * __useDocumentationRecordAssignThreeDOperatorMutation__
 *
 * To run a mutation, you first call `useDocumentationRecordAssignThreeDOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordAssignThreeDOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentationRecordAssignThreeDOperatorMutation, { data, loading, error }] = useDocumentationRecordAssignThreeDOperatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentationRecordAssignThreeDOperatorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DocumentationRecordAssignThreeDOperatorMutation,
    DocumentationRecordAssignThreeDOperatorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DocumentationRecordAssignThreeDOperatorMutation,
    DocumentationRecordAssignThreeDOperatorMutationVariables
  >(DocumentationRecordAssignThreeDOperatorDocument, options);
}
export type DocumentationRecordAssignThreeDOperatorMutationHookResult = ReturnType<
  typeof useDocumentationRecordAssignThreeDOperatorMutation
>;
export type DocumentationRecordAssignThreeDOperatorMutationResult =
  ApolloReactCommon.MutationResult<DocumentationRecordAssignThreeDOperatorMutation>;
export type DocumentationRecordAssignThreeDOperatorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DocumentationRecordAssignThreeDOperatorMutation,
  DocumentationRecordAssignThreeDOperatorMutationVariables
>;
export const DocumentationRecordCreateDocument = gql`
  mutation documentationRecordCreate($input: DocumentationRecordCreateInput!) {
    documentationRecord {
      create(input: $input) {
        clientMutationId
        documentationRecord {
          ...DocumentationRecord
        }
      }
    }
  }
  ${DocumentationRecordFragmentDoc}
`;

/**
 * __useDocumentationRecordCreateMutation__
 *
 * To run a mutation, you first call `useDocumentationRecordCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentationRecordCreateMutation, { data, loading, error }] = useDocumentationRecordCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentationRecordCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DocumentationRecordCreateMutation,
    DocumentationRecordCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DocumentationRecordCreateMutation, DocumentationRecordCreateMutationVariables>(
    DocumentationRecordCreateDocument,
    options
  );
}
export type DocumentationRecordCreateMutationHookResult = ReturnType<typeof useDocumentationRecordCreateMutation>;
export type DocumentationRecordCreateMutationResult =
  ApolloReactCommon.MutationResult<DocumentationRecordCreateMutation>;
export type DocumentationRecordCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DocumentationRecordCreateMutation,
  DocumentationRecordCreateMutationVariables
>;
export const DocumentationRecordDeleteDocument = gql`
  mutation documentationRecordDelete($input: DocumentationRecordDeleteInput!) {
    documentationRecord {
      delete(input: $input) {
        clientMutationId
        documentationRecord {
          ...DocumentationRecord
          constructionObject {
            id
            constructionSite {
              id
              organization {
                ...Organization
              }
            }
          }
        }
      }
    }
  }
  ${DocumentationRecordFragmentDoc}
  ${OrganizationFragmentDoc}
`;

/**
 * __useDocumentationRecordDeleteMutation__
 *
 * To run a mutation, you first call `useDocumentationRecordDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentationRecordDeleteMutation, { data, loading, error }] = useDocumentationRecordDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentationRecordDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DocumentationRecordDeleteMutation,
    DocumentationRecordDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DocumentationRecordDeleteMutation, DocumentationRecordDeleteMutationVariables>(
    DocumentationRecordDeleteDocument,
    options
  );
}
export type DocumentationRecordDeleteMutationHookResult = ReturnType<typeof useDocumentationRecordDeleteMutation>;
export type DocumentationRecordDeleteMutationResult =
  ApolloReactCommon.MutationResult<DocumentationRecordDeleteMutation>;
export type DocumentationRecordDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DocumentationRecordDeleteMutation,
  DocumentationRecordDeleteMutationVariables
>;
export const DocumentationRecordRelocateDocument = gql`
  mutation documentationRecordRelocate($input: DocumentationRecordRelocateInput!) {
    documentationRecord {
      relocate(input: $input) {
        documentationRecord {
          ...DocumentationRecord
        }
      }
    }
  }
  ${DocumentationRecordFragmentDoc}
`;

/**
 * __useDocumentationRecordRelocateMutation__
 *
 * To run a mutation, you first call `useDocumentationRecordRelocateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordRelocateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentationRecordRelocateMutation, { data, loading, error }] = useDocumentationRecordRelocateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentationRecordRelocateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DocumentationRecordRelocateMutation,
    DocumentationRecordRelocateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DocumentationRecordRelocateMutation,
    DocumentationRecordRelocateMutationVariables
  >(DocumentationRecordRelocateDocument, options);
}
export type DocumentationRecordRelocateMutationHookResult = ReturnType<typeof useDocumentationRecordRelocateMutation>;
export type DocumentationRecordRelocateMutationResult =
  ApolloReactCommon.MutationResult<DocumentationRecordRelocateMutation>;
export type DocumentationRecordRelocateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DocumentationRecordRelocateMutation,
  DocumentationRecordRelocateMutationVariables
>;
export const DocumentationRecordTriggerDocument = gql`
  mutation documentationRecordTrigger($input: DocumentationRecordTriggerInput!) {
    documentationRecord {
      trigger(input: $input) {
        clientMutationId
        documentationRecord {
          ...DocumentationRecord
        }
      }
    }
  }
  ${DocumentationRecordFragmentDoc}
`;

/**
 * __useDocumentationRecordTriggerMutation__
 *
 * To run a mutation, you first call `useDocumentationRecordTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentationRecordTriggerMutation, { data, loading, error }] = useDocumentationRecordTriggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentationRecordTriggerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DocumentationRecordTriggerMutation,
    DocumentationRecordTriggerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DocumentationRecordTriggerMutation, DocumentationRecordTriggerMutationVariables>(
    DocumentationRecordTriggerDocument,
    options
  );
}
export type DocumentationRecordTriggerMutationHookResult = ReturnType<typeof useDocumentationRecordTriggerMutation>;
export type DocumentationRecordTriggerMutationResult =
  ApolloReactCommon.MutationResult<DocumentationRecordTriggerMutation>;
export type DocumentationRecordTriggerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DocumentationRecordTriggerMutation,
  DocumentationRecordTriggerMutationVariables
>;
export const DocumentationRecordUpdateDocument = gql`
  mutation documentationRecordUpdate($input: DocumentationRecordUpdateInput!) {
    documentationRecord {
      update(input: $input) {
        clientMutationId
        documentationRecord {
          ...DocumentationRecord
        }
      }
    }
  }
  ${DocumentationRecordFragmentDoc}
`;

/**
 * __useDocumentationRecordUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentationRecordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentationRecordUpdateMutation, { data, loading, error }] = useDocumentationRecordUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentationRecordUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DocumentationRecordUpdateMutation,
    DocumentationRecordUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DocumentationRecordUpdateMutation, DocumentationRecordUpdateMutationVariables>(
    DocumentationRecordUpdateDocument,
    options
  );
}
export type DocumentationRecordUpdateMutationHookResult = ReturnType<typeof useDocumentationRecordUpdateMutation>;
export type DocumentationRecordUpdateMutationResult =
  ApolloReactCommon.MutationResult<DocumentationRecordUpdateMutation>;
export type DocumentationRecordUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DocumentationRecordUpdateMutation,
  DocumentationRecordUpdateMutationVariables
>;
export const GroundControlPointDocumentationCreateDocument = gql`
  mutation groundControlPointDocumentationCreate($input: GroundControlPointDocumentationCreateInput!) {
    groundControlPointDocumentation {
      create(input: $input) {
        clientMutationId
        groundControlPointDocumentation {
          ...GroundControlPointDocumentation
          documentationRecord {
            id
            triggers {
              ...Trigger
            }
          }
        }
      }
    }
  }
  ${GroundControlPointDocumentationFragmentDoc}
  ${TriggerFragmentDoc}
`;

/**
 * __useGroundControlPointDocumentationCreateMutation__
 *
 * To run a mutation, you first call `useGroundControlPointDocumentationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroundControlPointDocumentationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groundControlPointDocumentationCreateMutation, { data, loading, error }] = useGroundControlPointDocumentationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroundControlPointDocumentationCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GroundControlPointDocumentationCreateMutation,
    GroundControlPointDocumentationCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GroundControlPointDocumentationCreateMutation,
    GroundControlPointDocumentationCreateMutationVariables
  >(GroundControlPointDocumentationCreateDocument, options);
}
export type GroundControlPointDocumentationCreateMutationHookResult = ReturnType<
  typeof useGroundControlPointDocumentationCreateMutation
>;
export type GroundControlPointDocumentationCreateMutationResult =
  ApolloReactCommon.MutationResult<GroundControlPointDocumentationCreateMutation>;
export type GroundControlPointDocumentationCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GroundControlPointDocumentationCreateMutation,
  GroundControlPointDocumentationCreateMutationVariables
>;
export const GroundControlPointDocumentationDeleteDocument = gql`
  mutation groundControlPointDocumentationDelete($input: GroundControlPointDocumentationDeleteInput!) {
    groundControlPointDocumentation {
      delete(input: $input) {
        clientMutationId
        groundControlPointDocumentation {
          ...GroundControlPointDocumentation
          documentationRecord {
            id
            triggers {
              ...Trigger
            }
          }
        }
      }
    }
  }
  ${GroundControlPointDocumentationFragmentDoc}
  ${TriggerFragmentDoc}
`;

/**
 * __useGroundControlPointDocumentationDeleteMutation__
 *
 * To run a mutation, you first call `useGroundControlPointDocumentationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroundControlPointDocumentationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groundControlPointDocumentationDeleteMutation, { data, loading, error }] = useGroundControlPointDocumentationDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroundControlPointDocumentationDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GroundControlPointDocumentationDeleteMutation,
    GroundControlPointDocumentationDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GroundControlPointDocumentationDeleteMutation,
    GroundControlPointDocumentationDeleteMutationVariables
  >(GroundControlPointDocumentationDeleteDocument, options);
}
export type GroundControlPointDocumentationDeleteMutationHookResult = ReturnType<
  typeof useGroundControlPointDocumentationDeleteMutation
>;
export type GroundControlPointDocumentationDeleteMutationResult =
  ApolloReactCommon.MutationResult<GroundControlPointDocumentationDeleteMutation>;
export type GroundControlPointDocumentationDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GroundControlPointDocumentationDeleteMutation,
  GroundControlPointDocumentationDeleteMutationVariables
>;
export const GroundControlPointDocumentationUpdateDocument = gql`
  mutation groundControlPointDocumentationUpdate($input: GroundControlPointDocumentationUpdateInput!) {
    groundControlPointDocumentation {
      update(input: $input) {
        clientMutationId
        groundControlPointDocumentation {
          ...GroundControlPointDocumentation
          documentationRecord {
            id
            triggers {
              ...Trigger
            }
          }
        }
      }
    }
  }
  ${GroundControlPointDocumentationFragmentDoc}
  ${TriggerFragmentDoc}
`;

/**
 * __useGroundControlPointDocumentationUpdateMutation__
 *
 * To run a mutation, you first call `useGroundControlPointDocumentationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroundControlPointDocumentationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groundControlPointDocumentationUpdateMutation, { data, loading, error }] = useGroundControlPointDocumentationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroundControlPointDocumentationUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GroundControlPointDocumentationUpdateMutation,
    GroundControlPointDocumentationUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GroundControlPointDocumentationUpdateMutation,
    GroundControlPointDocumentationUpdateMutationVariables
  >(GroundControlPointDocumentationUpdateDocument, options);
}
export type GroundControlPointDocumentationUpdateMutationHookResult = ReturnType<
  typeof useGroundControlPointDocumentationUpdateMutation
>;
export type GroundControlPointDocumentationUpdateMutationResult =
  ApolloReactCommon.MutationResult<GroundControlPointDocumentationUpdateMutation>;
export type GroundControlPointDocumentationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GroundControlPointDocumentationUpdateMutation,
  GroundControlPointDocumentationUpdateMutationVariables
>;
export const InquiryResolveDocument = gql`
  mutation inquiryResolve($input: InquiryResolveInput!) {
    inquiry {
      resolve(input: $input) {
        inquiry {
          id
        }
      }
    }
  }
`;

/**
 * __useInquiryResolveMutation__
 *
 * To run a mutation, you first call `useInquiryResolveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquiryResolveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquiryResolveMutation, { data, loading, error }] = useInquiryResolveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInquiryResolveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<InquiryResolveMutation, InquiryResolveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<InquiryResolveMutation, InquiryResolveMutationVariables>(
    InquiryResolveDocument,
    options
  );
}
export type InquiryResolveMutationHookResult = ReturnType<typeof useInquiryResolveMutation>;
export type InquiryResolveMutationResult = ApolloReactCommon.MutationResult<InquiryResolveMutation>;
export type InquiryResolveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InquiryResolveMutation,
  InquiryResolveMutationVariables
>;
export const InquirySendDocument = gql`
  mutation inquirySend($input: InquirySendInput!) {
    inquiry {
      send(input: $input) {
        clientMutationId
      }
    }
  }
`;

/**
 * __useInquirySendMutation__
 *
 * To run a mutation, you first call `useInquirySendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquirySendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquirySendMutation, { data, loading, error }] = useInquirySendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInquirySendMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<InquirySendMutation, InquirySendMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<InquirySendMutation, InquirySendMutationVariables>(InquirySendDocument, options);
}
export type InquirySendMutationHookResult = ReturnType<typeof useInquirySendMutation>;
export type InquirySendMutationResult = ApolloReactCommon.MutationResult<InquirySendMutation>;
export type InquirySendMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InquirySendMutation,
  InquirySendMutationVariables
>;
export const LoginDocument = gql`
  mutation login($input: LoginAuthTokenInput!) {
    login {
      authToken(input: $input) {
        token
        refreshToken
      }
    }
  }
`;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginByCredentialsDocument = gql`
  mutation loginByCredentials($input: LoginCredentialsInput!) {
    login {
      credentials(input: $input) {
        token
        refreshToken
      }
    }
  }
`;

/**
 * __useLoginByCredentialsMutation__
 *
 * To run a mutation, you first call `useLoginByCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByCredentialsMutation, { data, loading, error }] = useLoginByCredentialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginByCredentialsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginByCredentialsMutation, LoginByCredentialsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginByCredentialsMutation, LoginByCredentialsMutationVariables>(
    LoginByCredentialsDocument,
    options
  );
}
export type LoginByCredentialsMutationHookResult = ReturnType<typeof useLoginByCredentialsMutation>;
export type LoginByCredentialsMutationResult = ApolloReactCommon.MutationResult<LoginByCredentialsMutation>;
export type LoginByCredentialsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginByCredentialsMutation,
  LoginByCredentialsMutationVariables
>;
export const MediaCreateDocument = gql`
  mutation mediaCreate($input: MediaCreateInput!) {
    media {
      create(input: $input) {
        clientMutationId
        media {
          ...Media
        }
      }
    }
  }
  ${MediaFragmentDoc}
`;

/**
 * __useMediaCreateMutation__
 *
 * To run a mutation, you first call `useMediaCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaCreateMutation, { data, loading, error }] = useMediaCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMediaCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<MediaCreateMutation, MediaCreateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<MediaCreateMutation, MediaCreateMutationVariables>(MediaCreateDocument, options);
}
export type MediaCreateMutationHookResult = ReturnType<typeof useMediaCreateMutation>;
export type MediaCreateMutationResult = ApolloReactCommon.MutationResult<MediaCreateMutation>;
export type MediaCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MediaCreateMutation,
  MediaCreateMutationVariables
>;
export const NotifyOutputExtensionsCompletedDocument = gql`
  mutation notifyOutputExtensionsCompleted($input: NotifyOutputExtensionsCompletedInput!) {
    outputExtension {
      notifyOutputExtensionsCompleted(input: $input) {
        clientMutationId
        success
      }
    }
  }
`;

/**
 * __useNotifyOutputExtensionsCompletedMutation__
 *
 * To run a mutation, you first call `useNotifyOutputExtensionsCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyOutputExtensionsCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyOutputExtensionsCompletedMutation, { data, loading, error }] = useNotifyOutputExtensionsCompletedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotifyOutputExtensionsCompletedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotifyOutputExtensionsCompletedMutation,
    NotifyOutputExtensionsCompletedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NotifyOutputExtensionsCompletedMutation,
    NotifyOutputExtensionsCompletedMutationVariables
  >(NotifyOutputExtensionsCompletedDocument, options);
}
export type NotifyOutputExtensionsCompletedMutationHookResult = ReturnType<
  typeof useNotifyOutputExtensionsCompletedMutation
>;
export type NotifyOutputExtensionsCompletedMutationResult =
  ApolloReactCommon.MutationResult<NotifyOutputExtensionsCompletedMutation>;
export type NotifyOutputExtensionsCompletedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  NotifyOutputExtensionsCompletedMutation,
  NotifyOutputExtensionsCompletedMutationVariables
>;
export const OrganizationCancelInviteDocument = gql`
  mutation organizationCancelInvite($input: OrganizationCancelInviteInput!) {
    organization {
      cancelInvite(input: $input) {
        clientMutationId
        invite {
          id
          organization {
            ...Organization
          }
          user {
            ...User
          }
        }
      }
    }
  }
  ${OrganizationFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __useOrganizationCancelInviteMutation__
 *
 * To run a mutation, you first call `useOrganizationCancelInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCancelInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCancelInviteMutation, { data, loading, error }] = useOrganizationCancelInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationCancelInviteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationCancelInviteMutation,
    OrganizationCancelInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<OrganizationCancelInviteMutation, OrganizationCancelInviteMutationVariables>(
    OrganizationCancelInviteDocument,
    options
  );
}
export type OrganizationCancelInviteMutationHookResult = ReturnType<typeof useOrganizationCancelInviteMutation>;
export type OrganizationCancelInviteMutationResult = ApolloReactCommon.MutationResult<OrganizationCancelInviteMutation>;
export type OrganizationCancelInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OrganizationCancelInviteMutation,
  OrganizationCancelInviteMutationVariables
>;
export const OrganizationInviteUserDocument = gql`
  mutation organizationInviteUser($input: OrganizationInviteUserInput!) {
    organization {
      inviteUser(input: $input) {
        clientMutationId
        invite {
          user {
            ...User
          }
          organization {
            ...Organization
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${OrganizationFragmentDoc}
`;

/**
 * __useOrganizationInviteUserMutation__
 *
 * To run a mutation, you first call `useOrganizationInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationInviteUserMutation, { data, loading, error }] = useOrganizationInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationInviteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationInviteUserMutation,
    OrganizationInviteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<OrganizationInviteUserMutation, OrganizationInviteUserMutationVariables>(
    OrganizationInviteUserDocument,
    options
  );
}
export type OrganizationInviteUserMutationHookResult = ReturnType<typeof useOrganizationInviteUserMutation>;
export type OrganizationInviteUserMutationResult = ApolloReactCommon.MutationResult<OrganizationInviteUserMutation>;
export type OrganizationInviteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OrganizationInviteUserMutation,
  OrganizationInviteUserMutationVariables
>;
export const OrganizationLeaveDocument = gql`
  mutation organizationLeave($input: OrganizationLeaveInput!) {
    organization {
      leave(input: $input) {
        clientMutationId
        user {
          ...User
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useOrganizationLeaveMutation__
 *
 * To run a mutation, you first call `useOrganizationLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationLeaveMutation, { data, loading, error }] = useOrganizationLeaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationLeaveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationLeaveMutation, OrganizationLeaveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<OrganizationLeaveMutation, OrganizationLeaveMutationVariables>(
    OrganizationLeaveDocument,
    options
  );
}
export type OrganizationLeaveMutationHookResult = ReturnType<typeof useOrganizationLeaveMutation>;
export type OrganizationLeaveMutationResult = ApolloReactCommon.MutationResult<OrganizationLeaveMutation>;
export type OrganizationLeaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OrganizationLeaveMutation,
  OrganizationLeaveMutationVariables
>;
export const OrganizationLogEventDocument = gql`
  mutation organizationLogEvent($input: OrganizationLogEventInput!) {
    organization {
      logEvent(input: $input) {
        clientMutationId
        success
      }
    }
  }
`;

/**
 * __useOrganizationLogEventMutation__
 *
 * To run a mutation, you first call `useOrganizationLogEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLogEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationLogEventMutation, { data, loading, error }] = useOrganizationLogEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationLogEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationLogEventMutation,
    OrganizationLogEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<OrganizationLogEventMutation, OrganizationLogEventMutationVariables>(
    OrganizationLogEventDocument,
    options
  );
}
export type OrganizationLogEventMutationHookResult = ReturnType<typeof useOrganizationLogEventMutation>;
export type OrganizationLogEventMutationResult = ApolloReactCommon.MutationResult<OrganizationLogEventMutation>;
export type OrganizationLogEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OrganizationLogEventMutation,
  OrganizationLogEventMutationVariables
>;
export const OrganizationRemoveUserDocument = gql`
  mutation organizationRemoveUser($input: OrganizationRemoveUserInput!) {
    organization {
      removeUser(input: $input) {
        clientMutationId
        organization {
          ...Organization
        }
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useOrganizationRemoveUserMutation__
 *
 * To run a mutation, you first call `useOrganizationRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationRemoveUserMutation, { data, loading, error }] = useOrganizationRemoveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationRemoveUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationRemoveUserMutation,
    OrganizationRemoveUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<OrganizationRemoveUserMutation, OrganizationRemoveUserMutationVariables>(
    OrganizationRemoveUserDocument,
    options
  );
}
export type OrganizationRemoveUserMutationHookResult = ReturnType<typeof useOrganizationRemoveUserMutation>;
export type OrganizationRemoveUserMutationResult = ApolloReactCommon.MutationResult<OrganizationRemoveUserMutation>;
export type OrganizationRemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OrganizationRemoveUserMutation,
  OrganizationRemoveUserMutationVariables
>;
export const OrganizationResolveInviteDocument = gql`
  mutation organizationResolveInvite($input: OrganizationResolveInviteInput!) {
    organization {
      resolveInvite(input: $input) {
        clientMutationId
        invite {
          id
          organization {
            ...Organization
          }
          user {
            ...User
            ...UserOrganizationMemberships
            ...UserInvitations
          }
        }
      }
    }
  }
  ${OrganizationFragmentDoc}
  ${UserFragmentDoc}
  ${UserOrganizationMembershipsFragmentDoc}
  ${UserInvitationsFragmentDoc}
`;

/**
 * __useOrganizationResolveInviteMutation__
 *
 * To run a mutation, you first call `useOrganizationResolveInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationResolveInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationResolveInviteMutation, { data, loading, error }] = useOrganizationResolveInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationResolveInviteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationResolveInviteMutation,
    OrganizationResolveInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<OrganizationResolveInviteMutation, OrganizationResolveInviteMutationVariables>(
    OrganizationResolveInviteDocument,
    options
  );
}
export type OrganizationResolveInviteMutationHookResult = ReturnType<typeof useOrganizationResolveInviteMutation>;
export type OrganizationResolveInviteMutationResult =
  ApolloReactCommon.MutationResult<OrganizationResolveInviteMutation>;
export type OrganizationResolveInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OrganizationResolveInviteMutation,
  OrganizationResolveInviteMutationVariables
>;
export const OutputExtensionDeleteDocument = gql`
  mutation outputExtensionDelete($input: OutputExtensionDeleteInput!) {
    outputExtension {
      delete(input: $input) {
        clientMutationId
        success
      }
    }
  }
`;

/**
 * __useOutputExtensionDeleteMutation__
 *
 * To run a mutation, you first call `useOutputExtensionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOutputExtensionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [outputExtensionDeleteMutation, { data, loading, error }] = useOutputExtensionDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOutputExtensionDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OutputExtensionDeleteMutation,
    OutputExtensionDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<OutputExtensionDeleteMutation, OutputExtensionDeleteMutationVariables>(
    OutputExtensionDeleteDocument,
    options
  );
}
export type OutputExtensionDeleteMutationHookResult = ReturnType<typeof useOutputExtensionDeleteMutation>;
export type OutputExtensionDeleteMutationResult = ApolloReactCommon.MutationResult<OutputExtensionDeleteMutation>;
export type OutputExtensionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OutputExtensionDeleteMutation,
  OutputExtensionDeleteMutationVariables
>;
export const OutputExtensionsUpdateDocument = gql`
  mutation outputExtensionsUpdate($input: OutputExtensionUpdateInput!) {
    outputExtension {
      update(input: $input) {
        clientMutationId
        outputExtension {
          id
          comment
          createdAt
          files {
            edges {
              node {
                ...Media
              }
            }
          }
        }
      }
    }
  }
  ${MediaFragmentDoc}
`;

/**
 * __useOutputExtensionsUpdateMutation__
 *
 * To run a mutation, you first call `useOutputExtensionsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOutputExtensionsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [outputExtensionsUpdateMutation, { data, loading, error }] = useOutputExtensionsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOutputExtensionsUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OutputExtensionsUpdateMutation,
    OutputExtensionsUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<OutputExtensionsUpdateMutation, OutputExtensionsUpdateMutationVariables>(
    OutputExtensionsUpdateDocument,
    options
  );
}
export type OutputExtensionsUpdateMutationHookResult = ReturnType<typeof useOutputExtensionsUpdateMutation>;
export type OutputExtensionsUpdateMutationResult = ApolloReactCommon.MutationResult<OutputExtensionsUpdateMutation>;
export type OutputExtensionsUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OutputExtensionsUpdateMutation,
  OutputExtensionsUpdateMutationVariables
>;
export const PurchaseDocument = gql`
  mutation purchase($input: PurchaseClaimInput!) {
    purchase {
      claim(input: $input) {
        purchase {
          ...PurchaseSimple
          user {
            ...User
            ...UserPurchases
          }
        }
      }
    }
  }
  ${PurchaseSimpleFragmentDoc}
  ${UserFragmentDoc}
  ${UserPurchasesFragmentDoc}
`;

/**
 * __usePurchaseMutation__
 *
 * To run a mutation, you first call `usePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseMutation, { data, loading, error }] = usePurchaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<PurchaseMutation, PurchaseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<PurchaseMutation, PurchaseMutationVariables>(PurchaseDocument, options);
}
export type PurchaseMutationHookResult = ReturnType<typeof usePurchaseMutation>;
export type PurchaseMutationResult = ApolloReactCommon.MutationResult<PurchaseMutation>;
export type PurchaseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PurchaseMutation,
  PurchaseMutationVariables
>;
export const RefreshPayloadDocument = gql`
  mutation refreshPayload($input: RefreshInput!) {
    refreshToken(input: $input) {
      payload
      refreshToken
      token
    }
  }
`;

/**
 * __useRefreshPayloadMutation__
 *
 * To run a mutation, you first call `useRefreshPayloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshPayloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshPayloadMutation, { data, loading, error }] = useRefreshPayloadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshPayloadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshPayloadMutation, RefreshPayloadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RefreshPayloadMutation, RefreshPayloadMutationVariables>(
    RefreshPayloadDocument,
    options
  );
}
export type RefreshPayloadMutationHookResult = ReturnType<typeof useRefreshPayloadMutation>;
export type RefreshPayloadMutationResult = ApolloReactCommon.MutationResult<RefreshPayloadMutation>;
export type RefreshPayloadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RefreshPayloadMutation,
  RefreshPayloadMutationVariables
>;
export const RequestThreeDModelDownloadLinkDocument = gql`
  mutation requestThreeDModelDownloadLink($input: RequestDownloadLinkInput!) {
    threeDModel {
      requestDownloadLink(input: $input) {
        clientMutationId
        url
      }
    }
  }
`;

/**
 * __useRequestThreeDModelDownloadLinkMutation__
 *
 * To run a mutation, you first call `useRequestThreeDModelDownloadLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestThreeDModelDownloadLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestThreeDModelDownloadLinkMutation, { data, loading, error }] = useRequestThreeDModelDownloadLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestThreeDModelDownloadLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestThreeDModelDownloadLinkMutation,
    RequestThreeDModelDownloadLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RequestThreeDModelDownloadLinkMutation,
    RequestThreeDModelDownloadLinkMutationVariables
  >(RequestThreeDModelDownloadLinkDocument, options);
}
export type RequestThreeDModelDownloadLinkMutationHookResult = ReturnType<
  typeof useRequestThreeDModelDownloadLinkMutation
>;
export type RequestThreeDModelDownloadLinkMutationResult =
  ApolloReactCommon.MutationResult<RequestThreeDModelDownloadLinkMutation>;
export type RequestThreeDModelDownloadLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestThreeDModelDownloadLinkMutation,
  RequestThreeDModelDownloadLinkMutationVariables
>;
export const SendInquiryDocument = gql`
  mutation sendInquiry($input: InquirySendInput!) {
    inquiry {
      send(input: $input) {
        inquiry {
          id
        }
      }
    }
  }
`;

/**
 * __useSendInquiryMutation__
 *
 * To run a mutation, you first call `useSendInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInquiryMutation, { data, loading, error }] = useSendInquiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInquiryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SendInquiryMutation, SendInquiryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SendInquiryMutation, SendInquiryMutationVariables>(SendInquiryDocument, options);
}
export type SendInquiryMutationHookResult = ReturnType<typeof useSendInquiryMutation>;
export type SendInquiryMutationResult = ApolloReactCommon.MutationResult<SendInquiryMutation>;
export type SendInquiryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendInquiryMutation,
  SendInquiryMutationVariables
>;
export const SetLanguageDocument = gql`
  mutation setLanguage($input: SetLanguageInput!) {
    organization {
      setLanguage(input: $input) {
        user {
          ...User
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useSetLanguageMutation__
 *
 * To run a mutation, you first call `useSetLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLanguageMutation, { data, loading, error }] = useSetLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLanguageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SetLanguageMutation, SetLanguageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SetLanguageMutation, SetLanguageMutationVariables>(SetLanguageDocument, options);
}
export type SetLanguageMutationHookResult = ReturnType<typeof useSetLanguageMutation>;
export type SetLanguageMutationResult = ApolloReactCommon.MutationResult<SetLanguageMutation>;
export type SetLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetLanguageMutation,
  SetLanguageMutationVariables
>;
export const SetSimplifiedGroundControlPointDocument = gql`
  mutation setSimplifiedGroundControlPoint($input: GroundControlPointDocumentationSetSimplifiedInput!) {
    groundControlPointDocumentation {
      setSimplified(input: $input) {
        clientMutationId
        documentationRecord {
          id
          triggers {
            ...Trigger
          }
          simpleGroundControlPointCount
          simpleGroundControlPoint {
            ...SimpleGroundControlPoint
          }
        }
      }
    }
  }
  ${TriggerFragmentDoc}
  ${SimpleGroundControlPointFragmentDoc}
`;

/**
 * __useSetSimplifiedGroundControlPointMutation__
 *
 * To run a mutation, you first call `useSetSimplifiedGroundControlPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSimplifiedGroundControlPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSimplifiedGroundControlPointMutation, { data, loading, error }] = useSetSimplifiedGroundControlPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSimplifiedGroundControlPointMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetSimplifiedGroundControlPointMutation,
    SetSimplifiedGroundControlPointMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetSimplifiedGroundControlPointMutation,
    SetSimplifiedGroundControlPointMutationVariables
  >(SetSimplifiedGroundControlPointDocument, options);
}
export type SetSimplifiedGroundControlPointMutationHookResult = ReturnType<
  typeof useSetSimplifiedGroundControlPointMutation
>;
export type SetSimplifiedGroundControlPointMutationResult =
  ApolloReactCommon.MutationResult<SetSimplifiedGroundControlPointMutation>;
export type SetSimplifiedGroundControlPointMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetSimplifiedGroundControlPointMutation,
  SetSimplifiedGroundControlPointMutationVariables
>;
export const ThreeDModelDeleteDocument = gql`
  mutation threeDModelDelete($input: ThreeDModelDeleteInput!) {
    threeDModel {
      delete(input: $input) {
        clientMutationId
        threeDModel {
          ...ThreeDModel
        }
      }
    }
  }
  ${ThreeDModelFragmentDoc}
`;

/**
 * __useThreeDModelDeleteMutation__
 *
 * To run a mutation, you first call `useThreeDModelDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThreeDModelDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [threeDModelDeleteMutation, { data, loading, error }] = useThreeDModelDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThreeDModelDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ThreeDModelDeleteMutation, ThreeDModelDeleteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ThreeDModelDeleteMutation, ThreeDModelDeleteMutationVariables>(
    ThreeDModelDeleteDocument,
    options
  );
}
export type ThreeDModelDeleteMutationHookResult = ReturnType<typeof useThreeDModelDeleteMutation>;
export type ThreeDModelDeleteMutationResult = ApolloReactCommon.MutationResult<ThreeDModelDeleteMutation>;
export type ThreeDModelDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ThreeDModelDeleteMutation,
  ThreeDModelDeleteMutationVariables
>;
export const ThreeDModelRevokeSharesDocument = gql`
  mutation threeDModelRevokeShares($input: ThreeDModelRevokeSharesInput!) {
    threeDModel {
      revokeShares(input: $input) {
        clientMutationId
        threeDModel {
          ...ThreeDModel
        }
      }
    }
  }
  ${ThreeDModelFragmentDoc}
`;

/**
 * __useThreeDModelRevokeSharesMutation__
 *
 * To run a mutation, you first call `useThreeDModelRevokeSharesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThreeDModelRevokeSharesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [threeDModelRevokeSharesMutation, { data, loading, error }] = useThreeDModelRevokeSharesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThreeDModelRevokeSharesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ThreeDModelRevokeSharesMutation,
    ThreeDModelRevokeSharesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ThreeDModelRevokeSharesMutation, ThreeDModelRevokeSharesMutationVariables>(
    ThreeDModelRevokeSharesDocument,
    options
  );
}
export type ThreeDModelRevokeSharesMutationHookResult = ReturnType<typeof useThreeDModelRevokeSharesMutation>;
export type ThreeDModelRevokeSharesMutationResult = ApolloReactCommon.MutationResult<ThreeDModelRevokeSharesMutation>;
export type ThreeDModelRevokeSharesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ThreeDModelRevokeSharesMutation,
  ThreeDModelRevokeSharesMutationVariables
>;
export const ThreeDModelSendShareDocument = gql`
  mutation threeDModelSendShare($input: ThreeDModelSendShareInput!) {
    threeDModel {
      sendShare(input: $input) {
        clientMutationId
        sent
      }
    }
  }
`;

/**
 * __useThreeDModelSendShareMutation__
 *
 * To run a mutation, you first call `useThreeDModelSendShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThreeDModelSendShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [threeDModelSendShareMutation, { data, loading, error }] = useThreeDModelSendShareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThreeDModelSendShareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ThreeDModelSendShareMutation,
    ThreeDModelSendShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ThreeDModelSendShareMutation, ThreeDModelSendShareMutationVariables>(
    ThreeDModelSendShareDocument,
    options
  );
}
export type ThreeDModelSendShareMutationHookResult = ReturnType<typeof useThreeDModelSendShareMutation>;
export type ThreeDModelSendShareMutationResult = ApolloReactCommon.MutationResult<ThreeDModelSendShareMutation>;
export type ThreeDModelSendShareMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ThreeDModelSendShareMutation,
  ThreeDModelSendShareMutationVariables
>;
export const ThreeDModelShareDocument = gql`
  mutation threeDModelShare($input: ThreeDModelShareInput!) {
    threeDModel {
      share(input: $input) {
        clientMutationId
        code
      }
    }
  }
`;

/**
 * __useThreeDModelShareMutation__
 *
 * To run a mutation, you first call `useThreeDModelShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThreeDModelShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [threeDModelShareMutation, { data, loading, error }] = useThreeDModelShareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThreeDModelShareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ThreeDModelShareMutation, ThreeDModelShareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ThreeDModelShareMutation, ThreeDModelShareMutationVariables>(
    ThreeDModelShareDocument,
    options
  );
}
export type ThreeDModelShareMutationHookResult = ReturnType<typeof useThreeDModelShareMutation>;
export type ThreeDModelShareMutationResult = ApolloReactCommon.MutationResult<ThreeDModelShareMutation>;
export type ThreeDModelShareMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ThreeDModelShareMutation,
  ThreeDModelShareMutationVariables
>;
export const AllTasksForThreeDOperatorDocument = gql`
  query allTasksForThreeDOperator(
    $skip: Int
    $name: String
    $organizationName: String
    $threeDOperatorName: String
    $states: [DocumentationRecordStateFilterEnum]
    $types: [DocumentationRecordTypeFilterEnum]
    $videoTypes: [DocumentationRecordVideoTypeFilterEnum]
    $orderBy: [DocumentationRecordOrderByEnum]
  ) {
    me {
      id
      tasks(
        allThreeD: true
        threeDOperatorAssigned: true
        first: 10
        skip: $skip
        name: $name
        organizationName: $organizationName
        state: $states
        type: $types
        videoType: $videoTypes
        orderBy: $orderBy
        threeDOperatorName: $threeDOperatorName
      ) {
        totalCount
        edges {
          node {
            id
            name
            constructionObject {
              id
              constructionSite {
                id
                organization {
                  id
                  name
                }
              }
            }
            createdAt
            state
            type
            documentationRecordVideo {
              id
              type
            }
            accountableThreeDOperator {
              id
              firstName
              lastName
            }
            threeDModel {
              id
              modelFile {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAllTasksForThreeDOperatorQuery__
 *
 * To run a query within a React component, call `useAllTasksForThreeDOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTasksForThreeDOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTasksForThreeDOperatorQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      name: // value for 'name'
 *      organizationName: // value for 'organizationName'
 *      threeDOperatorName: // value for 'threeDOperatorName'
 *      states: // value for 'states'
 *      types: // value for 'types'
 *      videoTypes: // value for 'videoTypes'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllTasksForThreeDOperatorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTasksForThreeDOperatorQuery,
    AllTasksForThreeDOperatorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTasksForThreeDOperatorQuery, AllTasksForThreeDOperatorQueryVariables>(
    AllTasksForThreeDOperatorDocument,
    options
  );
}
export function useAllTasksForThreeDOperatorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTasksForThreeDOperatorQuery,
    AllTasksForThreeDOperatorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTasksForThreeDOperatorQuery, AllTasksForThreeDOperatorQueryVariables>(
    AllTasksForThreeDOperatorDocument,
    options
  );
}
export type AllTasksForThreeDOperatorQueryHookResult = ReturnType<typeof useAllTasksForThreeDOperatorQuery>;
export type AllTasksForThreeDOperatorLazyQueryHookResult = ReturnType<typeof useAllTasksForThreeDOperatorLazyQuery>;
export type AllTasksForThreeDOperatorQueryResult = ApolloReactCommon.QueryResult<
  AllTasksForThreeDOperatorQuery,
  AllTasksForThreeDOperatorQueryVariables
>;
export const CatalogDocument = gql`
  query catalog {
    catalog {
      ...Catalog
    }
  }
  ${CatalogFragmentDoc}
`;

/**
 * __useCatalogQuery__
 *
 * To run a query within a React component, call `useCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogQuery, CatalogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CatalogQuery, CatalogQueryVariables>(CatalogDocument, options);
}
export function useCatalogLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogQuery, CatalogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CatalogQuery, CatalogQueryVariables>(CatalogDocument, options);
}
export type CatalogQueryHookResult = ReturnType<typeof useCatalogQuery>;
export type CatalogLazyQueryHookResult = ReturnType<typeof useCatalogLazyQuery>;
export type CatalogQueryResult = ApolloReactCommon.QueryResult<CatalogQuery, CatalogQueryVariables>;
export const ConfigDocument = gql`
  query config {
    config {
      ...Config
    }
  }
  ${ConfigFragmentDoc}
`;

/**
 * __useConfigQuery__
 *
 * To run a query within a React component, call `useConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
}
export function useConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigQuery, ConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
}
export type ConfigQueryHookResult = ReturnType<typeof useConfigQuery>;
export type ConfigLazyQueryHookResult = ReturnType<typeof useConfigLazyQuery>;
export type ConfigQueryResult = ApolloReactCommon.QueryResult<ConfigQuery, ConfigQueryVariables>;
export const ConstructionObjectCreatePageDocument = gql`
  query constructionObjectCreatePage($constructionObjectId: ID!) {
    constructionObject(id: $constructionObjectId) {
      id
      name
      state
      location
      createdAt
      archivedAt
      description
      createdRecords
      workInProgressRecords
      completedRecords
      accountableManager {
        ...User
      }
      accountableOperator {
        ...User
      }
      accountableSurveyor {
        ...User
      }
      constructionSite {
        id
        name
        description
        location
        organization {
          id
          name
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useConstructionObjectCreatePageQuery__
 *
 * To run a query within a React component, call `useConstructionObjectCreatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionObjectCreatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionObjectCreatePageQuery({
 *   variables: {
 *      constructionObjectId: // value for 'constructionObjectId'
 *   },
 * });
 */
export function useConstructionObjectCreatePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionObjectCreatePageQuery,
    ConstructionObjectCreatePageQueryVariables
  > &
    ({ variables: ConstructionObjectCreatePageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionObjectCreatePageQuery, ConstructionObjectCreatePageQueryVariables>(
    ConstructionObjectCreatePageDocument,
    options
  );
}
export function useConstructionObjectCreatePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionObjectCreatePageQuery,
    ConstructionObjectCreatePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionObjectCreatePageQuery, ConstructionObjectCreatePageQueryVariables>(
    ConstructionObjectCreatePageDocument,
    options
  );
}
export type ConstructionObjectCreatePageQueryHookResult = ReturnType<typeof useConstructionObjectCreatePageQuery>;
export type ConstructionObjectCreatePageLazyQueryHookResult = ReturnType<
  typeof useConstructionObjectCreatePageLazyQuery
>;
export type ConstructionObjectCreatePageQueryResult = ApolloReactCommon.QueryResult<
  ConstructionObjectCreatePageQuery,
  ConstructionObjectCreatePageQueryVariables
>;
export const ConstructionObjectDetailPageDocument = gql`
  query constructionObjectDetailPage($constructionObjectId: ID!) {
    constructionObject(id: $constructionObjectId) {
      id
      name
      state
      location
      createdAt
      archivedAt
      description
      accountableManager {
        ...User
      }
      accountableOperator {
        ...User
      }
      accountableSurveyor {
        ...User
      }
      constructionSite {
        id
        name
        description
        location
      }
      firstVersion: versions(first: 1) {
        edges {
          node {
            id
            createdAt
            user {
              ...User
            }
          }
        }
      }
      currentVersion: versions(last: 1) {
        edges {
          node {
            id
            createdAt
            user {
              ...User
            }
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useConstructionObjectDetailPageQuery__
 *
 * To run a query within a React component, call `useConstructionObjectDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionObjectDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionObjectDetailPageQuery({
 *   variables: {
 *      constructionObjectId: // value for 'constructionObjectId'
 *   },
 * });
 */
export function useConstructionObjectDetailPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionObjectDetailPageQuery,
    ConstructionObjectDetailPageQueryVariables
  > &
    ({ variables: ConstructionObjectDetailPageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionObjectDetailPageQuery, ConstructionObjectDetailPageQueryVariables>(
    ConstructionObjectDetailPageDocument,
    options
  );
}
export function useConstructionObjectDetailPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionObjectDetailPageQuery,
    ConstructionObjectDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionObjectDetailPageQuery, ConstructionObjectDetailPageQueryVariables>(
    ConstructionObjectDetailPageDocument,
    options
  );
}
export type ConstructionObjectDetailPageQueryHookResult = ReturnType<typeof useConstructionObjectDetailPageQuery>;
export type ConstructionObjectDetailPageLazyQueryHookResult = ReturnType<
  typeof useConstructionObjectDetailPageLazyQuery
>;
export type ConstructionObjectDetailPageQueryResult = ApolloReactCommon.QueryResult<
  ConstructionObjectDetailPageQuery,
  ConstructionObjectDetailPageQueryVariables
>;
export const ConstructionObjectsAndPhasesForSummaryTableDocument = gql`
  query constructionObjectsAndPhasesForSummaryTable($constructionSiteId: ID!) {
    constructionSite(id: $constructionSiteId) {
      id
      constructionObjects {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
      constructionPhases {
        totalCount
        edges {
          node {
            id
            name
            order
          }
        }
      }
    }
  }
`;

/**
 * __useConstructionObjectsAndPhasesForSummaryTableQuery__
 *
 * To run a query within a React component, call `useConstructionObjectsAndPhasesForSummaryTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionObjectsAndPhasesForSummaryTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionObjectsAndPhasesForSummaryTableQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *   },
 * });
 */
export function useConstructionObjectsAndPhasesForSummaryTableQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionObjectsAndPhasesForSummaryTableQuery,
    ConstructionObjectsAndPhasesForSummaryTableQueryVariables
  > &
    ({ variables: ConstructionObjectsAndPhasesForSummaryTableQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ConstructionObjectsAndPhasesForSummaryTableQuery,
    ConstructionObjectsAndPhasesForSummaryTableQueryVariables
  >(ConstructionObjectsAndPhasesForSummaryTableDocument, options);
}
export function useConstructionObjectsAndPhasesForSummaryTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionObjectsAndPhasesForSummaryTableQuery,
    ConstructionObjectsAndPhasesForSummaryTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ConstructionObjectsAndPhasesForSummaryTableQuery,
    ConstructionObjectsAndPhasesForSummaryTableQueryVariables
  >(ConstructionObjectsAndPhasesForSummaryTableDocument, options);
}
export type ConstructionObjectsAndPhasesForSummaryTableQueryHookResult = ReturnType<
  typeof useConstructionObjectsAndPhasesForSummaryTableQuery
>;
export type ConstructionObjectsAndPhasesForSummaryTableLazyQueryHookResult = ReturnType<
  typeof useConstructionObjectsAndPhasesForSummaryTableLazyQuery
>;
export type ConstructionObjectsAndPhasesForSummaryTableQueryResult = ApolloReactCommon.QueryResult<
  ConstructionObjectsAndPhasesForSummaryTableQuery,
  ConstructionObjectsAndPhasesForSummaryTableQueryVariables
>;
export const ConstructionObjectsForSelectDocument = gql`
  query constructionObjectsForSelect(
    $constructionSiteId: ID!
    $first: Int!
    $name: String
    $accountableManagerId: String
  ) {
    constructionSite(id: $constructionSiteId) {
      id
      constructionObjects(
        first: $first
        name: $name
        accountableManagerId: $accountableManagerId
        orderBy: [CREATED_AT_DESC]
      ) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useConstructionObjectsForSelectQuery__
 *
 * To run a query within a React component, call `useConstructionObjectsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionObjectsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionObjectsForSelectQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *      accountableManagerId: // value for 'accountableManagerId'
 *   },
 * });
 */
export function useConstructionObjectsForSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionObjectsForSelectQuery,
    ConstructionObjectsForSelectQueryVariables
  > &
    ({ variables: ConstructionObjectsForSelectQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionObjectsForSelectQuery, ConstructionObjectsForSelectQueryVariables>(
    ConstructionObjectsForSelectDocument,
    options
  );
}
export function useConstructionObjectsForSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionObjectsForSelectQuery,
    ConstructionObjectsForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionObjectsForSelectQuery, ConstructionObjectsForSelectQueryVariables>(
    ConstructionObjectsForSelectDocument,
    options
  );
}
export type ConstructionObjectsForSelectQueryHookResult = ReturnType<typeof useConstructionObjectsForSelectQuery>;
export type ConstructionObjectsForSelectLazyQueryHookResult = ReturnType<
  typeof useConstructionObjectsForSelectLazyQuery
>;
export type ConstructionObjectsForSelectQueryResult = ApolloReactCommon.QueryResult<
  ConstructionObjectsForSelectQuery,
  ConstructionObjectsForSelectQueryVariables
>;
export const ConstructionObjectsTableDocument = gql`
  query constructionObjectsTable(
    $constructionSiteId: ID!
    $skip: Int
    $name: String
    $states: [ConstructionObjectStateFilterEnum]
    $orderBy: [ConstructionObjectOrderByEnum]
  ) {
    constructionSite(id: $constructionSiteId) {
      id
      constructionObjects(first: 10, skip: $skip, name: $name, state: $states, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            id
            name
            state
            createdRecords
            workInProgressRecords
            completedRecords
          }
        }
      }
    }
  }
`;

/**
 * __useConstructionObjectsTableQuery__
 *
 * To run a query within a React component, call `useConstructionObjectsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionObjectsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionObjectsTableQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *      skip: // value for 'skip'
 *      name: // value for 'name'
 *      states: // value for 'states'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useConstructionObjectsTableQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionObjectsTableQuery,
    ConstructionObjectsTableQueryVariables
  > &
    ({ variables: ConstructionObjectsTableQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionObjectsTableQuery, ConstructionObjectsTableQueryVariables>(
    ConstructionObjectsTableDocument,
    options
  );
}
export function useConstructionObjectsTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionObjectsTableQuery,
    ConstructionObjectsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionObjectsTableQuery, ConstructionObjectsTableQueryVariables>(
    ConstructionObjectsTableDocument,
    options
  );
}
export type ConstructionObjectsTableQueryHookResult = ReturnType<typeof useConstructionObjectsTableQuery>;
export type ConstructionObjectsTableLazyQueryHookResult = ReturnType<typeof useConstructionObjectsTableLazyQuery>;
export type ConstructionObjectsTableQueryResult = ApolloReactCommon.QueryResult<
  ConstructionObjectsTableQuery,
  ConstructionObjectsTableQueryVariables
>;
export const ConstructionPhaseDocument = gql`
  query constructionPhase($id: ID!) {
    constructionPhase(id: $id) {
      id
      name
      createdAt
      constructionSite {
        id
        name
      }
    }
  }
`;

/**
 * __useConstructionPhaseQuery__
 *
 * To run a query within a React component, call `useConstructionPhaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionPhaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionPhaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionPhaseQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ConstructionPhaseQuery, ConstructionPhaseQueryVariables> &
    ({ variables: ConstructionPhaseQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionPhaseQuery, ConstructionPhaseQueryVariables>(
    ConstructionPhaseDocument,
    options
  );
}
export function useConstructionPhaseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConstructionPhaseQuery, ConstructionPhaseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionPhaseQuery, ConstructionPhaseQueryVariables>(
    ConstructionPhaseDocument,
    options
  );
}
export type ConstructionPhaseQueryHookResult = ReturnType<typeof useConstructionPhaseQuery>;
export type ConstructionPhaseLazyQueryHookResult = ReturnType<typeof useConstructionPhaseLazyQuery>;
export type ConstructionPhaseQueryResult = ApolloReactCommon.QueryResult<
  ConstructionPhaseQuery,
  ConstructionPhaseQueryVariables
>;
export const ConstructionPhasesForSelectDocument = gql`
  query constructionPhasesForSelect($constructionSiteId: ID!, $first: Int!, $name: String) {
    constructionSite(id: $constructionSiteId) {
      id
      constructionPhases(first: $first, name: $name, orderBy: [ORDER]) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useConstructionPhasesForSelectQuery__
 *
 * To run a query within a React component, call `useConstructionPhasesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionPhasesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionPhasesForSelectQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useConstructionPhasesForSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionPhasesForSelectQuery,
    ConstructionPhasesForSelectQueryVariables
  > &
    ({ variables: ConstructionPhasesForSelectQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionPhasesForSelectQuery, ConstructionPhasesForSelectQueryVariables>(
    ConstructionPhasesForSelectDocument,
    options
  );
}
export function useConstructionPhasesForSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionPhasesForSelectQuery,
    ConstructionPhasesForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionPhasesForSelectQuery, ConstructionPhasesForSelectQueryVariables>(
    ConstructionPhasesForSelectDocument,
    options
  );
}
export type ConstructionPhasesForSelectQueryHookResult = ReturnType<typeof useConstructionPhasesForSelectQuery>;
export type ConstructionPhasesForSelectLazyQueryHookResult = ReturnType<typeof useConstructionPhasesForSelectLazyQuery>;
export type ConstructionPhasesForSelectQueryResult = ApolloReactCommon.QueryResult<
  ConstructionPhasesForSelectQuery,
  ConstructionPhasesForSelectQueryVariables
>;
export const ConstructionPhasesTableDocument = gql`
  query constructionPhasesTable($constructionSiteId: ID!, $skip: Int, $orderBy: [ConstructionPhaseOrderByEnum]) {
    constructionSite(id: $constructionSiteId) {
      id
      constructionPhases(first: 10, skip: $skip, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            id
            name
            order
            createdRecords
            workInProgressRecords
            completedRecords
          }
        }
      }
    }
  }
`;

/**
 * __useConstructionPhasesTableQuery__
 *
 * To run a query within a React component, call `useConstructionPhasesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionPhasesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionPhasesTableQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useConstructionPhasesTableQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ConstructionPhasesTableQuery, ConstructionPhasesTableQueryVariables> &
    ({ variables: ConstructionPhasesTableQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionPhasesTableQuery, ConstructionPhasesTableQueryVariables>(
    ConstructionPhasesTableDocument,
    options
  );
}
export function useConstructionPhasesTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionPhasesTableQuery,
    ConstructionPhasesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionPhasesTableQuery, ConstructionPhasesTableQueryVariables>(
    ConstructionPhasesTableDocument,
    options
  );
}
export type ConstructionPhasesTableQueryHookResult = ReturnType<typeof useConstructionPhasesTableQuery>;
export type ConstructionPhasesTableLazyQueryHookResult = ReturnType<typeof useConstructionPhasesTableLazyQuery>;
export type ConstructionPhasesTableQueryResult = ApolloReactCommon.QueryResult<
  ConstructionPhasesTableQuery,
  ConstructionPhasesTableQueryVariables
>;
export const ConstructionSiteCreateEditPageDocument = gql`
  query constructionSiteCreateEditPage($id: ID!) {
    constructionSite(id: $id) {
      id
      name
      description
      state
      autoRequestThreeD
      location
      organization {
        id
      }
      accountableManager {
        ...User
      }
      accountableOperator {
        ...User
      }
      accountableSurveyor {
        ...User
      }
      constructionObjects(first: 1) {
        totalCount
      }
      constructionPhases(first: 1) {
        totalCount
      }
      createdRecords
      workInProgressRecords
      completedRecords
      firstVersion: versions(first: 1) {
        edges {
          node {
            id
            createdAt
            user {
              ...User
            }
          }
        }
      }
      currentVersion: versions(last: 1) {
        edges {
          node {
            id
            createdAt
            user {
              ...User
            }
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useConstructionSiteCreateEditPageQuery__
 *
 * To run a query within a React component, call `useConstructionSiteCreateEditPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionSiteCreateEditPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionSiteCreateEditPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionSiteCreateEditPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionSiteCreateEditPageQuery,
    ConstructionSiteCreateEditPageQueryVariables
  > &
    ({ variables: ConstructionSiteCreateEditPageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionSiteCreateEditPageQuery, ConstructionSiteCreateEditPageQueryVariables>(
    ConstructionSiteCreateEditPageDocument,
    options
  );
}
export function useConstructionSiteCreateEditPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionSiteCreateEditPageQuery,
    ConstructionSiteCreateEditPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ConstructionSiteCreateEditPageQuery,
    ConstructionSiteCreateEditPageQueryVariables
  >(ConstructionSiteCreateEditPageDocument, options);
}
export type ConstructionSiteCreateEditPageQueryHookResult = ReturnType<typeof useConstructionSiteCreateEditPageQuery>;
export type ConstructionSiteCreateEditPageLazyQueryHookResult = ReturnType<
  typeof useConstructionSiteCreateEditPageLazyQuery
>;
export type ConstructionSiteCreateEditPageQueryResult = ApolloReactCommon.QueryResult<
  ConstructionSiteCreateEditPageQuery,
  ConstructionSiteCreateEditPageQueryVariables
>;
export const ConstructionSiteDetailPageDocument = gql`
  query constructionSiteDetailPage($id: ID!) {
    constructionSite(id: $id) {
      id
      name
      description
      state
      autoRequestThreeD
      location
      organization {
        id
      }
      accountableManager {
        ...User
      }
      accountableOperator {
        ...User
      }
      accountableSurveyor {
        ...User
      }
      firstVersion: versions(first: 1) {
        edges {
          node {
            id
            createdAt
            user {
              ...User
            }
          }
        }
      }
      currentVersion: versions(last: 1) {
        edges {
          node {
            id
            createdAt
            user {
              ...User
            }
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useConstructionSiteDetailPageQuery__
 *
 * To run a query within a React component, call `useConstructionSiteDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionSiteDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionSiteDetailPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionSiteDetailPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionSiteDetailPageQuery,
    ConstructionSiteDetailPageQueryVariables
  > &
    ({ variables: ConstructionSiteDetailPageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionSiteDetailPageQuery, ConstructionSiteDetailPageQueryVariables>(
    ConstructionSiteDetailPageDocument,
    options
  );
}
export function useConstructionSiteDetailPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionSiteDetailPageQuery,
    ConstructionSiteDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionSiteDetailPageQuery, ConstructionSiteDetailPageQueryVariables>(
    ConstructionSiteDetailPageDocument,
    options
  );
}
export type ConstructionSiteDetailPageQueryHookResult = ReturnType<typeof useConstructionSiteDetailPageQuery>;
export type ConstructionSiteDetailPageLazyQueryHookResult = ReturnType<typeof useConstructionSiteDetailPageLazyQuery>;
export type ConstructionSiteDetailPageQueryResult = ApolloReactCommon.QueryResult<
  ConstructionSiteDetailPageQuery,
  ConstructionSiteDetailPageQueryVariables
>;
export const ConstructionSiteForConstructionObjectCreatePageDocument = gql`
  query constructionSiteForConstructionObjectCreatePage($id: ID!) {
    constructionSite(id: $id) {
      id
      name
      location
      organization {
        id
      }
      accountableManager {
        ...User
      }
      accountableOperator {
        ...User
      }
      accountableSurveyor {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useConstructionSiteForConstructionObjectCreatePageQuery__
 *
 * To run a query within a React component, call `useConstructionSiteForConstructionObjectCreatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionSiteForConstructionObjectCreatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionSiteForConstructionObjectCreatePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionSiteForConstructionObjectCreatePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionSiteForConstructionObjectCreatePageQuery,
    ConstructionSiteForConstructionObjectCreatePageQueryVariables
  > &
    ({ variables: ConstructionSiteForConstructionObjectCreatePageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ConstructionSiteForConstructionObjectCreatePageQuery,
    ConstructionSiteForConstructionObjectCreatePageQueryVariables
  >(ConstructionSiteForConstructionObjectCreatePageDocument, options);
}
export function useConstructionSiteForConstructionObjectCreatePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionSiteForConstructionObjectCreatePageQuery,
    ConstructionSiteForConstructionObjectCreatePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ConstructionSiteForConstructionObjectCreatePageQuery,
    ConstructionSiteForConstructionObjectCreatePageQueryVariables
  >(ConstructionSiteForConstructionObjectCreatePageDocument, options);
}
export type ConstructionSiteForConstructionObjectCreatePageQueryHookResult = ReturnType<
  typeof useConstructionSiteForConstructionObjectCreatePageQuery
>;
export type ConstructionSiteForConstructionObjectCreatePageLazyQueryHookResult = ReturnType<
  typeof useConstructionSiteForConstructionObjectCreatePageLazyQuery
>;
export type ConstructionSiteForConstructionObjectCreatePageQueryResult = ApolloReactCommon.QueryResult<
  ConstructionSiteForConstructionObjectCreatePageQuery,
  ConstructionSiteForConstructionObjectCreatePageQueryVariables
>;
export const ConstructionSiteForDocumentationRecordCreateSimplifiedPageDocument = gql`
  query constructionSiteForDocumentationRecordCreateSimplifiedPage($id: ID!) {
    constructionSite(id: $id) {
      id
      name
      accountableManagerId
    }
  }
`;

/**
 * __useConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery__
 *
 * To run a query within a React component, call `useConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery,
    ConstructionSiteForDocumentationRecordCreateSimplifiedPageQueryVariables
  > &
    (
      | { variables: ConstructionSiteForDocumentationRecordCreateSimplifiedPageQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery,
    ConstructionSiteForDocumentationRecordCreateSimplifiedPageQueryVariables
  >(ConstructionSiteForDocumentationRecordCreateSimplifiedPageDocument, options);
}
export function useConstructionSiteForDocumentationRecordCreateSimplifiedPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery,
    ConstructionSiteForDocumentationRecordCreateSimplifiedPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery,
    ConstructionSiteForDocumentationRecordCreateSimplifiedPageQueryVariables
  >(ConstructionSiteForDocumentationRecordCreateSimplifiedPageDocument, options);
}
export type ConstructionSiteForDocumentationRecordCreateSimplifiedPageQueryHookResult = ReturnType<
  typeof useConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery
>;
export type ConstructionSiteForDocumentationRecordCreateSimplifiedPageLazyQueryHookResult = ReturnType<
  typeof useConstructionSiteForDocumentationRecordCreateSimplifiedPageLazyQuery
>;
export type ConstructionSiteForDocumentationRecordCreateSimplifiedPageQueryResult = ApolloReactCommon.QueryResult<
  ConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery,
  ConstructionSiteForDocumentationRecordCreateSimplifiedPageQueryVariables
>;
export const ConstructionSiteNameDocument = gql`
  query constructionSiteName($id: ID!) {
    constructionSite(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useConstructionSiteNameQuery__
 *
 * To run a query within a React component, call `useConstructionSiteNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionSiteNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionSiteNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstructionSiteNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ConstructionSiteNameQuery, ConstructionSiteNameQueryVariables> &
    ({ variables: ConstructionSiteNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionSiteNameQuery, ConstructionSiteNameQueryVariables>(
    ConstructionSiteNameDocument,
    options
  );
}
export function useConstructionSiteNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConstructionSiteNameQuery, ConstructionSiteNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionSiteNameQuery, ConstructionSiteNameQueryVariables>(
    ConstructionSiteNameDocument,
    options
  );
}
export type ConstructionSiteNameQueryHookResult = ReturnType<typeof useConstructionSiteNameQuery>;
export type ConstructionSiteNameLazyQueryHookResult = ReturnType<typeof useConstructionSiteNameLazyQuery>;
export type ConstructionSiteNameQueryResult = ApolloReactCommon.QueryResult<
  ConstructionSiteNameQuery,
  ConstructionSiteNameQueryVariables
>;
export const ConstructionSitesDocument = gql`
  query constructionSites(
    $organizationId: ID!
    $skip: Int
    $name: String
    $accountableManagerName: String
    $states: [ConstructionSiteStateFilterEnum]
    $orderBy: [ConstructionSiteOrderByEnum]
  ) {
    organization(id: $organizationId) {
      id
      constructionSites(
        first: 10
        skip: $skip
        name: $name
        accountableManagerName: $accountableManagerName
        state: $states
        orderBy: $orderBy
      ) {
        totalCount
        edges {
          node {
            ...ConstructionSiteTableFragment
          }
        }
      }
    }
  }
  ${ConstructionSiteTableFragmentDoc}
`;

/**
 * __useConstructionSitesQuery__
 *
 * To run a query within a React component, call `useConstructionSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionSitesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      skip: // value for 'skip'
 *      name: // value for 'name'
 *      accountableManagerName: // value for 'accountableManagerName'
 *      states: // value for 'states'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useConstructionSitesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ConstructionSitesQuery, ConstructionSitesQueryVariables> &
    ({ variables: ConstructionSitesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionSitesQuery, ConstructionSitesQueryVariables>(
    ConstructionSitesDocument,
    options
  );
}
export function useConstructionSitesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConstructionSitesQuery, ConstructionSitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionSitesQuery, ConstructionSitesQueryVariables>(
    ConstructionSitesDocument,
    options
  );
}
export type ConstructionSitesQueryHookResult = ReturnType<typeof useConstructionSitesQuery>;
export type ConstructionSitesLazyQueryHookResult = ReturnType<typeof useConstructionSitesLazyQuery>;
export type ConstructionSitesQueryResult = ApolloReactCommon.QueryResult<
  ConstructionSitesQuery,
  ConstructionSitesQueryVariables
>;
export const ConstructionSitesForSelectDocument = gql`
  query constructionSitesForSelect($organizationId: ID!, $first: Int!, $name: String, $accountableManagerId: String) {
    organization(id: $organizationId) {
      id
      constructionSites(
        first: $first
        orderBy: [CREATED_AT_DESC]
        name: $name
        accountableManagerId: $accountableManagerId
      ) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useConstructionSitesForSelectQuery__
 *
 * To run a query within a React component, call `useConstructionSitesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstructionSitesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstructionSitesForSelectQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *      accountableManagerId: // value for 'accountableManagerId'
 *   },
 * });
 */
export function useConstructionSitesForSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConstructionSitesForSelectQuery,
    ConstructionSitesForSelectQueryVariables
  > &
    ({ variables: ConstructionSitesForSelectQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConstructionSitesForSelectQuery, ConstructionSitesForSelectQueryVariables>(
    ConstructionSitesForSelectDocument,
    options
  );
}
export function useConstructionSitesForSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConstructionSitesForSelectQuery,
    ConstructionSitesForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConstructionSitesForSelectQuery, ConstructionSitesForSelectQueryVariables>(
    ConstructionSitesForSelectDocument,
    options
  );
}
export type ConstructionSitesForSelectQueryHookResult = ReturnType<typeof useConstructionSitesForSelectQuery>;
export type ConstructionSitesForSelectLazyQueryHookResult = ReturnType<typeof useConstructionSitesForSelectLazyQuery>;
export type ConstructionSitesForSelectQueryResult = ApolloReactCommon.QueryResult<
  ConstructionSitesForSelectQuery,
  ConstructionSitesForSelectQueryVariables
>;
export const DocumentationRecordDocument = gql`
  query documentationRecord($id: ID!) {
    documentationRecord(id: $id) {
      ...DocumentationRecord
    }
  }
  ${DocumentationRecordFragmentDoc}
`;

/**
 * __useDocumentationRecordQuery__
 *
 * To run a query within a React component, call `useDocumentationRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentationRecordQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DocumentationRecordQuery, DocumentationRecordQueryVariables> &
    ({ variables: DocumentationRecordQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DocumentationRecordQuery, DocumentationRecordQueryVariables>(
    DocumentationRecordDocument,
    options
  );
}
export function useDocumentationRecordLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentationRecordQuery, DocumentationRecordQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DocumentationRecordQuery, DocumentationRecordQueryVariables>(
    DocumentationRecordDocument,
    options
  );
}
export type DocumentationRecordQueryHookResult = ReturnType<typeof useDocumentationRecordQuery>;
export type DocumentationRecordLazyQueryHookResult = ReturnType<typeof useDocumentationRecordLazyQuery>;
export type DocumentationRecordQueryResult = ApolloReactCommon.QueryResult<
  DocumentationRecordQuery,
  DocumentationRecordQueryVariables
>;
export const DocumentationRecordCreatePageDocument = gql`
  query documentationRecordCreatePage($constructionSiteId: ID!, $constructionObjectId: ID!) {
    constructionSite(id: $constructionSiteId) {
      id
      name
      autoRequestThreeD
      constructionPhases(first: 1) {
        edges {
          node {
            id
          }
        }
      }
      organization {
        id
        name
      }
    }
    constructionObject(id: $constructionObjectId) {
      id
      name
      suggestedDocumentationName
      accountableManager {
        ...User
      }
      accountableOperator {
        ...User
      }
      accountableSurveyor {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useDocumentationRecordCreatePageQuery__
 *
 * To run a query within a React component, call `useDocumentationRecordCreatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordCreatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationRecordCreatePageQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *      constructionObjectId: // value for 'constructionObjectId'
 *   },
 * });
 */
export function useDocumentationRecordCreatePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DocumentationRecordCreatePageQuery,
    DocumentationRecordCreatePageQueryVariables
  > &
    ({ variables: DocumentationRecordCreatePageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DocumentationRecordCreatePageQuery, DocumentationRecordCreatePageQueryVariables>(
    DocumentationRecordCreatePageDocument,
    options
  );
}
export function useDocumentationRecordCreatePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentationRecordCreatePageQuery,
    DocumentationRecordCreatePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DocumentationRecordCreatePageQuery, DocumentationRecordCreatePageQueryVariables>(
    DocumentationRecordCreatePageDocument,
    options
  );
}
export type DocumentationRecordCreatePageQueryHookResult = ReturnType<typeof useDocumentationRecordCreatePageQuery>;
export type DocumentationRecordCreatePageLazyQueryHookResult = ReturnType<
  typeof useDocumentationRecordCreatePageLazyQuery
>;
export type DocumentationRecordCreatePageQueryResult = ApolloReactCommon.QueryResult<
  DocumentationRecordCreatePageQuery,
  DocumentationRecordCreatePageQueryVariables
>;
export const DocumentationRecordCreateTypePageDocument = gql`
  query documentationRecordCreateTypePage($constructionSiteId: ID!, $constructionObjectId: ID!) {
    constructionSite(id: $constructionSiteId) {
      id
      name
    }
    constructionObject(id: $constructionObjectId) {
      id
      name
      totalRecords
    }
  }
`;

/**
 * __useDocumentationRecordCreateTypePageQuery__
 *
 * To run a query within a React component, call `useDocumentationRecordCreateTypePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordCreateTypePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationRecordCreateTypePageQuery({
 *   variables: {
 *      constructionSiteId: // value for 'constructionSiteId'
 *      constructionObjectId: // value for 'constructionObjectId'
 *   },
 * });
 */
export function useDocumentationRecordCreateTypePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DocumentationRecordCreateTypePageQuery,
    DocumentationRecordCreateTypePageQueryVariables
  > &
    ({ variables: DocumentationRecordCreateTypePageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DocumentationRecordCreateTypePageQuery,
    DocumentationRecordCreateTypePageQueryVariables
  >(DocumentationRecordCreateTypePageDocument, options);
}
export function useDocumentationRecordCreateTypePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentationRecordCreateTypePageQuery,
    DocumentationRecordCreateTypePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DocumentationRecordCreateTypePageQuery,
    DocumentationRecordCreateTypePageQueryVariables
  >(DocumentationRecordCreateTypePageDocument, options);
}
export type DocumentationRecordCreateTypePageQueryHookResult = ReturnType<
  typeof useDocumentationRecordCreateTypePageQuery
>;
export type DocumentationRecordCreateTypePageLazyQueryHookResult = ReturnType<
  typeof useDocumentationRecordCreateTypePageLazyQuery
>;
export type DocumentationRecordCreateTypePageQueryResult = ApolloReactCommon.QueryResult<
  DocumentationRecordCreateTypePageQuery,
  DocumentationRecordCreateTypePageQueryVariables
>;
export const DocumentationRecordEditPageDocument = gql`
  query documentationRecordEditPage($id: ID!) {
    documentationRecord(id: $id) {
      id
      name
      description
      constructionObject {
        id
        name
        suggestedDocumentationName
        accountableManager {
          ...User
        }
        accountableOperator {
          ...User
        }
        accountableSurveyor {
          ...User
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useDocumentationRecordEditPageQuery__
 *
 * To run a query within a React component, call `useDocumentationRecordEditPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordEditPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationRecordEditPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentationRecordEditPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DocumentationRecordEditPageQuery,
    DocumentationRecordEditPageQueryVariables
  > &
    ({ variables: DocumentationRecordEditPageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DocumentationRecordEditPageQuery, DocumentationRecordEditPageQueryVariables>(
    DocumentationRecordEditPageDocument,
    options
  );
}
export function useDocumentationRecordEditPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentationRecordEditPageQuery,
    DocumentationRecordEditPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DocumentationRecordEditPageQuery, DocumentationRecordEditPageQueryVariables>(
    DocumentationRecordEditPageDocument,
    options
  );
}
export type DocumentationRecordEditPageQueryHookResult = ReturnType<typeof useDocumentationRecordEditPageQuery>;
export type DocumentationRecordEditPageLazyQueryHookResult = ReturnType<typeof useDocumentationRecordEditPageLazyQuery>;
export type DocumentationRecordEditPageQueryResult = ApolloReactCommon.QueryResult<
  DocumentationRecordEditPageQuery,
  DocumentationRecordEditPageQueryVariables
>;
export const DocumentationRecordsDocument = gql`
  query documentationRecords(
    $id: ID!
    $skip: Int
    $orderBy: [DocumentationRecordOrderByEnum]
    $name: String
    $constructionSiteName: String
    $types: [DocumentationRecordTypeFilterEnum]
    $videoTypes: [DocumentationRecordVideoTypeFilterEnum]
    $states: [DocumentationRecordStateFilterEnum]
    $threeDModelUploaded: Boolean
  ) {
    organization(id: $id) {
      id
      tasks(
        first: 10
        skip: $skip
        orderBy: $orderBy
        name: $name
        type: $types
        videoType: $videoTypes
        state: $states
        threeDModelUploaded: $threeDModelUploaded
        constructionSiteName: $constructionSiteName
      ) {
        totalCount
        edges {
          node {
            ...Task
            accountableManager {
              id
            }
            accountableOperator {
              id
            }
            accountableSurveyor {
              id
            }
          }
        }
      }
    }
  }
  ${TaskFragmentDoc}
`;

/**
 * __useDocumentationRecordsQuery__
 *
 * To run a query within a React component, call `useDocumentationRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationRecordsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      name: // value for 'name'
 *      constructionSiteName: // value for 'constructionSiteName'
 *      types: // value for 'types'
 *      videoTypes: // value for 'videoTypes'
 *      states: // value for 'states'
 *      threeDModelUploaded: // value for 'threeDModelUploaded'
 *   },
 * });
 */
export function useDocumentationRecordsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DocumentationRecordsQuery, DocumentationRecordsQueryVariables> &
    ({ variables: DocumentationRecordsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DocumentationRecordsQuery, DocumentationRecordsQueryVariables>(
    DocumentationRecordsDocument,
    options
  );
}
export function useDocumentationRecordsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentationRecordsQuery, DocumentationRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DocumentationRecordsQuery, DocumentationRecordsQueryVariables>(
    DocumentationRecordsDocument,
    options
  );
}
export type DocumentationRecordsQueryHookResult = ReturnType<typeof useDocumentationRecordsQuery>;
export type DocumentationRecordsLazyQueryHookResult = ReturnType<typeof useDocumentationRecordsLazyQuery>;
export type DocumentationRecordsQueryResult = ApolloReactCommon.QueryResult<
  DocumentationRecordsQuery,
  DocumentationRecordsQueryVariables
>;
export const DocumentationRecordsForSummaryTableDocument = gql`
  query documentationRecordsForSummaryTable($constructionObjectId: ID!) {
    constructionObject(id: $constructionObjectId) {
      id
      documentationRecords {
        edges {
          node {
            id
            name
            type
            state
            constructionPhaseId
          }
        }
      }
    }
  }
`;

/**
 * __useDocumentationRecordsForSummaryTableQuery__
 *
 * To run a query within a React component, call `useDocumentationRecordsForSummaryTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordsForSummaryTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationRecordsForSummaryTableQuery({
 *   variables: {
 *      constructionObjectId: // value for 'constructionObjectId'
 *   },
 * });
 */
export function useDocumentationRecordsForSummaryTableQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DocumentationRecordsForSummaryTableQuery,
    DocumentationRecordsForSummaryTableQueryVariables
  > &
    ({ variables: DocumentationRecordsForSummaryTableQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DocumentationRecordsForSummaryTableQuery,
    DocumentationRecordsForSummaryTableQueryVariables
  >(DocumentationRecordsForSummaryTableDocument, options);
}
export function useDocumentationRecordsForSummaryTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentationRecordsForSummaryTableQuery,
    DocumentationRecordsForSummaryTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DocumentationRecordsForSummaryTableQuery,
    DocumentationRecordsForSummaryTableQueryVariables
  >(DocumentationRecordsForSummaryTableDocument, options);
}
export type DocumentationRecordsForSummaryTableQueryHookResult = ReturnType<
  typeof useDocumentationRecordsForSummaryTableQuery
>;
export type DocumentationRecordsForSummaryTableLazyQueryHookResult = ReturnType<
  typeof useDocumentationRecordsForSummaryTableLazyQuery
>;
export type DocumentationRecordsForSummaryTableQueryResult = ApolloReactCommon.QueryResult<
  DocumentationRecordsForSummaryTableQuery,
  DocumentationRecordsForSummaryTableQueryVariables
>;
export const DocumentationRecordsTableAtConstructionObjectDetailPageDocument = gql`
  query documentationRecordsTableAtConstructionObjectDetailPage(
    $constructionObjectId: ID!
    $skip: Int
    $orderBy: [DocumentationRecordOrderByEnum]
    $types: [DocumentationRecordTypeFilterEnum]
    $videoTypes: [DocumentationRecordVideoTypeFilterEnum]
    $states: [DocumentationRecordStateFilterEnum]
  ) {
    constructionObject(id: $constructionObjectId) {
      id
      documentationRecords(
        first: 10
        skip: $skip
        orderBy: $orderBy
        type: $types
        videoType: $videoTypes
        state: $states
      ) {
        totalCount
        edges {
          node {
            id
            name
            createdAt
            state
            type
            constructionPhase {
              id
              name
              order
            }
            documentationRecordVideo {
              id
              type
            }
            threeDModel {
              id
              modelFile {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDocumentationRecordsTableAtConstructionObjectDetailPageQuery__
 *
 * To run a query within a React component, call `useDocumentationRecordsTableAtConstructionObjectDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordsTableAtConstructionObjectDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationRecordsTableAtConstructionObjectDetailPageQuery({
 *   variables: {
 *      constructionObjectId: // value for 'constructionObjectId'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      types: // value for 'types'
 *      videoTypes: // value for 'videoTypes'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useDocumentationRecordsTableAtConstructionObjectDetailPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DocumentationRecordsTableAtConstructionObjectDetailPageQuery,
    DocumentationRecordsTableAtConstructionObjectDetailPageQueryVariables
  > &
    (
      | { variables: DocumentationRecordsTableAtConstructionObjectDetailPageQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DocumentationRecordsTableAtConstructionObjectDetailPageQuery,
    DocumentationRecordsTableAtConstructionObjectDetailPageQueryVariables
  >(DocumentationRecordsTableAtConstructionObjectDetailPageDocument, options);
}
export function useDocumentationRecordsTableAtConstructionObjectDetailPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentationRecordsTableAtConstructionObjectDetailPageQuery,
    DocumentationRecordsTableAtConstructionObjectDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DocumentationRecordsTableAtConstructionObjectDetailPageQuery,
    DocumentationRecordsTableAtConstructionObjectDetailPageQueryVariables
  >(DocumentationRecordsTableAtConstructionObjectDetailPageDocument, options);
}
export type DocumentationRecordsTableAtConstructionObjectDetailPageQueryHookResult = ReturnType<
  typeof useDocumentationRecordsTableAtConstructionObjectDetailPageQuery
>;
export type DocumentationRecordsTableAtConstructionObjectDetailPageLazyQueryHookResult = ReturnType<
  typeof useDocumentationRecordsTableAtConstructionObjectDetailPageLazyQuery
>;
export type DocumentationRecordsTableAtConstructionObjectDetailPageQueryResult = ApolloReactCommon.QueryResult<
  DocumentationRecordsTableAtConstructionObjectDetailPageQuery,
  DocumentationRecordsTableAtConstructionObjectDetailPageQueryVariables
>;
export const DocumentationRecordsTableAtConstructionPhaseDetailPageDocument = gql`
  query documentationRecordsTableAtConstructionPhaseDetailPage(
    $constructionPhaseId: ID!
    $skip: Int
    $orderBy: [DocumentationRecordOrderByEnum]
    $types: [DocumentationRecordTypeFilterEnum]
    $videoTypes: [DocumentationRecordVideoTypeFilterEnum]
    $states: [DocumentationRecordStateFilterEnum]
  ) {
    constructionPhase(id: $constructionPhaseId) {
      id
      documentationRecords(
        first: 10
        skip: $skip
        orderBy: $orderBy
        type: $types
        videoType: $videoTypes
        state: $states
      ) {
        totalCount
        edges {
          node {
            id
            name
            constructionObject {
              id
              name
            }
            createdAt
            state
            type
            documentationRecordVideo {
              id
              type
            }
            threeDModel {
              id
              modelFile {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDocumentationRecordsTableAtConstructionPhaseDetailPageQuery__
 *
 * To run a query within a React component, call `useDocumentationRecordsTableAtConstructionPhaseDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordsTableAtConstructionPhaseDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationRecordsTableAtConstructionPhaseDetailPageQuery({
 *   variables: {
 *      constructionPhaseId: // value for 'constructionPhaseId'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      types: // value for 'types'
 *      videoTypes: // value for 'videoTypes'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useDocumentationRecordsTableAtConstructionPhaseDetailPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DocumentationRecordsTableAtConstructionPhaseDetailPageQuery,
    DocumentationRecordsTableAtConstructionPhaseDetailPageQueryVariables
  > &
    (
      | { variables: DocumentationRecordsTableAtConstructionPhaseDetailPageQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DocumentationRecordsTableAtConstructionPhaseDetailPageQuery,
    DocumentationRecordsTableAtConstructionPhaseDetailPageQueryVariables
  >(DocumentationRecordsTableAtConstructionPhaseDetailPageDocument, options);
}
export function useDocumentationRecordsTableAtConstructionPhaseDetailPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentationRecordsTableAtConstructionPhaseDetailPageQuery,
    DocumentationRecordsTableAtConstructionPhaseDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DocumentationRecordsTableAtConstructionPhaseDetailPageQuery,
    DocumentationRecordsTableAtConstructionPhaseDetailPageQueryVariables
  >(DocumentationRecordsTableAtConstructionPhaseDetailPageDocument, options);
}
export type DocumentationRecordsTableAtConstructionPhaseDetailPageQueryHookResult = ReturnType<
  typeof useDocumentationRecordsTableAtConstructionPhaseDetailPageQuery
>;
export type DocumentationRecordsTableAtConstructionPhaseDetailPageLazyQueryHookResult = ReturnType<
  typeof useDocumentationRecordsTableAtConstructionPhaseDetailPageLazyQuery
>;
export type DocumentationRecordsTableAtConstructionPhaseDetailPageQueryResult = ApolloReactCommon.QueryResult<
  DocumentationRecordsTableAtConstructionPhaseDetailPageQuery,
  DocumentationRecordsTableAtConstructionPhaseDetailPageQueryVariables
>;
export const DocumentationRecordsTableAtConstructionSiteDetailPageDocument = gql`
  query documentationRecordsTableAtConstructionSiteDetailPage(
    $organizationId: ID!
    $constructionSiteId: String!
    $skip: Int
    $orderBy: [DocumentationRecordOrderByEnum]
    $types: [DocumentationRecordTypeFilterEnum]
    $videoTypes: [DocumentationRecordVideoTypeFilterEnum]
    $states: [DocumentationRecordStateFilterEnum]
  ) {
    organization(id: $organizationId) {
      id
      tasks(
        constructionSiteId: $constructionSiteId
        first: 10
        skip: $skip
        orderBy: $orderBy
        type: $types
        videoType: $videoTypes
        state: $states
      ) {
        totalCount
        edges {
          node {
            id
            name
            createdAt
            state
            type
            constructionPhase {
              id
              name
              order
            }
            documentationRecordVideo {
              id
              type
            }
            threeDModel {
              id
              modelFile {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDocumentationRecordsTableAtConstructionSiteDetailPageQuery__
 *
 * To run a query within a React component, call `useDocumentationRecordsTableAtConstructionSiteDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentationRecordsTableAtConstructionSiteDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentationRecordsTableAtConstructionSiteDetailPageQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      constructionSiteId: // value for 'constructionSiteId'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      types: // value for 'types'
 *      videoTypes: // value for 'videoTypes'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useDocumentationRecordsTableAtConstructionSiteDetailPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DocumentationRecordsTableAtConstructionSiteDetailPageQuery,
    DocumentationRecordsTableAtConstructionSiteDetailPageQueryVariables
  > &
    (
      | { variables: DocumentationRecordsTableAtConstructionSiteDetailPageQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DocumentationRecordsTableAtConstructionSiteDetailPageQuery,
    DocumentationRecordsTableAtConstructionSiteDetailPageQueryVariables
  >(DocumentationRecordsTableAtConstructionSiteDetailPageDocument, options);
}
export function useDocumentationRecordsTableAtConstructionSiteDetailPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentationRecordsTableAtConstructionSiteDetailPageQuery,
    DocumentationRecordsTableAtConstructionSiteDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DocumentationRecordsTableAtConstructionSiteDetailPageQuery,
    DocumentationRecordsTableAtConstructionSiteDetailPageQueryVariables
  >(DocumentationRecordsTableAtConstructionSiteDetailPageDocument, options);
}
export type DocumentationRecordsTableAtConstructionSiteDetailPageQueryHookResult = ReturnType<
  typeof useDocumentationRecordsTableAtConstructionSiteDetailPageQuery
>;
export type DocumentationRecordsTableAtConstructionSiteDetailPageLazyQueryHookResult = ReturnType<
  typeof useDocumentationRecordsTableAtConstructionSiteDetailPageLazyQuery
>;
export type DocumentationRecordsTableAtConstructionSiteDetailPageQueryResult = ApolloReactCommon.QueryResult<
  DocumentationRecordsTableAtConstructionSiteDetailPageQuery,
  DocumentationRecordsTableAtConstructionSiteDetailPageQueryVariables
>;
export const InquiriesForAdditionalServicesPageDocument = gql`
  query inquiriesForAdditionalServicesPage(
    $organizationId: ID!
    $skip: Int
    $orderBy: [InquiryOrderByEnum]
    $status: [InquiryStatusFilterEnum]
  ) {
    organization(id: $organizationId) {
      id
      inquiries(first: 10, skip: $skip, orderBy: $orderBy, status: $status) {
        totalCount
        edges {
          node {
            requests {
              edges {
                node {
                  id
                  constructionSites(first: 1) {
                    totalCount
                    edges {
                      node {
                        __typename
                        id
                        name
                      }
                    }
                  }
                  constructionObjects(first: 1) {
                    totalCount
                    edges {
                      node {
                        __typename
                        id
                        name
                        constructionSite {
                          id
                          name
                        }
                      }
                    }
                  }
                  documentationRecords(first: 1) {
                    totalCount
                    edges {
                      node {
                        __typename
                        id
                        name
                        constructionObject {
                          id
                          constructionSite {
                            id
                          }
                        }
                      }
                    }
                  }
                  inquiryTopic {
                    id
                    ...InquiryTopicLocalesFragment
                  }
                }
              }
            }
            id
            createdAt
            status
          }
        }
      }
    }
  }
  ${InquiryTopicLocalesFragmentDoc}
`;

/**
 * __useInquiriesForAdditionalServicesPageQuery__
 *
 * To run a query within a React component, call `useInquiriesForAdditionalServicesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiriesForAdditionalServicesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiriesForAdditionalServicesPageQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useInquiriesForAdditionalServicesPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InquiriesForAdditionalServicesPageQuery,
    InquiriesForAdditionalServicesPageQueryVariables
  > &
    ({ variables: InquiriesForAdditionalServicesPageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    InquiriesForAdditionalServicesPageQuery,
    InquiriesForAdditionalServicesPageQueryVariables
  >(InquiriesForAdditionalServicesPageDocument, options);
}
export function useInquiriesForAdditionalServicesPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InquiriesForAdditionalServicesPageQuery,
    InquiriesForAdditionalServicesPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InquiriesForAdditionalServicesPageQuery,
    InquiriesForAdditionalServicesPageQueryVariables
  >(InquiriesForAdditionalServicesPageDocument, options);
}
export type InquiriesForAdditionalServicesPageQueryHookResult = ReturnType<
  typeof useInquiriesForAdditionalServicesPageQuery
>;
export type InquiriesForAdditionalServicesPageLazyQueryHookResult = ReturnType<
  typeof useInquiriesForAdditionalServicesPageLazyQuery
>;
export type InquiriesForAdditionalServicesPageQueryResult = ApolloReactCommon.QueryResult<
  InquiriesForAdditionalServicesPageQuery,
  InquiriesForAdditionalServicesPageQueryVariables
>;
export const InquiriesForAdditionalServicesReviewPageDocument = gql`
  query inquiriesForAdditionalServicesReviewPage(
    $skip: Int
    $orderBy: [InquiryOrderByEnum]
    $inquiryNumber: String
    $requestedByName: String
    $organizationName: String
    $status: [InquiryStatusFilterEnum]
  ) {
    inquiries(
      skip: $skip
      first: 10
      status: $status
      orderBy: $orderBy
      id: $inquiryNumber
      requestedByName: $requestedByName
      organizationName: $organizationName
    ) {
      totalCount
      edges {
        node {
          id
          status
          createdAt
          requests {
            edges {
              node {
                id
                inquiryTopic {
                  id
                  ...InquiryTopicLocalesFragment
                }
              }
            }
          }
          user {
            id
            firstName
            lastName
          }
          organization {
            id
            name
          }
        }
      }
    }
  }
  ${InquiryTopicLocalesFragmentDoc}
`;

/**
 * __useInquiriesForAdditionalServicesReviewPageQuery__
 *
 * To run a query within a React component, call `useInquiriesForAdditionalServicesReviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiriesForAdditionalServicesReviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiriesForAdditionalServicesReviewPageQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      inquiryNumber: // value for 'inquiryNumber'
 *      requestedByName: // value for 'requestedByName'
 *      organizationName: // value for 'organizationName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useInquiriesForAdditionalServicesReviewPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InquiriesForAdditionalServicesReviewPageQuery,
    InquiriesForAdditionalServicesReviewPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    InquiriesForAdditionalServicesReviewPageQuery,
    InquiriesForAdditionalServicesReviewPageQueryVariables
  >(InquiriesForAdditionalServicesReviewPageDocument, options);
}
export function useInquiriesForAdditionalServicesReviewPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InquiriesForAdditionalServicesReviewPageQuery,
    InquiriesForAdditionalServicesReviewPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InquiriesForAdditionalServicesReviewPageQuery,
    InquiriesForAdditionalServicesReviewPageQueryVariables
  >(InquiriesForAdditionalServicesReviewPageDocument, options);
}
export type InquiriesForAdditionalServicesReviewPageQueryHookResult = ReturnType<
  typeof useInquiriesForAdditionalServicesReviewPageQuery
>;
export type InquiriesForAdditionalServicesReviewPageLazyQueryHookResult = ReturnType<
  typeof useInquiriesForAdditionalServicesReviewPageLazyQuery
>;
export type InquiriesForAdditionalServicesReviewPageQueryResult = ApolloReactCommon.QueryResult<
  InquiriesForAdditionalServicesReviewPageQuery,
  InquiriesForAdditionalServicesReviewPageQueryVariables
>;
export const InquiryForDetailPageDocument = gql`
  query inquiryForDetailPage($id: ID!) {
    inquiry(id: $id) {
      id
      status
      createdAt
      resolvedAt
      userComment
      resolverComment
      resolvedBy {
        id
        firstName
        lastName
      }
      files {
        edges {
          node {
            id
            fileName
            url
            state
          }
        }
      }
      requests {
        edges {
          node {
            id
            inquiryTopic {
              id
              ...InquiryTopicLocalesFragment
            }
            documentationRecords(first: 20) {
              edges {
                node {
                  id
                  name
                  constructionObject {
                    id
                    name
                    constructionSite {
                      id
                      name
                    }
                  }
                }
              }
            }
            constructionSites(first: 20) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            constructionObjects(first: 20) {
              edges {
                node {
                  id
                  name
                  constructionSite {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${InquiryTopicLocalesFragmentDoc}
`;

/**
 * __useInquiryForDetailPageQuery__
 *
 * To run a query within a React component, call `useInquiryForDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiryForDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiryForDetailPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInquiryForDetailPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<InquiryForDetailPageQuery, InquiryForDetailPageQueryVariables> &
    ({ variables: InquiryForDetailPageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InquiryForDetailPageQuery, InquiryForDetailPageQueryVariables>(
    InquiryForDetailPageDocument,
    options
  );
}
export function useInquiryForDetailPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InquiryForDetailPageQuery, InquiryForDetailPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InquiryForDetailPageQuery, InquiryForDetailPageQueryVariables>(
    InquiryForDetailPageDocument,
    options
  );
}
export type InquiryForDetailPageQueryHookResult = ReturnType<typeof useInquiryForDetailPageQuery>;
export type InquiryForDetailPageLazyQueryHookResult = ReturnType<typeof useInquiryForDetailPageLazyQuery>;
export type InquiryForDetailPageQueryResult = ApolloReactCommon.QueryResult<
  InquiryForDetailPageQuery,
  InquiryForDetailPageQueryVariables
>;
export const InquiryForReviewDetailPageDocument = gql`
  query inquiryForReviewDetailPage($id: ID!) {
    inquiry(id: $id) {
      id
      status
      createdAt
      resolvedAt
      userComment
      resolverComment
      organization {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
      files {
        edges {
          node {
            id
            fileName
            url
            state
          }
        }
      }
      requests {
        edges {
          node {
            id
            inquiryTopic {
              id
              ...InquiryTopicLocalesFragment
            }
            documentationRecords(first: 20) {
              edges {
                node {
                  id
                  name
                  constructionObject {
                    id
                    name
                    constructionSite {
                      id
                      name
                    }
                  }
                }
              }
            }
            constructionSites(first: 20) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            constructionObjects(first: 20) {
              edges {
                node {
                  id
                  name
                  constructionSite {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${InquiryTopicLocalesFragmentDoc}
`;

/**
 * __useInquiryForReviewDetailPageQuery__
 *
 * To run a query within a React component, call `useInquiryForReviewDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiryForReviewDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiryForReviewDetailPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInquiryForReviewDetailPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InquiryForReviewDetailPageQuery,
    InquiryForReviewDetailPageQueryVariables
  > &
    ({ variables: InquiryForReviewDetailPageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InquiryForReviewDetailPageQuery, InquiryForReviewDetailPageQueryVariables>(
    InquiryForReviewDetailPageDocument,
    options
  );
}
export function useInquiryForReviewDetailPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InquiryForReviewDetailPageQuery,
    InquiryForReviewDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InquiryForReviewDetailPageQuery, InquiryForReviewDetailPageQueryVariables>(
    InquiryForReviewDetailPageDocument,
    options
  );
}
export type InquiryForReviewDetailPageQueryHookResult = ReturnType<typeof useInquiryForReviewDetailPageQuery>;
export type InquiryForReviewDetailPageLazyQueryHookResult = ReturnType<typeof useInquiryForReviewDetailPageLazyQuery>;
export type InquiryForReviewDetailPageQueryResult = ApolloReactCommon.QueryResult<
  InquiryForReviewDetailPageQuery,
  InquiryForReviewDetailPageQueryVariables
>;
export const InquiryTopicsDocument = gql`
  query inquiryTopics {
    inquiryTopics {
      id
      isStructural
      ...InquiryTopicLocalesFragment
    }
  }
  ${InquiryTopicLocalesFragmentDoc}
`;

/**
 * __useInquiryTopicsQuery__
 *
 * To run a query within a React component, call `useInquiryTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiryTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiryTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInquiryTopicsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<InquiryTopicsQuery, InquiryTopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InquiryTopicsQuery, InquiryTopicsQueryVariables>(InquiryTopicsDocument, options);
}
export function useInquiryTopicsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InquiryTopicsQuery, InquiryTopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InquiryTopicsQuery, InquiryTopicsQueryVariables>(InquiryTopicsDocument, options);
}
export type InquiryTopicsQueryHookResult = ReturnType<typeof useInquiryTopicsQuery>;
export type InquiryTopicsLazyQueryHookResult = ReturnType<typeof useInquiryTopicsLazyQuery>;
export type InquiryTopicsQueryResult = ApolloReactCommon.QueryResult<InquiryTopicsQuery, InquiryTopicsQueryVariables>;
export const MeDocument = gql`
  query me {
    me {
      ...User
      organizationMemberships(showInactive: true) {
        edges {
          node {
            organization {
              id
              crn
              name
              credits
              createdAt
              archivedAt
              activatedAt
              organizationMemberships {
                edges {
                  node {
                    id
                    user {
                      ...User
                    }
                  }
                }
              }
            }
          }
        }
      }
      ...UserInvitations
      ...UserPurchases
    }
  }
  ${UserFragmentDoc}
  ${UserInvitationsFragmentDoc}
  ${UserPurchasesFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const MeLanguageDocument = gql`
  query meLanguage {
    me {
      id
      language
    }
  }
`;

/**
 * __useMeLanguageQuery__
 *
 * To run a query within a React component, call `useMeLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeLanguageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MeLanguageQuery, MeLanguageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MeLanguageQuery, MeLanguageQueryVariables>(MeLanguageDocument, options);
}
export function useMeLanguageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeLanguageQuery, MeLanguageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MeLanguageQuery, MeLanguageQueryVariables>(MeLanguageDocument, options);
}
export type MeLanguageQueryHookResult = ReturnType<typeof useMeLanguageQuery>;
export type MeLanguageLazyQueryHookResult = ReturnType<typeof useMeLanguageLazyQuery>;
export type MeLanguageQueryResult = ApolloReactCommon.QueryResult<MeLanguageQuery, MeLanguageQueryVariables>;
export const MediaDocument = gql`
  query media($id: ID!) {
    media(id: $id) {
      ...Media
    }
  }
  ${MediaFragmentDoc}
`;

/**
 * __useMediaQuery__
 *
 * To run a query within a React component, call `useMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MediaQuery, MediaQueryVariables> &
    ({ variables: MediaQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
}
export function useMediaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MediaQuery, MediaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
}
export type MediaQueryHookResult = ReturnType<typeof useMediaQuery>;
export type MediaLazyQueryHookResult = ReturnType<typeof useMediaLazyQuery>;
export type MediaQueryResult = ApolloReactCommon.QueryResult<MediaQuery, MediaQueryVariables>;
export const MediaStateDocument = gql`
  query mediaState($id: ID!) {
    media(id: $id) {
      id
      state
    }
  }
`;

/**
 * __useMediaStateQuery__
 *
 * To run a query within a React component, call `useMediaStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaStateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MediaStateQuery, MediaStateQueryVariables> &
    ({ variables: MediaStateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MediaStateQuery, MediaStateQueryVariables>(MediaStateDocument, options);
}
export function useMediaStateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MediaStateQuery, MediaStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MediaStateQuery, MediaStateQueryVariables>(MediaStateDocument, options);
}
export type MediaStateQueryHookResult = ReturnType<typeof useMediaStateQuery>;
export type MediaStateLazyQueryHookResult = ReturnType<typeof useMediaStateLazyQuery>;
export type MediaStateQueryResult = ApolloReactCommon.QueryResult<MediaStateQuery, MediaStateQueryVariables>;
export const OrganizationDocument = gql`
  query organization($id: ID!) {
    organization(id: $id) {
      ...Organization
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables> &
    ({ variables: OrganizationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
}
export function useOrganizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
}
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = ApolloReactCommon.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const OrganizationTasksDocument = gql`
  query organizationTasks(
    $organizationId: ID!
    $skip: Int
    $state: [DocumentationRecordStateFilterEnum]
    $types: [DocumentationRecordTypeFilterEnum]
    $orderBy: [DocumentationRecordOrderByEnum]
    $name: String
    $constructionSiteName: String
    $organizationName: String
    $constructionObjectName: String
    $threeDModelUploaded: Boolean
    $videoTypes: [DocumentationRecordVideoTypeFilterEnum]
    $withTotalSize: Boolean!
    $withModelSize: Boolean!
    $withAccountableManager: Boolean!
  ) {
    organization(id: $organizationId) {
      id
      tasks(
        state: $state
        type: $types
        first: 10
        orderBy: $orderBy
        skip: $skip
        name: $name
        organizationName: $organizationName
        constructionSiteName: $constructionSiteName
        constructionObjectName: $constructionObjectName
        threeDModelUploaded: $threeDModelUploaded
        videoType: $videoTypes
      ) {
        totalCount
        edges {
          node {
            ...Task
            totalSize @include(if: $withTotalSize)
            modelSize @include(if: $withModelSize)
            accountableManager @include(if: $withAccountableManager) {
              id
            }
          }
        }
      }
    }
  }
  ${TaskFragmentDoc}
`;

/**
 * __useOrganizationTasksQuery__
 *
 * To run a query within a React component, call `useOrganizationTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTasksQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      skip: // value for 'skip'
 *      state: // value for 'state'
 *      types: // value for 'types'
 *      orderBy: // value for 'orderBy'
 *      name: // value for 'name'
 *      constructionSiteName: // value for 'constructionSiteName'
 *      organizationName: // value for 'organizationName'
 *      constructionObjectName: // value for 'constructionObjectName'
 *      threeDModelUploaded: // value for 'threeDModelUploaded'
 *      videoTypes: // value for 'videoTypes'
 *      withTotalSize: // value for 'withTotalSize'
 *      withModelSize: // value for 'withModelSize'
 *      withAccountableManager: // value for 'withAccountableManager'
 *   },
 * });
 */
export function useOrganizationTasksQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrganizationTasksQuery, OrganizationTasksQueryVariables> &
    ({ variables: OrganizationTasksQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationTasksQuery, OrganizationTasksQueryVariables>(
    OrganizationTasksDocument,
    options
  );
}
export function useOrganizationTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationTasksQuery, OrganizationTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationTasksQuery, OrganizationTasksQueryVariables>(
    OrganizationTasksDocument,
    options
  );
}
export type OrganizationTasksQueryHookResult = ReturnType<typeof useOrganizationTasksQuery>;
export type OrganizationTasksLazyQueryHookResult = ReturnType<typeof useOrganizationTasksLazyQuery>;
export type OrganizationTasksQueryResult = ApolloReactCommon.QueryResult<
  OrganizationTasksQuery,
  OrganizationTasksQueryVariables
>;
export const OutputExtensionsForDocumentationRecordDetailPageDocument = gql`
  query outputExtensionsForDocumentationRecordDetailPage($id: ID!) {
    documentationRecord(id: $id) {
      id
      outputExtensions {
        edges {
          node {
            id
            createdAt
            completedAt
            comment
            files {
              edges {
                node {
                  ...Media
                }
              }
            }
            inquiry {
              id
              status
            }
            inquiryTopic {
              id
              ...InquiryTopicLocalesFragment
            }
          }
        }
      }
    }
  }
  ${MediaFragmentDoc}
  ${InquiryTopicLocalesFragmentDoc}
`;

/**
 * __useOutputExtensionsForDocumentationRecordDetailPageQuery__
 *
 * To run a query within a React component, call `useOutputExtensionsForDocumentationRecordDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutputExtensionsForDocumentationRecordDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutputExtensionsForDocumentationRecordDetailPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutputExtensionsForDocumentationRecordDetailPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OutputExtensionsForDocumentationRecordDetailPageQuery,
    OutputExtensionsForDocumentationRecordDetailPageQueryVariables
  > &
    ({ variables: OutputExtensionsForDocumentationRecordDetailPageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OutputExtensionsForDocumentationRecordDetailPageQuery,
    OutputExtensionsForDocumentationRecordDetailPageQueryVariables
  >(OutputExtensionsForDocumentationRecordDetailPageDocument, options);
}
export function useOutputExtensionsForDocumentationRecordDetailPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OutputExtensionsForDocumentationRecordDetailPageQuery,
    OutputExtensionsForDocumentationRecordDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OutputExtensionsForDocumentationRecordDetailPageQuery,
    OutputExtensionsForDocumentationRecordDetailPageQueryVariables
  >(OutputExtensionsForDocumentationRecordDetailPageDocument, options);
}
export type OutputExtensionsForDocumentationRecordDetailPageQueryHookResult = ReturnType<
  typeof useOutputExtensionsForDocumentationRecordDetailPageQuery
>;
export type OutputExtensionsForDocumentationRecordDetailPageLazyQueryHookResult = ReturnType<
  typeof useOutputExtensionsForDocumentationRecordDetailPageLazyQuery
>;
export type OutputExtensionsForDocumentationRecordDetailPageQueryResult = ApolloReactCommon.QueryResult<
  OutputExtensionsForDocumentationRecordDetailPageQuery,
  OutputExtensionsForDocumentationRecordDetailPageQueryVariables
>;
export const OutputExtensionsForTaskThreeDOperatorDocument = gql`
  query outputExtensionsForTaskThreeDOperator($id: ID!) {
    documentationRecord(id: $id) {
      id
      outputExtensions {
        edges {
          node {
            id
            comment
            files {
              edges {
                node {
                  ...Media
                }
              }
            }
            inquiry {
              id
              status
              createdAt
              user {
                id
                firstName
                lastName
              }
              userComment
              resolverComment
            }
            inquiryTopic {
              id
              ...InquiryTopicLocalesFragment
            }
          }
        }
      }
    }
  }
  ${MediaFragmentDoc}
  ${InquiryTopicLocalesFragmentDoc}
`;

/**
 * __useOutputExtensionsForTaskThreeDOperatorQuery__
 *
 * To run a query within a React component, call `useOutputExtensionsForTaskThreeDOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutputExtensionsForTaskThreeDOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutputExtensionsForTaskThreeDOperatorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutputExtensionsForTaskThreeDOperatorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OutputExtensionsForTaskThreeDOperatorQuery,
    OutputExtensionsForTaskThreeDOperatorQueryVariables
  > &
    ({ variables: OutputExtensionsForTaskThreeDOperatorQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OutputExtensionsForTaskThreeDOperatorQuery,
    OutputExtensionsForTaskThreeDOperatorQueryVariables
  >(OutputExtensionsForTaskThreeDOperatorDocument, options);
}
export function useOutputExtensionsForTaskThreeDOperatorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OutputExtensionsForTaskThreeDOperatorQuery,
    OutputExtensionsForTaskThreeDOperatorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OutputExtensionsForTaskThreeDOperatorQuery,
    OutputExtensionsForTaskThreeDOperatorQueryVariables
  >(OutputExtensionsForTaskThreeDOperatorDocument, options);
}
export type OutputExtensionsForTaskThreeDOperatorQueryHookResult = ReturnType<
  typeof useOutputExtensionsForTaskThreeDOperatorQuery
>;
export type OutputExtensionsForTaskThreeDOperatorLazyQueryHookResult = ReturnType<
  typeof useOutputExtensionsForTaskThreeDOperatorLazyQuery
>;
export type OutputExtensionsForTaskThreeDOperatorQueryResult = ApolloReactCommon.QueryResult<
  OutputExtensionsForTaskThreeDOperatorQuery,
  OutputExtensionsForTaskThreeDOperatorQueryVariables
>;
export const PrefetchDocument = gql`
  query prefetch {
    me {
      ...User
      ...UserOrganizationMemberships
      ...UserInvitations
      ...UserPurchases
    }
    catalog {
      ...Catalog
    }
    config {
      ...Config
    }
  }
  ${UserFragmentDoc}
  ${UserOrganizationMembershipsFragmentDoc}
  ${UserInvitationsFragmentDoc}
  ${UserPurchasesFragmentDoc}
  ${CatalogFragmentDoc}
  ${ConfigFragmentDoc}
`;

/**
 * __usePrefetchQuery__
 *
 * To run a query within a React component, call `usePrefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrefetchQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PrefetchQuery, PrefetchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PrefetchQuery, PrefetchQueryVariables>(PrefetchDocument, options);
}
export function usePrefetchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrefetchQuery, PrefetchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PrefetchQuery, PrefetchQueryVariables>(PrefetchDocument, options);
}
export type PrefetchQueryHookResult = ReturnType<typeof usePrefetchQuery>;
export type PrefetchLazyQueryHookResult = ReturnType<typeof usePrefetchLazyQuery>;
export type PrefetchQueryResult = ApolloReactCommon.QueryResult<PrefetchQuery, PrefetchQueryVariables>;
export const SearchDocument = gql`
  query search($query: String!, $types: [SearchType]!) {
    search(query: $query, types: $types) {
      __typename
      id
      name
    }
  }
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SearchQuery, SearchQueryVariables> &
    ({ variables: SearchQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>;
export const SearchForInquiryFormDocument = gql`
  query searchForInquiryForm($query: String!) {
    search(query: $query, types: [construction_object, construction_site, documentation_record]) {
      __typename
      id
      name
    }
  }
`;

/**
 * __useSearchForInquiryFormQuery__
 *
 * To run a query within a React component, call `useSearchForInquiryFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchForInquiryFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchForInquiryFormQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchForInquiryFormQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SearchForInquiryFormQuery, SearchForInquiryFormQueryVariables> &
    ({ variables: SearchForInquiryFormQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SearchForInquiryFormQuery, SearchForInquiryFormQueryVariables>(
    SearchForInquiryFormDocument,
    options
  );
}
export function useSearchForInquiryFormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchForInquiryFormQuery, SearchForInquiryFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SearchForInquiryFormQuery, SearchForInquiryFormQueryVariables>(
    SearchForInquiryFormDocument,
    options
  );
}
export type SearchForInquiryFormQueryHookResult = ReturnType<typeof useSearchForInquiryFormQuery>;
export type SearchForInquiryFormLazyQueryHookResult = ReturnType<typeof useSearchForInquiryFormLazyQuery>;
export type SearchForInquiryFormQueryResult = ApolloReactCommon.QueryResult<
  SearchForInquiryFormQuery,
  SearchForInquiryFormQueryVariables
>;
export const TaskCountsDocument = gql`
  query taskCounts {
    me {
      id
      allTasks: tasks(first: 1) {
        totalCount
      }
      mobileTasks: tasks(first: 1, state: [REQUIRES_OPERATOR]) {
        totalCount
      }
    }
  }
`;

/**
 * __useTaskCountsQuery__
 *
 * To run a query within a React component, call `useTaskCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskCountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TaskCountsQuery, TaskCountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskCountsQuery, TaskCountsQueryVariables>(TaskCountsDocument, options);
}
export function useTaskCountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskCountsQuery, TaskCountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskCountsQuery, TaskCountsQueryVariables>(TaskCountsDocument, options);
}
export type TaskCountsQueryHookResult = ReturnType<typeof useTaskCountsQuery>;
export type TaskCountsLazyQueryHookResult = ReturnType<typeof useTaskCountsLazyQuery>;
export type TaskCountsQueryResult = ApolloReactCommon.QueryResult<TaskCountsQuery, TaskCountsQueryVariables>;
export const TasksDocument = gql`
  query tasks(
    $state: [DocumentationRecordStateFilterEnum]
    $toProcess: Boolean
    $types: [DocumentationRecordTypeFilterEnum]
    $skip: Int
    $name: String
    $organizationName: String
    $constructionSiteName: String
    $constructionObjectName: String
    $constructionPhaseOrder: String
    $surveyUploaded: Boolean
    $threeDModelUploaded: Boolean
    $threeDOperatorAssigned: Boolean
    $videoTypes: [DocumentationRecordVideoTypeFilterEnum]
    $orderBy: [DocumentationRecordOrderByEnum]
  ) {
    me {
      id
      tasks(
        first: 10
        state: $state
        toProcess: $toProcess
        type: $types
        skip: $skip
        orderBy: $orderBy
        name: $name
        organizationName: $organizationName
        constructionSiteName: $constructionSiteName
        constructionObjectName: $constructionObjectName
        constructionPhaseOrder: $constructionPhaseOrder
        surveyUploaded: $surveyUploaded
        threeDModelUploaded: $threeDModelUploaded
        threeDOperatorAssigned: $threeDOperatorAssigned
        videoType: $videoTypes
      ) {
        totalCount
        edges {
          node {
            ...Task
          }
        }
      }
    }
  }
  ${TaskFragmentDoc}
`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      state: // value for 'state'
 *      toProcess: // value for 'toProcess'
 *      types: // value for 'types'
 *      skip: // value for 'skip'
 *      name: // value for 'name'
 *      organizationName: // value for 'organizationName'
 *      constructionSiteName: // value for 'constructionSiteName'
 *      constructionObjectName: // value for 'constructionObjectName'
 *      constructionPhaseOrder: // value for 'constructionPhaseOrder'
 *      surveyUploaded: // value for 'surveyUploaded'
 *      threeDModelUploaded: // value for 'threeDModelUploaded'
 *      threeDOperatorAssigned: // value for 'threeDOperatorAssigned'
 *      videoTypes: // value for 'videoTypes'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export function useTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = ApolloReactCommon.QueryResult<TasksQuery, TasksQueryVariables>;
export const ThreeDModelDocument = gql`
  query threeDModel($id: ID!) {
    threeDModel(id: $id) {
      ...ThreeDModel
      documentationRecord {
        id
        name
        type
        accountableThreeDOperator {
          ...User
        }
        constructionPhase {
          id
          name
        }
        constructionObject {
          id
          name
          constructionSite {
            id
            name
          }
        }
        documentationRecordVideo {
          id
          type
        }
      }
    }
  }
  ${ThreeDModelFragmentDoc}
  ${UserFragmentDoc}
`;

/**
 * __useThreeDModelQuery__
 *
 * To run a query within a React component, call `useThreeDModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDModelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThreeDModelQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ThreeDModelQuery, ThreeDModelQueryVariables> &
    ({ variables: ThreeDModelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ThreeDModelQuery, ThreeDModelQueryVariables>(ThreeDModelDocument, options);
}
export function useThreeDModelLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ThreeDModelQuery, ThreeDModelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ThreeDModelQuery, ThreeDModelQueryVariables>(ThreeDModelDocument, options);
}
export type ThreeDModelQueryHookResult = ReturnType<typeof useThreeDModelQuery>;
export type ThreeDModelLazyQueryHookResult = ReturnType<typeof useThreeDModelLazyQuery>;
export type ThreeDModelQueryResult = ApolloReactCommon.QueryResult<ThreeDModelQuery, ThreeDModelQueryVariables>;
export const ThreeDModelForDownloadDocument = gql`
  query threeDModelForDownload($id: ID!) {
    threeDModel(id: $id) {
      id
      isShared
      files {
        id
        fileName
        size
      }
    }
  }
`;

/**
 * __useThreeDModelForDownloadQuery__
 *
 * To run a query within a React component, call `useThreeDModelForDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDModelForDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDModelForDownloadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThreeDModelForDownloadQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ThreeDModelForDownloadQuery, ThreeDModelForDownloadQueryVariables> &
    ({ variables: ThreeDModelForDownloadQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ThreeDModelForDownloadQuery, ThreeDModelForDownloadQueryVariables>(
    ThreeDModelForDownloadDocument,
    options
  );
}
export function useThreeDModelForDownloadLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ThreeDModelForDownloadQuery, ThreeDModelForDownloadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ThreeDModelForDownloadQuery, ThreeDModelForDownloadQueryVariables>(
    ThreeDModelForDownloadDocument,
    options
  );
}
export type ThreeDModelForDownloadQueryHookResult = ReturnType<typeof useThreeDModelForDownloadQuery>;
export type ThreeDModelForDownloadLazyQueryHookResult = ReturnType<typeof useThreeDModelForDownloadLazyQuery>;
export type ThreeDModelForDownloadQueryResult = ApolloReactCommon.QueryResult<
  ThreeDModelForDownloadQuery,
  ThreeDModelForDownloadQueryVariables
>;
export const ThreeDModelForShareDocument = gql`
  query threeDModelForShare($id: ID!) {
    threeDModel(id: $id) {
      id
      isShared
    }
  }
`;

/**
 * __useThreeDModelForShareQuery__
 *
 * To run a query within a React component, call `useThreeDModelForShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDModelForShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDModelForShareQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThreeDModelForShareQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ThreeDModelForShareQuery, ThreeDModelForShareQueryVariables> &
    ({ variables: ThreeDModelForShareQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ThreeDModelForShareQuery, ThreeDModelForShareQueryVariables>(
    ThreeDModelForShareDocument,
    options
  );
}
export function useThreeDModelForShareLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ThreeDModelForShareQuery, ThreeDModelForShareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ThreeDModelForShareQuery, ThreeDModelForShareQueryVariables>(
    ThreeDModelForShareDocument,
    options
  );
}
export type ThreeDModelForShareQueryHookResult = ReturnType<typeof useThreeDModelForShareQuery>;
export type ThreeDModelForShareLazyQueryHookResult = ReturnType<typeof useThreeDModelForShareLazyQuery>;
export type ThreeDModelForShareQueryResult = ApolloReactCommon.QueryResult<
  ThreeDModelForShareQuery,
  ThreeDModelForShareQueryVariables
>;
